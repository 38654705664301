.Basket .elevated-cart {
    padding-top: 16px;

    .basket-heading {
        .tick-outer {
            fill: var(--site-primary);
        }
    }

    .cart-page__primary-button {
        font-weight: var(--font-weight-bold) !important;
    }

    .PromoCodeBut,
    .basket-summary-continue-button-container .ContinueOn {
        letter-spacing: 0.1em;
    }
}
