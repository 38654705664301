.cms-sitewide-banner-enabled {
    --headertop-height: 144px;
    --sitewide-banner-z-index: 5890;

    &.cms-sitewide-banner-above {
        #showFilterButtonDiv {
            margin-top: 49px;
        }
    }
}
