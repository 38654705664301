.menu-search-hidden #divBagItems.elevated-sliding-basket {
    top: 80px;
    transition-delay: 0.1s;

    @media (max-width: 1022px) {
        top: 70px;
    }
}

.lillBasket #aBagLink span#bagQuantity.empty,
.lillBasket #aBagLink span#bagQuantity.hide-count,
#lblWishListCount.hide-wishlist {
    display: none !important;
}

#divBag.lillBasket #aBagLink span#bagQuantity {
    background-color: var(--site-primary);
    color: var(--site-white);
    min-height: 17px;
    border-radius: 50%;
    height: auto;
    margin: 0;
    min-width: 17px;
    position: absolute;
    top: 6px;
    left: 70%;
    width: auto;
    padding: 0;
    text-align: center;
    font-size: 12px;
    line-height: 1;
    aspect-ratio: 1 / 1;
    display: flex;
    align-items: center;
    justify-content: center;

    @media (min-width: 767px) {
        top: 11px;
        left: 55%;
    }

    @media (min-width: 1022px) {
        top: -2px;
    }
}

#divBagItems.elevated-sliding-basket {
    position: absolute;
    top: 100%;
    overflow: hidden;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.1);
    border: none;
    transition: top 0.25s;
    max-width: 430px;
    &.open {
        overflow: hidden !important;
    }

    .innerdivBagItems {
        max-height: calc(95vh - 85px);
        display: flex;
        flex-direction: column;
    }
    .bagHeader {
        flex: 0 0 auto;

        .chkoutopt {
            width: 100%;

            #aCheckout {
                margin: 0;
            }
        }

        .tick-outer {
            fill: var(--site-primary);
        }

        .tick-inner {
            fill: var(--site-white);
        }
    }

    #divBagItemsChild {
        /* entire mini bag container */
        display: flex;
        flex-direction: column;
        flex: 1;
        overflow: hidden;
        padding: 0 !important;

        #divulBagParent {
            flex: 1 1 auto;
        }

        .bagHeader p {
            display: flex;
            text-transform: uppercase;
            font-family: var(--brand-font) !important;
            font-size: var(--subtitle-font-size);
            font-weight: var(--font-weight-bold) !important;
            border-bottom: 1px solid #ddd !important;
            padding: 30px 24px !important;
            margin: 0;
            background-color: var(--site-white);
            line-height: unset;

            .global-icon {
                margin: 0 10px;
            }

            #clsBasketMob::before {
                content: none;
            }

            #clsBasketMob {
                width: 42px;
                right: 10px;
                height: 42px;
                line-height: unset;
                top: 39px;
                translate: 0 -50%;
                display: flex !important;
                align-items: center;
                justify-content: center;
            }

            #clsBasketMob span {
                height: 18px;
            }

            #clsBasketMob .global-icon svg {
                width: 18px;
                height: 100%;
            }

            .basketTotalItemsHeader {
                text-transform: uppercase;
            }
        }
    }

    .innerdivBagItems #divulBagParent {
        /* bag summary content */
        overflow-y: auto !important;
        width: 100%;
        max-height: 50vh;
    }

    #divButtons {
        /* bag summary footer */
        background-color: var(--site-light-grey) !important;
        padding: 10px 25px 25px;
        flex: 0 0 auto;

        #divBagTotalDiscount {
            font-size: var(--body-font-size);
            color: var(--site-red);
            text-align: left;
        }

        #lblBagSubTotal {
            font-size: var(--body-font-size);
            font-weight: var(--font-weight-regular);
        }

        #spanBagSubTotalValue {
            font-size: var(--body-font-size);
        }

        .SubBask {
            display: flex;
            padding: 0;

            span {
                text-transform: uppercase;
            }
        }

        .SubBask > div {
            width: 50%;
            flex: 1 1 auto;
        }

        .chkouterror {
            margin: 6px 0 16px;
            background-color: hsl(3 79% 95% / 1);
            padding: 8px 16px;
            text-align: center;
            font-weight: var(--font-weight-bold);
        }
    }

    .summaryWrapCTA {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;

        #aViewBag,
        #aCheckout {
            font-weight: var(--font-weight-bold) !important;
            font-size: var(--body-font-size) !important;
            width: 100%;
            padding: 15px !important;
            line-height: 19px;
        }

        #spanCheckout {
            width: 100% !important;
        }

        #aViewBag {
            margin-bottom: 16px !important;
            border: 1px solid var(--site-black) !important;
            color: var(--site-black) !important;
            background-color: var(--site-button-secondary-background) !important;
            width: 100% !important;

            &:hover {
                background-color: var(--site-button-secondary-background-hover) !important;
                color: var(--site-button-secondary-color-hover) !important;
            }
        }

        #aCheckout {
            border: 1px solid var(--site-primary) !important;
        }
    }
}

#divBagTotalLink a#aBagLink #bagQuantityContainer:has(.global-icon) {
    background-image: none;

    svg {
        path {
            fill: var(--site-black);
        }
    }
}

#divBag #bagTotal {
    margin: 8px 0 0;
}
