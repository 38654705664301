.wishlist-page {
    padding-top: 20px;

    .wishlist-page__header {
        .tick-outer {
            fill: var(--site-primary);
        }
    }

    .wishlist-page__primary-button {
        border-radius: 0;
        text-align: center;
        border: none;
        background-color: var(--site-button-primary-background);
        line-height: 19px !important;
        font-weight: var(--font-weight-bold) !important;
        font-size: var(--body-font-size);
        transition: all 0.3s ease-in;

        &:hover {
            background-color: var(--site-button-primary-background-hover);
            color: var(--site-button-primary-color-hover);
        }
    }
}

@media (min-width: 1022px) {
    .wishlist-page__header {
        top: 214px;
    }
}

@media (max-width: 1021px) {
    .wishlist-page__header {
        top: 177px;
    }
}

@media (max-width: 767px) {
    .wishlist-page__header {
        top: 152px;
    }
}
