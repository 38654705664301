.evanDetails {
    #addToWishListContainer {
        height: 64px;
        display: flex;
        align-items: center;
        justify-content: center;

        #aAddToWishList {
            &:hover {
                svg,
                path {
                    fill: var(--site-primary) !important;
                }
            }
        }
    }

    .WishListContain svg path {
        fill: var(--site-black);
    }

    .WishListContain a:focus svg path,
    .WishListContain a:hover svg path {
        stroke: none;
    }

    .member-price {
        justify-content: start;
        text-align: left;
        font-size: var(--subtitle-font-size);
        margin-bottom: 10px;

        @media (max-width: 767px) {
            padding: 0 15px;
        }
    }

    .pdpMoreLinksDelivery {
        padding: 0;
    }

    #MoreFromLinks .MoreFromLinksHeader {
        font-size: 1.2em;
        padding: 10px 15px;
    }

    @media (max-width: 767px) {
        .BasketWishContainer {
            display: flex;
            flex-wrap: wrap;

            .stockLevelWrapper {
                width: 100%;
            }
        }

        .addtoBagWrap {
            flex: 1;
        }

        #addToWishListContainer {
            width: auto !important;
            float: none;
            margin: 0;
            padding: 10px;
        }

        .evanDetails .WishListContain a {
            margin-top: 15px;
        }

        .evanDetails #MoreFromLinks .MoreFromLinksHeader,
        .sdDetails #MoreFromLinks .MoreFromLinksHeader {
            padding: 10px 0 0;
        }

        .evanDetails #MoreFromLinks ul {
            padding: 10px 0;
        }

        .evanDetails .pdpMoreLinksDelivery {
            border-top: 0;
            border-bottom: 0;
        }
    }
}

.evanDetails .WishListContain a {
    margin-top: 16px;
}

.reviews-container .bv-cv2-cleanslate .bv-shared button.bv-write-review,
.reviews-container .bv-cv2-cleanslate .bv-shared button.bv-write-review:hover,
.reviews-container .bv-cv2-cleanslate .bv-shared button.bv-text-link {
    font-family: poppins-regular, Arial, sans-serif !important;
    font-weight: var(--font-weight-bold) !important;
    letter-spacing: 0.1em !important;
}
