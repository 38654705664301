.evanProdList {
    .hotspotbuy {
        .WishIcon {
            background-image: none;
            display: flex;
            align-items: center;
            justify-content: center;

            &:hover {
                svg {
                    fill: var(--site-primary) !important;
                }
            }

            .global-icon-wishlist {
                width: 24px;
                height: 24px;
                display: flex;

                svg {
                    fill: var(--site-black);
                }
            }
        }
    }

    .member-pricing {
        padding: 0 10px;

        .member-price-text {
            font-size: 1.2em;
        }
    }

    .ImgTxtContainer {
        text-align: left;
    }
}
