:root {
    --site-black: #000;
    --site-white: #fff;
    --site-light-grey: #f6f6f6;
    --site-mid-grey: #ccc;
    --site-dark-grey: #5d5d5d;

    /* Brand */
    --site-primary: #f7a923;
    --site-sale: #b30000;
    --site-border-radius: 0;
    --padding-unit: 4px;
    --cart-details-margin-top-value: 16px;

    --site-button-primary-background: var(--site-primary);
    --site-button-primary-background-hover: #e0920d;
    --site-button-primary-color: var(--site-white);
    --site-button-primary-color-hover: var(--site-white);

    --site-button-secondary-background: var(--site-white);
    --site-button-secondary-background-hover: var(--site-dark-grey);
    --site-button-secondary-color: var(--site-white);
    --site-button-secondary-color-hover: var(--site-white);

    --site-button-focus: 1px solid var(--site-primary);
    --site-button-border-radius: var(--site-border-radius);

    /* Font */
    --brand-font: poppins-regular, Arial, sans-serif;
    --brand-font-bold: poppins-bold, Arial, sans-serif;
    --body-font-size: 14px;
    --body-font-size-small: 12px;
    --subtitle-font-size: 16px;

    --font-weight-regular: 400;
    --font-weight-bold: 700;

    /* Wishlist Variables */
    --font-family-normal: var(--brand-font);
    --font-family-bold: var(--brand-font-bold);
    --font-family: var(--brand-font);
    --font-family-italic: var(--brand-font);
    --wishlist-discount-text-color: var(--site-sale);
    --wishlist-popup-border-color: var(--site-light-grey);
    --wishlist-popup-header-border-color: var(--site-light-grey);
    --wishlist-no-image-color: var(--site-light-grey);
    --wishlist-tick-background-color: var(--site-primary);
    --wishlist-header-font-style: normal;
    --wishlist-summary-header-font-style: normal;
    --wishlist-button-text-transform: uppercase;
    --wishlist-button-font-style: normal;
    --wishlist-button-background-color: var(--site-button-secondary-background);
    --wishlist-button-hover-color: var(--site-button-secondary-background-hover);
    --wishlist-button-border-radius: var(--site-border-radius);
    --wishlist-button-background-color-hover: var(--site-button-primary-background-hover);
    --wishlist-scroll-background-color: var(--site-primary);

    /* Cart Variables */
    --product-line-item-font-family-bold: var(--brand-font);
    --product-line-item-price-font-weight: var(--font-weight-regular);
    --product-line-item-default-font-color: var(--site-black);
    --product-line-item-original-price-color: var(--site-black);
    --product-line-item-discount-text-color: var(--site-sale);
    --product-line-item-total-price-font-weight: var(--font-weight-bold);
    --cart-border-radius: 0;
    --cart-sticky-element-default-top-value: 210px;
    --cart-heading-font-style: normal;
    --cart-discount-font-color: var(--site-sale);

    --cart-font-family: var(--brand-font);
    --cart-font-family-bold: var(--brand-font);
    --cart-font-family-italic: var(--brand-font);

    --cart-button-font-style: normal;
    --cart-button-font-family: var(--brand-font);
    --cart-button-font-weight: var(--font-weight-bold);

    --cart-button-font-color: var(--site-white);
    --cart-button-hover-text-color: var(--site-white);

    --cart-button-background-color: var(--site-button-primary-background);
    --cart-button-hover-color: var(--site-button-primary-background);

    --cart-button-transactional-background-color: var(--site-button-primary-background);
    --cart-button-transactional-font-color: var(--site-white);
    --cart-button-transactional-hover-background-color: var(--site-button-primary-background-hover);
    --cart-button-transactional-hover-font-color: var(--site-white);
}
