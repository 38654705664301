@font-face {
    font-family: poppins-regular;
    src: url("/Fonts/poppins-regular.woff2") format("woff2");
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: poppins-bold;
    src: url("/Fonts/poppins-bold.woff2") format("woff2");
    font-style: normal;
    font-display: swap;
}

#Body,
p,
a,
div,
dl,
dt,
dd,
ul,
ol,
li,
h1,
h2,
h3,
h4,
h5,
h6,
pre,
form,
fieldset,
input,
textarea,
html,
blockquote,
th,
td,
tr,
legend,
select,
button,
input[type="submit"],
.prc_comment_postdatetime,
#StoreFinderResultsAttributes ul li,
nav#topMenu li.root .SubMenuWrapper > ul li a,
nav#topMenu > ul li a {
    font-family: poppins-regular, Arial, sans-serif;
}

.evanDetails #lblProductBrand,
#filterlist h3.productFilterTitle,
.evanProdList .s-producttext-top-wrapper .brandWrapTitle,
#pdpAttributesWrapper .specItem .specTitle,
.evanProdList.Browse h1,
.Basket #divContinueSecurely.ImgButWrap a,
.Basket #divContinueSecurelyTop.ImgButWrap a {
    font-family: poppins-bold, Arial, sans-serif !important;
}

.Browse #lblCategoryCopy p,
.Browse #lblCategoryCopy a,
.s-breadcrumbs-bar p,
.s-breadcrumbs-bar li a {
    font-family: poppins-regular, Arial, sans-serif;
}

.reviews-container [data-bv-rating] .bv_main_container .bv_text,
.reviews-container [data-bv-show="inline_rating"] .bv_main_container .bv_text,
.reviews-container [data-bv-show="rating_summary"] .bv_main_container .bv_numReviews_text,
[data-bv-show="rating_summary"] .bv_main_container .bv_button_buttonMinimalist,
.bv_modal_component_container .bv_button_component_container button.bv_button_buttonFull {
    font-family: poppins-regular, Arial, sans-serif !important;
}

.maxText,
.saleprice,
.SubBask .PriceTotalBask,
.saleTime .s-producttext-withticket .s-largered,
#productDetails #infoTabs .infoTabPage a:hover,
h6,
.csHomeTable a:hover,
.SubTotal,
.csHomeTable li.header a:hover,
.saleTime .AltStratSellPrice.productHasRef,
.outofstock,
.yousave,
.nav#topMenu li.root .SubMenuWrapper > ul .sdmColSaleHeader > a,
.saleTime #hsPrice,
.saleTime .PSProdPrice .PSSellPrice.productHasRef,
.saleTime .pdpPrice .productHasRef,
.evanProdList .s-producttext-withticket .curprice {
    color: #ff0039;
}

.discountText,
.preorder,
#FootcmdSearch {
    color: #005a54;
}

#dvCancelled,
#dvInProgress,
#dvNewOrder,
#dvRejected,
#dvShipped,
#dvSubmitted {
    color: #005a54 !important;
}

.personalisation {
    color: #46b05a;
}

a,
a:active,
a:focus,
a:link,
a:visited,
.RightBox p,
.RightBox h3,
.RightBox,
.TopHeader p,
a:hover,
a p:hover {
    color: #23282b;
}

.LeftBarLinks #topLinkMenu ul.TopLinkMenu li {
    color: #333 !important;
}

.RadTabStrip .rtsLevel .rtsIn:hover,
.RadTabStrip .rtsLevel .rtsSelected .rtsIn,
.RadTabStrip .rtsLevel .rtsIn {
    color: #005a54 !important;
}

#divPopupSizeGuide a,
.evanDetails .infoaccordion .panel-heading > a,
.mobAppliedFilters,
.s-producttext-top-wrapper a:hover,
.evanProdList .ddlSortOptions li:hover,
#FiltersAndProductsWrapper #FilterContainer ul.productFilters .productFilter .FilterAnchor:hover,
#pdpAttributesWrapper h2,
.am-menu ul li > a,
.shop > ul > li > a {
    color: #005a54;
}

.Basket #BasketDiv table th,
.pppt,
.sizeDetail,
#productImages .imageControls .zoomControl,
#productImages .imageControls .spinControl,
.currentPage {
    color: #666;
}

.fromProductPrice {
    color: #888;
}

#securedByLogin span {
    color: #777 !important;
}

.originalprice,
input[type="button"]:disabled,
input[type="submit"]:disabled,
input[type="reset"]:disabled,
input[type="file"]:disabled::-webkit-file-upload-button,
button:disabled,
select:disabled,
keygen:disabled,
optgroup:disabled,
option:disabled,
.BaskColr,
.BaskSize,
.s-smalltext,
.s-producttext-price .AdditonalPriceLabel {
    color: #888;
}

.Basket #BasketDiv .productcode span,
.ToplinksGroup .search input.watermarked {
    color: #636363;
}

body,
h1,
h1 span,
h2,
h3,
h4,
h5,
p,
label,
input,
optgroup,
select,
textarea,
.Basket h1,
.weWrap,
.Basket #BasketDiv table td,
.Basket #BasketDiv .basketsubtotal,
.sizeText,
.Profile h3,
.productFilterTitle,
.DontForgetText,
#productDetails #infoTabs .infoTabPage,
#productDetails #infoTabs .infoTabPage a,
#productDetails #infoTabs .infoTabPage h1,
#productDetails #infoTabs .infoTabPage h2,
#productDetails #infoTabs .infoTabPage h3,
#productImages .imageControls .zoomControl:hover,
#productImages .imageControls .spinControl:hover,
#productDetails #infoTabs .infoTabPage p,
.sizegroup h1,
.csHomeTable li.header a,
ins,
mark,
nav#topMenu > ul li.ClearBack > a,
nav#topMenu > ul li.ClearBack > a:hover,
nav#topMenu li.root .SubMenuWrapper > ul .sdmColSaleHeader > a,
nav#topMenu li.root .SubMenuWrapper > ul .sdmColSaleFooter > a,
.PreDate,
.productcolour,
.productsize,
.categorycopyd4,
.MobFiltersText,
.sizeslink,
.ui-autocomplete > li > .ui-corner-all,
.MobSearchDropDown > li > a {
    color: #000;
}

.s-checkout-fix .secondary a,
.BagandCheckoutBtn .secondary2 a,
.entryPaging a {
    color: #333 !important;
}

#pagernav a,
.dbc,
#TooManyDailyOfferItemsError,
.jquery_tools_tooltip,
.ui-autocomplete li #ui-active-menuitem,
.sg_colHead,
.OrderGroup .OrderColC .ButtonOrder a,
.OrderColC .TrackOrder a,
.entryPaging a:hover,
#divBag.active .HeaderCheckoutLink,
#filterByMob .MobFiltersText,
.evanProdList .ViewPerPage a.selected,
.newBasketPromoCode .PromoCodeBut a {
    color: #fff;
}

.ImgButWrap a,
.ImgButWrap a:active,
.ImgButWrap a:visited,
.ImgButWrap a:link,
.ImgButWrap input,
a.prc_commentpostingpanelcommentsectionlink,
.Basket #BasketDiv .UpdateQuant,
.Login .loginContainer .ImgButWrap,
.Profile a.dnnPrimaryAction,
#addressbook-edit-dialog a.dnnPrimaryAction,
button,
input[type="button"],
input[type="submit"],
.primary2 a.dnnPrimaryAction,
.primary2 input,
#aPayNow,
#aViewBag,
.addToBasketContainer .ImgButWrap a,
.PersVouchBasketContainer .ImgButWrap a,
.CheckWrap .ImgButWrap a,
.CheckWrap a.ContinueOn,
.CheckWrap .ContinueOn a,
.CheckWrap input.ContinueOn,
.entryPaging a:hover {
    color: #fff !important;
}

.dbc,
.contentwrap,
.header,
.productFilter-slider,
#topLinkMenu ul li a:hover {
    background-color: transparent;
}

div.NewMenuWrap {
    background: rgba(255, 255, 255, 0.95);
}

nav#topMenu > ul li.SaleTop,
#TooManyDailyOfferItemsError,
nav#topMenu > ul li.SaleTop:hover {
    background-color: #ee3425;
}

.ImgButWrap a,
.ImgButWrap a:active,
.ImgButWrap a:visited,
.ImgButWrap a:link,
.ImgButWrap input,
a.prc_commentpostingpanelcommentsectionlink,
.Basket #BasketDiv .UpdateQuant,
.Login .loginContainer .ImgButWrap,
.Profile a.dnnPrimaryAction,
#addressbook-edit-dialog a.dnnPrimaryAction,
button,
input[type="button"],
input[type="submit"],
.primary2 input,
.piActiveDot,
.sizeHeader,
.cs_sizeguide > tbody > tr.sg_colHead,
.mp-level,
.OrderGroup .OrderColC .ButtonOrder a,
.OrderColC .TrackOrder a,
.sdlProdList #mobFilterControls li,
.DeliveryOptions .DeliveryOptionsItem.active .InnerMethods,
.newBasketPromoCode .PromoCodeBut a {
    background-color: #005a54 !important;
}

.ImgButWrap a:hover,
.ImgButWrap input:hover,
.ImgButWrap a:focus,
a.prc_commentpostingpanelcommentsectionlink:hover,
.Basket #BasketDiv .UpdateQuant:hover,
.Login .loginContainer .ImgButWrap:hover,
.Profile a.dnnPrimaryAction:hover,
#addressbook-edit-dialog a.dnnPrimaryAction:hover,
button:hover,
input[type="button"]:hover,
input[type="submit"]:hover,
.primary2 a.dnnPrimaryAction:hover,
.primary2 input:hover,
.buttonAction:hover,
.basketLink .langlogin a.login:hover,
.s-basket-button-right input:hover,
.OrderGroup .OrderColC .ButtonOrder a:hover,
.OrderColC .TrackOrder a:hover,
.sdlProdList #mobFilterControls li:hover,
.newBasketPromoCode .PromoCodeBut a:hover {
    background-color: #03312e !important;
}

.primary2 a.dnnPrimaryAction,
.primary2 input,
#aPayNow,
#aViewBag,
.PersVouchBasketContainer .ImgButWrap a,
.CheckWrap .ImgButWrap a,
.CheckWrap a.ContinueOn,
.CheckWrap .ContinueOn a,
.CareersBrand .vacancy-search-submit a,
.CareersBrand .vacancy-more-info-btn a,
#divBag.active .HeaderCheckoutLink,
.OrderCompleteRegistration .ContinueOn,
.OrderCompleteLogin .OrderCompleteLoginForm .dnnPrimaryAction {
    background-color: #005a54 !important;
}

.primary2 a.dnnPrimaryAction:hover,
.primary2 input:hover,
#aViewBag:hover,
.PersVouchBasketContainer .ImgButWrap a:hover,
.CheckWrap .ImgButWrap a:hover,
.CheckWrap a.ContinueOn:hover,
.CheckWrap .ContinueOn a:hover,
.CareersBrand .vacancy-search-submit a:hover,
.CareersBrand .vacancy-more-info-btn a:hover,
#divBag.active .HeaderCheckoutLink:hover,
#divBag.active .HeaderCheckoutLink:focus,
.OrderCompleteRegistration .ContinueOn:hover,
.OrderCompleteLogin .OrderCompleteLoginForm .dnnPrimaryAction:hover {
    background-color: #03312e !important;
}

.addToBasketContainer .ImgButWrap a,
.BasketNew a.ContinueOn,
.lillBasket #aCheckout,
.CheckWrap input.ContinueOn {
    background-color: #f7a923 !important;
}

.addToBasketContainer .ImgButWrap a:hover,
.BasketNew a.ContinueOn:hover,
.lillBasket #aCheckout:hover,
.CheckWrap input.ContinueOn:hover {
    background-color: #e0920d !important;
}

.BodyWrap,
#BodyWrap,
.DontForgetText,
body,
.ui-state-active a,
.ui-widget-content .ui-state-active a,
.ui-widget-header .ui-state-active a,
.HeaderWrap,
#OrderHistoryPeriod select:focus,
#productDetails #infoTabs .infoTabPageContainer,
#productDetails #infoTabs .multiPage,
.RadTabStripBottom_Vista .rtsLevel .rtsLink,
.RadTabStrip .rtsLevel .rtsSelected .rtsIn,
.RadTabStrip .rtsLevel .rtsSelected .rtsIn:hover,
#divBagItems,
#divEmptyErrorMessage,
#divAjaxLoaderImage,
#ulBag li,
.ASCdelivery2 .FullAdvert img,
.FourHovers ul li > div {
    background-color: #fff;
}

.evanProdList .SliderContain .ui-slider-range,
.evanProdList .SliderContain .ui-slider-handle::after,
#mobControlBar,
.evanProdList .ViewPerPage a.selected {
    background-color: #005a54;
}

.RadTabStrip .rtsLevel .rtsIn:hover {
    background-color: #f1f1f1;
}

.currentPage,
.greyBoxBrand,
.s-checkout-fix .secondary a,
.BagandCheckoutBtn .secondary2 a {
    background: #fff !important;
}

.jquery_tools_tooltip {
    background-color: #333;
}

#Storetext {
    background: rgba(51, 51, 51, 0.5);
}

.advert .pagination,
.Home .advert .swiper-container,
.Vieworders {
    background-color: #ccc;
}

.sizegroup h1,
.c_BlackTitle h3,
.csHomeTable li.header {
    background-color: #eee;
}

#Cancelled-popup-container,
#InProgress-popup-container,
#NewOrder-popup-container,
#Rejected-popup-container,
#Shipped-popup-container,
#Submitted-popup-container,
#OrderHistoryPeriod select {
    background-color: #f1f1f1;
}

.byPostGroup {
    background-color: #f7f7f7;
}

.entryPaging a:hover {
    background-color: #666 !important;
}

ins {
    background: #ff9;
}

mark {
    background: #ff0;
}

nav#topMenu > ul li.root.ClearBack {
    background-color: #f3d600;
}

nav#topMenu > ul li.root.ClearBack:hover {
    background-color: #e5ca00 !important;
}

.ImgButWrap a,
.ImgButWrap a:active,
.ImgButWrap a:visited,
.ImgButWrap a:link,
.ImgButWrap input,
a.prc_commentpostingpanelcommentsectionlink,
.Basket #BasketDiv .UpdateQuant,
.Login .loginContainer .ImgButWrap,
.Profile a.dnnPrimaryAction,
#addressbook-edit-dialog a.dnnPrimaryAction,
button,
input[type="button"],
input[type="submit"],
.primary2 a.dnnPrimaryAction,
.primary2 input,
#aPayNow,
#aViewBag,
.addToBasketContainer .ImgButWrap a,
.PersVouchBasketContainer .ImgButWrap a,
.CheckWrap .ImgButWrap a,
.CheckWrap a.ContinueOn,
.CheckWrap .ContinueOn a,
.CheckWrap input.ContinueOn,
.CareersBrand .vacancy-search-submit a,
.CareersBrand .vacancy-more-info-btn a {
    background-image: none;
    border: 0;
    border-radius: 0;
    filter: none;
    text-shadow: none;
}

.groupquestions li {
    background: transparent url("/images/core/diam4vr.png") no-repeat scroll left center;
}

ul.toptabs {
    width: 100%;
    margin: auto;
}

.ui-state-default a,
.ui-widget-content .ui-state-default a,
.ui-widget-header .ui-state-default a {
    background-color: #0255a3;
}

.blkBldTxt,
.redBldTxt {
    font-weight: var(--font-weight-bold);
}

.redBldTxt {
    color: #ee3425;
}

.ImgButWrap a,
.ImgButWrap a:active,
.ImgButWrap a:visited,
.ImgButWrap a:link,
.ImgButWrap input,
a.prc_commentpostingpanelcommentsectionlink,
.Basket #BasketDiv .UpdateQuant,
.Login .loginContainer .ImgButWrap,
.Profile a.dnnPrimaryAction,
#addressbook-edit-dialog a.dnnPrimaryAction,
button,
input[type="button"],
input[type="submit"],
.primary2 a.dnnPrimaryAction,
.primary2 input,
#aCheckout,
#aViewBag,
.PersVouchBasketContainer .ImgButWrap a,
.ToplinksGroup .search #cmdSearch,
#divBag,
#divBag.active .HeaderCheckoutLink,
nav#topMenu ul li.root.brandsMenu .SubMenuWrapper ul li.level1 a,
#productDetails .personalisationTitlePanel {
    transition: all 0.3s ease-in;
}

.PersonaliseSpace .PersVouchBasketContainer .ImgButWrap a {
    font-size: 1.3em;
    letter-spacing: 0.1em;
    font-weight: var(--font-weight-bold);
    padding: 20px 5px;
    text-transform: uppercase;
}

#hotspotModal h2 {
    font-size: 1.3em;
    line-height: 1.3em;
    font-weight: var(--font-weight-regular);
}

#hsPriceWrapper {
    margin-bottom: 15px;
}

#hsPrice {
    font-size: 1.2em;
    display: inline-block;
    padding-right: 10px;
    line-height: 1.2em;
    font-weight: var(--font-weight-bold);
    letter-spacing: 0.05em;
    float: none;
}

#hsAdditionalPriceLabel {
    display: inline-block;
    font-size: 1.1em;
    padding: 0 5px 0 0;
    line-height: 1.3em;
    float: none;
    color: #888;
}

#hsRefPrice {
    display: inline-block;
    font-size: 1.1em;
    padding-top: 0;
    line-height: 1.3em;
    float: none;
    color: #888;
}

.hsColourDesc .ColourLabel,
.hsSizeLabel {
    letter-spacing: 0.05em;
    font-size: 1.2em;
    line-height: 1.2em;
}

.hsColourDesc .ColourLabel::after,
.hsSizeLabel::after {
    content: ":";
}

.hsColourDesc .ColourDesc {
    font-weight: var(--font-weight-regular);
    font-style: italic;
    font-size: 1.2em;
    line-height: 1.2em;
}

#hsAddToBagContainer a,
#hsAddToWishListContainer a {
    padding: 20px 5px;
    font-size: 1.2em;
    text-transform: uppercase;
    letter-spacing: 0.1em;
    font-weight: var(--font-weight-bold);
    display: block;
}

.FooterCardIcons.eur,
.FooterCheck .ThreeHelpTips .CardPayments .CheckFootImg.eur,
.BasketCardsIcons .PayImage.eur {
    background-image: url("/images/payment/cardpayments-aunzie.png");
}

header {
    background-color: #fff;
}

.CheckoutHeader .headerSecureCheckout,
.CheckoutHeader .headerSummary .summaryText,
.CheckoutHeader .headerSummary .summaryPrice,
.CheckoutHeader .headerRightSecureCheckout {
    color: #000;
}

.checkChevron span {
    background-position: -20px -115px;
}

.headerSummaryContainer {
    background-color: #f5f5f5;
}

.securePad {
    background-position: -276px -107px;
}

@media (min-width: 1025px) {
    .securePad {
        background-position: -368px -144px;
    }
}

.formCompleteCTA {
    button {
        background-color: #f7a923 !important;
        font-weight: var(--font-weight-bold);
        letter-spacing: 0.1em;

        &:hover {
            background-color: #e0920d !important;
        }
    }
}
