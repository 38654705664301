.menu-search-hidden #divWishList .wishlist-summary {
    top: 80px;
    transition-delay: 0.1s, 0;

    @media (max-width: 1022px) {
        top: 70px;
    }
}

#divWishList.show-wishlist .wishlist-summary {
    @media (max-width: 767px) {
        max-height: calc(100vh - 55px);
    }
}

#divWishList.WishList,
a#aWishListLink {
    overflow: visible;
}

.ControlWrap .WishList .wishQuantityContainer #lblWishListCount {
    background-color: var(--site-primary);
    color: var(--site-white);
    min-height: 17px;
    border-radius: 50%;
    height: auto;
    margin: 0;
    min-width: 17px;
    position: absolute;
    top: -2px;
    left: 70%;
    width: auto;
    padding: 0;
    text-align: center;
    font-size: 12px;
    line-height: 1;
    aspect-ratio: 1 / 1;
    display: flex;
    align-items: center;
    justify-content: center;
}

#divWishList .wishlist-summary {
    position: absolute;
    top: 100%;
    width: 430px;
    z-index: 5900;
    transform: translateX(calc(-100% + 160px));
    transition:
        top 0.25s,
        max-height 800ms ease-out 0s;

    @media (min-width: 1022px) {
        transform: translateX(calc(-100% + 60px));
    }

    @media (max-width: 430px) {
        width: calc(100vw - 17px);
    }

    &::before {
        content: none;
    }

    &-inner {
        margin: 0;
    }

    &-signinMessage {
        font-size: 11px;
    }

    @media (max-width: 767px) {
        right: auto;
        bottom: auto;
    }

    @media (max-width: 490px) {
        right: 0;
        transform: none;
    }

    .wishlist-summary-header {
        .clsWishlistMob svg {
            width: 18px;
            height: 100%;
        }

        .tick-tick {
            fill: var(--site-white) !important;
        }

        #divBagItemsChild {
            padding: 0;
        }
    }

    .wishlist-summary-footer {
        background-color: var(--site-light-grey);

        .ctaBtn {
            border-radius: 0;
            text-align: center;
            border: none;
            background-color: var(--site-button-primary-background);
            line-height: 19px !important;
            font-weight: var(--font-weight-bold) !important;
            font-size: var(--body-font-size);

            &:hover {
                background-color: var(--site-button-primary-background-hover);
                color: var(--site-button-primary-color-hover);
            }
        }

        .ctaBtn.signIn {
            background-color: var(--site-button-secondary-background);
            line-height: normal;

            &:hover {
                background-color: var(--site-button-secondary-background-hover);
                color: var(--site-button-secondary-color-hover);
            }
        }
    }
}

.ControlWrap .WishList .wishQuantityContainer .WishIcon:has(.global-icon) {
    background-image: none;

    .global-icon-wishlist {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 3px;
        @media (max-width: 767px) {
            margin-top: 1px;
        }

        svg {
            height: 24px;
            width: 24px;
            fill: var(--site-black);
            @media (max-width: 767px) {
                height: 26px;
                width: 26px;
            }
        }
    }
}

.ControlWrap .WishList {
    @media (max-width: 767px) {
        margin-right: 0;
    }
}

.ControlWrap .bagWishBlock {
    @media (max-width: 767px) {
        width: 110px;
    }
}
