a.bx-prev,
a.bx-next {
    width: 45px;
    height: 45px;
    background-position: -13.2em -46.6em;
    background-size: 75em 75em;
}

.bx-prev::after,
.bx-prev::before,
.bx-next::after,
.bx-next::before {
    content: "";
    position: absolute;
    width: 0;
    height: 0;
    background-color: transparent;
}

a.bx-prev {
    left: 10px;
}

a.bx-next {
    background-position: -19.4em -46.6em;
    right: 10px;
}

.bx-prev:hover,
.bx-next:hover {
    background-color: transparent;
}

.bx-wrapper .bx-pager.bx-default-pager a {
    background: #fff;
    width: 16px;
    height: 16px;
    border-radius: 0;
    border: solid 1px #666;
}

.bx-wrapper .bx-pager.bx-default-pager a:hover,
.bx-wrapper .bx-pager.bx-default-pager a.active {
    background: #0054a4;
}

.pagination {
    padding: 0 5px;
    margin: 0;
}

.hmeSlideImg,
.divCTA,
.divSpacer {
    top: 0;
}

.hmeSlideImg {
    z-index: 0;
    transition: 0.5s ease-in-out;
    opacity: 1;
}

.divAdTable {
    width: 100%;
    display: block;
    height: 100%;
    float: left;
    vertical-align: top;
    z-index: 2;
    position: absolute;
    bottom: 0;
}

.divAdTable a {
    color: #fff;
}

.divCTA {
    text-align: center;
    display: block;
    height: 100%;
    position: absolute;
    top: 0;
    bottom: 0;
    padding: 2% 0 0;
}

.dnnFormItem .SignupConfirm input[type="checkbox"] {
    width: 18px;
    height: 18px;
    margin-top: 3px;
}

.aCTAlink {
    background: rgba(51, 51, 51, 0.6);
    padding: 10px 20%;
    text-transform: uppercase;
    color: #fff;
    transform: translate3d(0, 0, 0);
    width: 100%;
}

a.aCTAlink:hover {
    color: #fff;
}

.imgCopy {
    text-transform: uppercase;
    color: #fff;
    margin: 10% 0 1%;
}

li:hover > .hmeSlideImg {
    transition: 0.5s ease-in-out;
    opacity: 0.8;
}

.callOutAd {
    display: block;
    position: relative;
}

.callOutInnerWrap {
    overflow: hidden;
    position: relative;
}

.callOut img {
    padding-bottom: 10px;
    z-index: 0;
}

.callOutimg {
    transition: 0.5s ease-in-out;
    opacity: 1;
}

.callOutCTA,
.sliderTwo .callOutCTA {
    display: block;
    width: 100%;
    height: 100%;
    max-height: 80px;
    z-index: 2;
    position: absolute;
    bottom: 10%;
    left: 0;
    background: rgba(51, 51, 51, 0.6);
    padding: 10px 0;
}

.callOutCTA a,
.sliderTwo .callOutCTA a {
    display: block;
    width: 100%;
    height: 100%;
}

.callOutAd:hover > .callOutInnerWrap > .callOutimg,
.sliderTwo li div:hover > .callOutInnerWrap > .callOutimg,
.sliderThree li .slideCell:hover > div > .callOutimg {
    transition: 0.5s ease-in-out;
    opacity: 0.6;
    cursor: pointer;
}

.callOutCTA span,
.sliderTwo .callOutCTA span {
    width: 100%;
    text-transform: uppercase;
    float: left;
    text-align: center;
    transform: translate3d(0, 0, 0);
}

.callOutCat {
    font-size: 2.3em;
    line-height: 1em;
}

.callOutCat,
.callOutSubTxt {
    color: #fff;
}

.sliderTwo img {
    padding-bottom: 10px;
}

.tab {
    position: relative;
}

span.prodPrice {
    font-size: 1.1em;
}

.hpTrendingTxt {
    text-align: center;
    text-transform: uppercase;
    font-size: 1.5em;
    font-weight: var(--font-weight-bold);
    letter-spacing: 1px;
    color: #747474;
    margin-top: 10px;
}

.tabSwitch .inActive {
    background: transparent;
    color: #747474;
}

.tabSwitch .inActive:hover {
    background-color: transparent;
    color: #747474;
    text-decoration: none;
}

.tabSwitch .active {
    background: transparent;
    color: #0054a4;
}

.tabSwitch .active::before,
.tabSwitch .inActive::before {
    content: "";
    display: block;
    height: 5px;
    width: 50%;
    position: absolute;
    background-color: #0054a4;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
}

.tabSwitch .inActive::before {
    background-color: transparent;
}

.tabSwitch .inActive:hover::before {
    background-color: #747474;
}

.hotspotTooltip {
    display: block !important;
}

.hotspotTop,
.hotspotBottom {
    text-align: center;
}

#One,
#Two {
    width: auto;
    float: left;
    padding: 10px 60px;
    border: 0;
    font-size: 1.2em;
}

#One:hover,
#Two:hover {
    cursor: pointer;
}

h3.prodTitle:hover {
    color: #0054a4;
}

.carouselIcon {
    padding-right: 4px;
}

.bxCarouselA li,
.bxCarouselB li {
    width: 20%;
    float: left;
}

.hpSlideInner {
    display: block;
    overflow: hidden;
}

.slideCell {
    padding-bottom: 9999px;
    margin-bottom: -9999px;
}

.noPadding {
    padding: 0 0 0 5px;
}

.botMargin10 {
    margin-bottom: 0;
}

.sliderThree img {
    padding-bottom: 10px;
}

.sliderThree .lgeImgWrap .callOutimg {
    margin-bottom: 0;
}

.lgeImgWrap {
    width: 100%;
    float: left;
    position: relative;
    min-height: 1px;
    margin-bottom: -10px;
}

.s-productthumbtext .s-producttext-price .finance-monthly-payment {
    color: #005a54;
    padding-top: 5px;
}

#mobSearchContainer {
    height: 45px !important;
    @media (min-width: 768px) and (max-width: 1021px) {
        height: 70px !important;
    }
}

@media (max-width: 767px) {
    #retSlideArrow,
    #advSlideArrow,
    #retSlide2Arrow,
    #advSlide2Arrow,
    #retSlide3Arrow,
    #advSlide3Arrow {
        display: none;
    }

    .bx-wrapper .bx-pager.bx-default-pager a {
        margin: 0 10px;
        border-radius: 0;
    }

    .slideCell {
        padding-bottom: 0;
        margin-bottom: 0;
    }
}

@media (min-width: 768px) {
    .divCTA {
        text-align: left;
        display: block;
        height: 100%;
        padding: 11% 0 0 7%;
    }

    .imgCopy {
        margin: 0 0 18%;
    }

    .largeTxt.imgCopy {
        margin-top: 11%;
    }

    .aCTAlink {
        width: auto;
    }

    .leftSlide {
        padding-left: 0;
    }

    .rightSlide {
        padding-right: 0;
    }

    .sliderTwo img {
        padding-bottom: 0;
    }

    .botMargin10 {
        margin-bottom: 10px;
    }

    .sliderThree img {
        padding-bottom: 0;
    }

    .sliderThree .lgeImgWrap .callOutimg {
        margin-bottom: -5px;
    }

    .nlPadding {
        padding-left: 0;
    }

    .nrPadding {
        padding-right: 0;
    }
}

.SD_NEW_ROWFIVE .callOut {
    margin-top: 10px;
}

@media (max-width: 767px) {
    .noPadding {
        padding: 0;
    }

    .SD_NEW_ROWFIVE .callOut {
        margin-top: 0;
    }

    .SD_NEW_ROWTHREE .sliderWrapper {
        padding: 0;
        margin-bottom: 0;
    }

    .sliderThree .lgeImgWrap .callOutimg {
        margin-bottom: 10px;
    }

    .bxslider2,
    .bxslider2 li {
        width: 100% !important;
    }
}

#Body {
    font-size: 12px !important;
    font-weight: var(--font-weight-regular);
    font-style: normal;
}

:focus {
    outline: 1px dotted #999;
}

.content {
    border: 12px solid #fff;
}

div.TopLinkMenu > a .ico,
nav#topMenu > ul li.myidTag a::after,
.am-menu .newDrop ul li.myidTag .menuitemtext::after,
.ChildCategoriesListWrapper #ChildCategoriesList li a::after,
#mobileSearchTriggerBtn span.searchIco,
.MobileMenuContentWrap .glyphicon.glyphicon-chevron-right::before,
.FooterHeader span,
.dvSearch .TextBoxClear::before,
.HelpIco,
.MenuCloseActive,
.uscSite nav#topMenu ul.RootGroup li.root.uscxsdBackSD > a::before {
    background-image: url("/images/core/sd-sprite-sheet-v12.svg");
}

.ControlWrap .accountBlock .login .ico,
#topLinkMenu ul li.TopLink .ico,
#divBagTotalLink a#aBagLink #bagQuantityContainer,
.ControlWrap .WishList .wishQuantityContainer .WishIcon,
.ControlWrap .storeGroup #topLinkMenu .TopLink a::before,
.ToplinksGroup .search #cmdSearch span,
.Copyright .footerSocial ul li a,
.menu-trigger::before,
.menu-trigger.open::before,
.MobileMenuContentWrap > .mobMenuGroup > li.root > a::before,
.MobileMenuContentWrap > .mobMenuGroup ~ div li.has-dropdown > a::before,
.MobileMenuContentWrap > .mobMenuGroup ~ ul li a.mob-login::before,
.FooterWrap .FooterTop .footerStoreFinder a::before,
.FooterWrap .FooterTop .footerCustServ a::before,
.back-to-top,
.evanDetails .pdpUSP a::before,
.selectedFilters li.selectedFilter > a::before,
#spnSearchIcon .glyphicon,
.paginationWrapper .PrevNextButtons.PageNumber .NextLink,
.paginationWrapper .PrevNextButtons.PageNumber .PrevLink,
.evanProdList .QuickLookIcon,
.evanProdList .WishIcon,
.evanProdList .layout li a,
.evanDetails .pdpWishListIco,
.evanDetails .productImage .mainImageButtonPrev a span,
.evanDetails .productImage .mainImageButtonNext a span,
#divCurrencyLanguageSelector .spanCurrencyLanguageSelector .flag,
.evanDetails .hotspotbuy .QuickLookIcon,
.evanDetails .hotspotbuy .WishIcon {
    background-image: url("/images/core/Evans-Cycles-sprite-sheet-v12.svg");
}

.sdlProdList .QuickLookIcon,
.sdlProdList .WishIcon,
.productFilterTitleBox .glyphicon::before,
.mobFilterAppIcon::before,
.Responsive .mobDdClose .clsFilterIcon::before,
.evanDetails .SuggestedProduct .WishIcon,
a.bx-prev,
a.bx-next,
.evanDetails #mainDetails p.preOrder::before,
.lillBasket a.removeClass,
.s-basket-remove-button a .deleteItemIcon,
.updateIcon,
#li_placement .SuggestedProduct .QuickLookIcon,
#li_placement .SuggestedProduct .WishIcon,
#li_placement .hotspotbuy .QuickLookIcon,
#li_placement .hotspotbuy .WishIcon,
.evanDetails .SuggestedProduct .QuickLookIcon,
.evanDetails .AltProdDet #piThumbs #sPrevImg span::before,
.evanDetails .AltProdDet #piThumbs #sNextImg span::before,
#divBagItemsChild .bagHeader #clsBasketMob::before,
.lillBasket .PreviousEnable,
.lillBasket .NextEnable,
.lillBasket .PreviousDisable,
.lillBasket .NextDisable,
.BasketNew .BasketQuantBut::before,
.BasketNew .BasketQuantBut.s-basket-plus-button::before,
.Responsive .BasketNew #BasketDiv .NewUpdateQuant:hover .updateIcon,
.sdlProdList .catTemplateNew .viewMore span.OpenView::after,
.sdlProdList .catTemplateNew .viewMore span.ClosedView::after,
.FooterHeader span {
    background-image: url("/images/core/sd-new-main-sprite-15-v12.svg");
}

.modal .close {
    background-image: url("/images/core/sd-new-main-sprite-15-v12.svg") !important;
    width: 30px;
    height: 30px;
    background-color: transparent !important;
    background-size: 700px 700px !important;
    background-position: -265px -491px !important;
    border: 0;
    transition: none;
}

.socialSitesWrapper li a {
    background-image: url("/images/core/sd-new-footer-icons.svg");
    background-repeat: no-repeat;
    text-indent: -9999px;
    display: inline-block;
    height: 100%;
    width: 100%;
    max-width: 35px;
    background-size: 1100px 1100px;
    opacity: 0.8;
}

.byPostGroup,
#contactuscontent select,
#Cancelled-popup-container,
#InProgress-popup-container,
#NewOrder-popup-container,
#Rejected-popup-container,
#Shipped-popup-container,
#Submitted-popup-container,
.csHomeTable,
#divBagItems {
    border: 1px solid #ccc;
}

.prc_commentpostingpanelcommentsectiontextboxcomment,
.prc_ModShow_Textbox {
    border: 2px solid #ccc;
}

.DontForgetText {
    border-bottom: 1px solid #b2b2b2;
}

.RadTabStripTop_Vista .rtsLevel .rtsLink,
.RadTabStripTop_Vista .rtsLevel .rtsOut,
.RadTabStripBottom_Vista .rtsLevel .rtsLink {
    background-image: none !important;
}

.whiteBoxBrand .greyBgBrand.boxContentBrand,
.whiteBoxBrand {
    background: none repeat scroll 0 0 #fff !important;
}

.whiteBoxBrand .boxContentBrand {
    border: none !important;
}

.c_BlackTitle h3 {
    border-bottom: 2px solid #ccc;
}

.relatedquestions h4 {
    border-bottom: 1px dotted #ccc;
}

hr {
    border-top: 1px solid #ccc;
}

.evanProdList hr {
    border-top: 2px solid #ffd51e;
}

.ui-slider .ui-slider-handle {
    border: 1px solid #999;
}

.productFilter-slider {
    border: 0;
}

.lillBasket #divBagItems,
#divAjaxLoaderImage,
#divEmptyErrorMessage {
    box-shadow: none;
    border: 1px solid #ddd;
    z-index: 5925 !important;
    top: 70px;
}

#divAjaxLoaderImage,
#divEmptyErrorMessage {
    z-index: 5926 !important;
}

#pnlWebPercentOff {
    padding-top: 5px;
}

.weWrap {
    font-size: 1em;
}

.lillBasket #aCheckout {
    font-size: 1.2em;
    letter-spacing: 0.1em;
    padding: 16px 5px;
}

.lillBasket #aViewBag,
.lillBasket #divBagTotalDiscount {
    font-size: 1.1em;
}

.lillBasket #aViewBag {
    width: calc(34% - 5px);
    line-height: 1.15em;
    letter-spacing: 0.1em;
}

.lillBasket #spanCheckout {
    width: calc(66% - 5px);
}

.lillBasket #ulBag {
    font-size: 1em;
}

.lillBasket .bagContentItemWrap {
    font-size: 1.1em;
}

.lillBasket .PreviousEnable,
.lillBasket .NextEnable,
.lillBasket .PreviousDisable,
.lillBasket .NextDisable {
    font-size: 11px;
    background-size: 1000px 1000px;
    opacity: 0.3;
    background-position: -228px -560px;
}

.lillBasket .NextEnable,
.lillBasket .NextDisable {
    background-position: -228px -521px;
}

.lillBasket .PreviousEnable,
.lillBasket .NextEnable {
    opacity: 1;
}

.infoTabPage h2,
.ProdDetRight .infoaccordion .panel-body h2 {
    font-size: 1.1em;
}

.evanDetails #infoTabs .infoTabPageContainer .infoTabPage ul li {
    width: 100%;
    list-style-position: inside;
}

.currencySelector li label {
    font-size: 1.1em;
}

#divCurrencySelector span.currencyHeader,
#divLanguageSelector span.languageHeader {
    font-size: 1.1em;
}

.HelpIco {
    background-position: -19px -273px;
    background-size: 700px 700px;
    font-size: 11px;
}

.s-checkout-fix .secondary a,
.BagandCheckoutBtn .secondary2 a {
    border: 0;
}

.SliderContain .ui-slider-horizontal .ui-slider-handle {
    margin-left: -3px;
    margin-right: 0;
}

.fieldcheckbox {
    width: 62%;
}

#address-sec {
    margin: 0;
}

.dontForget,
.dontForget img {
    width: 100%;
    height: auto;
}

.ui-state-default a,
.ui-widget-content .ui-state-default a,
.ui-widget-header .ui-state-default a {
    border: 1px solid #0463c4;
}

.ui-state-active a,
.ui-widget-content .ui-state-active a,
.ui-widget-header .ui-state-active a {
    border: 1px solid #000;
}

#navigation-container {
    margin: 1% 0 2%;
}

.wrapper h1 {
    overflow: hidden;
}

.HeaderWrap {
    box-shadow: none;
    top: 0;
    @media (max-width: 767px) {
        height: 100px;
    }
}

.ContentWrapper {
    width: 100%;
    max-width: 1440px;
    padding: 10px 15px 15px;
}

.ToplinksGroup,
.dbc,
.footerContainer .footerMainMenu,
.FooterPane,
.FooterPane2 {
    margin: 0 auto;
    max-width: 1440px;
    display: block;
    float: none;
    position: relative;
    padding: 0 10px;
}

#divCurrencyLanguageSelector {
    height: 100%;
    float: none;
    position: static;
    width: 100%;
}

#divCurrencyLanguageSliding {
    line-height: normal;
}

.currencyLanguageSlider {
    top: 74px;
    right: 5px;
    z-index: 5930 !important;
}

#topMenuWrapper {
    top: 104px;
    z-index: 0 !important;
    border-bottom: 1px solid #000;
}

.menu-trigger {
    background: none;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    float: none;
}

.menu-trigger::before {
    content: "";
    display: block;
    background-size: 1250px 1250px;
    background-position: -395px -47px;
    width: 45px;
    height: 45px;
    font-size: 11px;
}

.menu-trigger.open::before {
    background-position: -298px -233px;
    background-size: 1450px 1450px;
}

.ToplinksGroup .bsLogo {
    height: 100%;
}

#currencyLanguageSelector {
    background: none transparent;
    border: 0;
    height: 100%;
    width: 100%;
    float: none;
}

.Responsive .currencyLanguageSlider {
    width: 150px;
    text-align: center;
    right: 85px;
}

.Responsive .currencyLanguageSlider li {
    width: 100%;
    display: block;
}

.Responsive .currencyLanguageSlider li.activeHover label {
    font-weight: var(--font-weight-bold);
    color: #005a54;
}

.Responsive .currencyLanguageSlider li input {
    display: none;
}

.ToplinksGroup .search {
    position: relative;
}

.ToplinksGroup .search .dvSearch {
    background-color: #fff;
    border-radius: 5px;
    transition: all 400ms linear 0s;
    box-shadow: 0 2px 4px #ccc;
}

.ToplinksGroup .search #cmdSearch {
    position: relative;
    height: 35px;
    width: 46px;
    background-color: #005a54;
    border-radius: 0 5px 5px 0;
    cursor: pointer;
}

.ToplinksGroup .search #cmdSearch span {
    position: absolute;
    left: 50%;
    top: 50%;
    width: 30px;
    height: 30px;
    transform: translate(-50%, -50%);
    background-position: -256px -376px;
    background-size: 1000px 1000px;
    font-size: 12px;
}

.ToplinksGroup .search #txtSearch {
    border: 1px solid #ccc;
    border-right: none;
    border-radius: 5px 0 0 5px;
    float: left;
    width: calc(100% - 46px);
    margin-left: 0;
    height: 35px;
    padding: 0 25px 0 10px;
    background-color: transparent;
}

.ToplinksGroup .search #txtSearch:focus {
    background-color: transparent;
    border-color: #005a54;
    border-right: none;
}

@media (min-width: 1022px) {
    .ToplinksGroup .search #txtSearch {
        height: 46px;
        padding: 5px 10px 5px 20px;
    }

    .ToplinksGroup .search #cmdSearch {
        width: 46px;
        height: 46px;
    }
}

.dvSearch .TextBoxClear {
    position: absolute;
    top: 50%;
    right: 46px;
    height: 25px;
    width: 25px;
    border: none;
    box-shadow: none;
    font-size: 11px;
    font-family: inherit;
    line-height: normal;
    display: block;
    padding: 0;
    transform: translateY(-50%);
}

.dvSearch .TextBoxClear::before {
    content: " ";
    background-size: 450px 450px;
    display: inline-block;
    width: 100%;
    height: 100%;
    background-position: -81px -94px;
}

.LanguageCurrency {
    margin: 0;
}

.currencyLanguageSelector .spanCurrencyLanguageSelector {
    padding: 0;
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
}

.currencyLanguageSelector .spanCurrencyLanguageSelector .flag {
    float: none;
    display: inline-block;
    background-color: transparent;
    width: 26px;
    height: 26px;
}

.currencyLanguageSelector .spanCurrencyLanguageSelector .flag.global {
    background-position: -68px -261px;
}

.currencyLanguageSelector .spanCurrencyLanguageSelector .flag.at {
    background-position: -82px -38px;
}

.currencyLanguageSelector .spanCurrencyLanguageSelector .flag.au {
    background-position: -27px 0;
}

.currencyLanguageSelector .spanCurrencyLanguageSelector .flag.be {
    background-position: 1px -76px;
}

.currencyLanguageSelector .spanCurrencyLanguageSelector .flag.bg {
    background-position: -110px 0;
}

.currencyLanguageSelector .spanCurrencyLanguageSelector .flag.ch {
    background-position: -110px -114px;
}

.currencyLanguageSelector .spanCurrencyLanguageSelector .flag.cs {
    background-position: -54px 0;
}

.currencyLanguageSelector .spanCurrencyLanguageSelector .flag.cz {
    background-position: -54px 0;
}

.currencyLanguageSelector .spanCurrencyLanguageSelector .flag.de {
    background-position: -81px 0;
}

.currencyLanguageSelector .spanCurrencyLanguageSelector .flag.ee {
    background-position: -109px -38px;
}

.currencyLanguageSelector .spanCurrencyLanguageSelector .flag.es {
    background-position: 1px -19px;
}

.currencyLanguageSelector .spanCurrencyLanguageSelector .flag.fi {
    background-position: -109px -57px;
}

.currencyLanguageSelector .spanCurrencyLanguageSelector .flag.fr {
    background-position: -27px -19px;
}

.currencyLanguageSelector .spanCurrencyLanguageSelector .flag.gr {
    background-position: -81px -75px;
}

.currencyLanguageSelector .spanCurrencyLanguageSelector .flag.hr {
    background-position: -109px -19px;
}

.currencyLanguageSelector .spanCurrencyLanguageSelector .flag.hu {
    background-position: -27px -38px;
}

.currencyLanguageSelector .spanCurrencyLanguageSelector .flag.ie {
    background-position: -137px -261px;
}

.currencyLanguageSelector .spanCurrencyLanguageSelector .flag.is {
    background-position: 0 -38px;
}

.currencyLanguageSelector .spanCurrencyLanguageSelector .flag.it {
    background-position: -81px -19px;
}

.currencyLanguageSelector .spanCurrencyLanguageSelector .flag.lt {
    background-position: 0 -94px;
}

.currencyLanguageSelector .spanCurrencyLanguageSelector .flag.lu {
    background-position: -27px -94px;
}

.currencyLanguageSelector .spanCurrencyLanguageSelector .flag.lv {
    background-position: -110px -76px;
}

.currencyLanguageSelector .spanCurrencyLanguageSelector .flag.md {
    background-position: -137px 1px;
}

.currencyLanguageSelector .spanCurrencyLanguageSelector .flag.mt {
    background-position: -54px -94px;
}

.currencyLanguageSelector .spanCurrencyLanguageSelector .flag.my {
    background-position: -137px -19px;
}

.currencyLanguageSelector .spanCurrencyLanguageSelector .flag.nl {
    background-position: -54px -75px;
}

.currencyLanguageSelector .spanCurrencyLanguageSelector .flag.no {
    background-position: -81px -95px;
}

.currencyLanguageSelector .spanCurrencyLanguageSelector .flag.nz {
    background-position: -54px -38px;
}

.currencyLanguageSelector .spanCurrencyLanguageSelector .flag.pl {
    background-position: -27px -56px;
}

.currencyLanguageSelector .spanCurrencyLanguageSelector .flag.pt {
    background-position: 1px -57px;
}

.currencyLanguageSelector .spanCurrencyLanguageSelector .flag.ro {
    background-position: -109px -95px;
}

.currencyLanguageSelector .spanCurrencyLanguageSelector .flag.rs {
    background-position: -27px -114px;
}

.currencyLanguageSelector .spanCurrencyLanguageSelector .flag.sa {
    background-position: 0 -113px;
}

.currencyLanguageSelector .spanCurrencyLanguageSelector .flag.se {
    background-position: -81px -57px;
}

.currencyLanguageSelector .spanCurrencyLanguageSelector .flag.si {
    background-position: -27px -75px;
}

.currencyLanguageSelector .spanCurrencyLanguageSelector .flag.sk {
    background-position: -54px -57px;
}

.currencyLanguageSelector .spanCurrencyLanguageSelector .flag.ua {
    background-position: -54px -114px;
}

.currencyLanguageSelector .spanCurrencyLanguageSelector .flag.us {
    background-position: -82px -114px;
}

.currencyLanguageSelector .spanCurrencyLanguageSelector p {
    float: none;
    font-size: 12px;
    width: 100%;
    max-width: 55px;
    padding: 0;
    color: #005a54;
    white-space: nowrap;
    order: 2;
}

.HeaderLeftPane {
    float: left;
    height: 100%;
}

.ControlWrap {
    max-width: 390px;
}

.ControlWrap,
.ControlWrap > div {
    float: right;
}

.ControlWrap .bagWishBlock,
.ControlWrap .languageBlock,
.ControlWrap .accountBlock,
.ControlWrap .accountBlock .login,
.ControlWrap .accountBlock .login .ico,
.accountBlock .AccountLink,
.accountBlock .SignInLink {
    height: 100%;
}

.ControlWrap .storeGroup,
.ControlWrap .languageBlock {
    text-align: center;
    width: 55px;
    height: 48px;
    margin-right: 10px;
}

a#aWishListLink,
.ControlWrap .bagWishBlock #divBagTotalLink,
#divBagTotalLink a#aBagLink #bagQuantityContainer,
.ControlWrap .accountBlock .login,
.ControlWrap .languageBlock,
.ControlWrap .storeGroup {
    line-height: normal;
}

.ControlWrap .bagWishBlock {
    text-align: center;
    width: 120px;
    height: 65px;
}

.ControlWrap .accountBlock {
    position: relative;
    max-width: 65px;
    margin-right: 10px;
    min-width: 55px;
    width: auto;
    padding-right: 10px;
}

.ControlWrap .accountBlock .AccountLink,
.ControlWrap .accountBlock .SignInLink {
    position: relative;
}

.ControlWrap .accountBlock::before {
    content: "";
    display: block;
    position: absolute;
    width: 1px;
    right: 0;
    height: 100%;
    background-color: #ccc;
}

.ControlWrap .accountBlock .login,
.ControlWrap .accountBlock .lillAccounts > a {
    font-size: 1.3em;
}

.ControlWrap .accountBlock .login {
    display: block;
    min-width: 55px;
    text-align: center;
}

.ControlWrap .accountBlock .login .ico {
    display: block;
    margin: 0 auto;
    width: 26px;
    height: 28px;
    background-size: 1000px 1000px;
    background-position: -40px -46px;
}

.ControlWrap .accountBlock .login .hidden-xs {
    font-size: 12px;
    line-height: normal;
    display: block;
    color: #005a54;
    margin-top: 6px;
    vertical-align: top;
    max-height: 14px;
}

.ControlWrap .storeGroup #topLinkMenu .TopLink {
    line-height: normal;
    float: none;
}

.ControlWrap .storeGroup #topLinkMenu .TopLink a {
    min-width: 55px;
}

.ControlWrap .storeGroup #topLinkMenu .TopLink a::before {
    content: "";
    display: block;
    height: 28px;
    width: 26px;
    margin: 0 auto;
    background-size: 1000px 1000px;
    background-position: -97px -47px;
    font-size: 12px;
}

.ControlWrap .storeGroup #topLinkMenu .TopLink a span {
    color: #005a54;
    line-height: normal;
    display: block;
    margin-top: 6px;
    height: auto;
    max-height: 14px;
}

.ControlWrap .accountBlock .login:hover span,
.ControlWrap .storeGroup #topLinkMenu .TopLink a:hover span,
#topLinkMenu ul.TopLinkMenu > li > a:hover span.logoutTxt,
.ControlWrap .WishList a:hover .wishQuantityContainer #lblWishList,
#currencyLanguageSelector:hover p,
#divBag a:hover #bagTotal {
    text-decoration: underline;
}

.HeaderIcons > li a:hover,
#divBagTotalLink a#aBagLink:hover {
    background-color: transparent;
}

.FooterPane,
.FooterPane2 {
    padding: 0;
}

ul.StoreList li {
    width: auto;
    padding: 0 6px;
    float: left;
}

ul.StoreList {
    height: 26px;
    display: inline-block;
    padding-right: 17px;
}

ul.StoreList li a {
    color: #0051a1;
}

ul.StoreList li a:hover {
    text-decoration: underline;
    color: #ef2010;
}

#topLinkMenu ul li {
    text-align: left;
}

#topLinkMenu ul li.TopLinkDrop ul {
    width: 280px;
    display: none;
    padding: 0;
    left: 50%;
    transform: translateX(-50%);
    position: absolute;
    z-index: 5930 !important;
}

#topLinkMenu ul li.TopLinkDrop ul::before {
    content: "";
    display: none;
    position: absolute;
    width: 16px;
    height: 10px;
    top: 6px;
    left: 50%;
    transform: translateX(-50%);
    border-bottom: 8px solid #f3f3f3;
    border-left: 8px solid transparent;
    border-right: 8px solid transparent;
}

#topLinkMenu ul li.TopLinkDrop ul:hover::before,
#topLinkMenu ul li.TopLinkDrop a:hover + ul::before {
    display: block;
}

#topLinkMenu ul li.TopLinkDrop ul li,
#topLinkMenu ul li.TopLinkDrop ul.TopSubLinkMenu li a {
    width: 100%;
}

#topLinkMenu ul li.TopLinkDrop ul li {
    background-color: #fff !important;
    border-bottom: 1px solid #f1f1f1;
    border-left: 1px solid #f1f1f1;
    border-right: 1px solid #f1f1f1;
    line-height: 1em;
}

#topLinkMenu ul li.TopLinkDrop ul li.FirstTopSub {
    border-top: 1px solid #f1f1f1;
    margin-top: 16px;
}

#topLinkMenu ul li.TopLinkDrop ul li.AccOrderIcon {
    border-bottom: 1px solid #9a9a9a;
}

#topLinkMenu ul li.TopLinkDrop ul li.SignOut {
    text-align: center;
    border: none;
}

#topLinkMenu ul li.TopLinkDrop ul li.SignOut a {
    display: inline-block;
    padding: 10px;
    letter-spacing: 0.1em;
    background-color: #005a54;
}

#topLinkMenu ul li.TopLinkDrop ul li.SignOut a:hover {
    background-color: #03312e;
}

#topLinkMenu ul li.TopLinkDrop ul li.SignOut a span {
    color: #fff;
}

#topLinkMenu ul.TopLinkMenu > li > a {
    font-size: 1em;
    line-height: 1em;
}

#topLinkMenu ul.TopLinkMenu > li > a span.logoutTxt {
    display: block;
    height: auto;
    margin-top: 6px;
    font-size: 12px;
    max-height: 14px;
    line-height: normal;
    color: #005a54;
}

#topLinkMenu ul.TopLinkMenu > li {
    width: 100%;
    line-height: 42px;
    border: 0;
}

#topLinkMenu ul li.TopLink a {
    padding: 0;
    background-image: none;
    background-position: initial;
    background-repeat: initial;
    height: 100%;
    display: block;
    color: #333;
    min-width: 55px;
}

#topLinkMenu ul li.TopLink > a {
    text-align: center;
}

#topLinkMenu ul.TopLinkMenu > li span,
.RightBarLinks div.TopLinkMenu > a span {
    display: inline-block;
    height: 100%;
    vertical-align: top;
}

#topLinkMenu ul li.TopLink .ico {
    padding: 0;
    background-size: 1000px 1000px;
    width: 26px;
    height: 28px;
    display: block;
    margin: 0 auto;
    background-position: -40px -46px;
}

#topLinkMenu ul.TopSubLinkMenu > li span {
    font-size: 1.1em;
}

#topLinkMenu ul.TopSubLinkMenu li .ico {
    width: 22px;
    height: 24px;
    margin-right: 10px;
    vertical-align: middle;
    display: inline-block;
    font-size: 11px;
    background-size: 700px 700px;
}

#topLinkMenu .TopSubLinkMenu li.AccIcon .ico {
    background-position: -27px -31px;
}

#topLinkMenu .TopSubLinkMenu li.AccIcon a:hover .ico {
    background-position: -27px -31px;
}

#topLinkMenu .TopSubLinkMenu li.AccWishIcon .ico {
    background-position: -145px -30px;
}

#topLinkMenu .TopSubLinkMenu li.AccWishIcon a:hover .ico {
    background-position: -145px -30px;
}

#topLinkMenu .TopSubLinkMenu li.AccPrefIcon .ico {
    background-position: -470px -29px;
}

#topLinkMenu .TopSubLinkMenu li.AccPrefIcon a:hover .ico {
    background-position: -470px -29px;
}

#topLinkMenu .TopSubLinkMenu li.AccOrderIcon .ico {
    background-position: -433px -27px;
}

#topLinkMenu .TopSubLinkMenu li.AccOrderIcon a:hover .ico {
    background-position: -433px -27px;
}

#topLinkMenu .TopSubLinkMenu li.SignOut .ico {
    display: none;
}

#topLinkMenu .TopLinkMenu .TopSubLinkMenu li a {
    padding: 15px;
    background: none;
    line-height: 2em;
    background-color: #fff;
    transition: background-color 0.3s ease-in;
}

#topLinkMenu .TopLinkMenu .TopSubLinkMenu li a:hover {
    background-color: #eee;
}

span#bagTotal {
    min-width: 60px;
}

.LogoWrap a {
    text-align: center;
    line-height: 42px;
}

#divBagTotalLink a#aBagLink {
    width: 100%;
    display: block;
    text-align: center;
    height: 100%;
    background: none;
    line-height: normal;
    padding: 0;
    min-width: 55px;
}

#divBagTotalLink a#aBagLink #bagQuantityContainer {
    height: 26px;
    background-size: 1100px 1100px;
    background-position: -167px -54px;
    background-repeat: no-repeat;
    width: 30px;
    display: inline-block;
    vertical-align: top;
    font-size: 12px;

    .global-icon-bag svg {
        width: 30px;
    }
}

.active #divBagTotalLink a#aBagLink #bagQuantityContainer {
    background-position: -167px -54px;
}

#divBagTotalLink a#aBagLink #bagTotalContainer,
#divBag #bagTotal {
    display: block;
}

.ControlWrap .WishList {
    width: 55px;
    height: 100%;
    margin-right: 10px;
}

.ControlWrap .WishList .wishQuantityContainer {
    display: block;
    position: relative;
    line-height: normal;
    text-align: center;
}

.ControlWrap .WishList .wishQuantityContainer .WishIcon {
    display: inline-block;
    width: 30px;
    height: 26px;
    float: none;
    background-size: 1050px 1050px;
    background-position: -219px -46px;
}

.ControlWrap .WishList .wishQuantityContainer #lblWishList {
    display: block;
    margin: 3px 0 0;
    color: #005a54;
    min-width: 0;
    font-size: 12px;
    max-height: 14px;
}

.ControlWrap .WishList .wishQuantityContainer #lblWishListCount {
    font-size: 12px;
    left: 50%;
    top: 8px;
    background-color: transparent;
    color: #ffd51e;
    transform: translateX(-50%);
}

.Responsive #divBag {
    width: 55px;
    padding: 0;
    font-size: 1em;
}

#divBag #bagTotal {
    margin: 7px 0 0;
    color: #005a54;
    min-width: 0;
    font-size: 12px;
    max-height: 14px;
}

.ASCdelivery2 .FullAdvert {
    margin-bottom: 5px;
}

.FourHovers ul li > a {
    margin-bottom: 0;
}

.FourHovers ul li > div {
    padding: 10px;
    border-bottom: 1px solid #ddd;
    border-left: 1px solid #ddd;
    border-right: 1px solid #ddd;
}

.advert .swiper-container {
    margin: 0 auto 20px;
}

#divBagTotalLink #bagName {
    text-transform: uppercase;
    display: inline-block;
    font-weight: var(--font-weight-bold);
}

#bagQuantityContainer {
    width: 100%;
    display: block;
    height: 100%;
}

.lillBasket #aBagLink span#bagQuantity {
    background: none;
    color: #ffd51e;
    height: auto;
    padding: 0;
    margin: 0;
    font-size: 12px;
    display: inline-block;
    line-height: normal;
    vertical-align: -5px;
}

.lillBasket #aBagLink span#bagQuantity.empty {
    display: none;
}

.Basket .elevated-cart.elevated-cart-is-empty .cart-page__empty .global-icon-bag svg {
    height: 24px;
}

.basketLink .langlogin .login {
    display: block;
    padding-top: 12px;
    padding-left: 35px;
    height: 100%;
    font-size: 1.1em;
}

.Home .ContentWrapper {
    padding: 0;
    max-width: 1920px;
}

section.mainBody > .row > div {
    min-height: 0;
}

#findAstore a {
    padding-top: 12px;
    display: block;
    padding-left: 22px;
    height: 100%;
    font-size: 1.1em;
}

.storefind {
    display: block;
    height: 40px;
}

.basketLink {
    height: 40px;
    padding: 0;
}

.PopToRight,
.CardsSocial,
#dnn_footerPaneR {
    display: none;
}

.coprightmega {
    text-align: left;
}

.Responsive .coprightmega {
    padding-top: 7px;
}

.FooterSubGroup .FooterHeader {
    margin-bottom: 0;
    font-size: 1.3em;
    font-weight: var(--font-weight-bold);
    text-transform: uppercase;
    color: #005a54;
    border: none;
    float: none;
}

.FooterGroupLink li {
    line-height: 1.6em;
    padding: 0;
}

.FooterGroupLink li a {
    padding: 5px 10px;
    font-size: 1.25em;
    color: #005a54;
    letter-spacing: 0.03em;
}

.FooterGroupLink li a:hover {
    text-decoration: underline;
}

#FootcmdSearch {
    padding: 7px 4% 7px 6%;
}

li.shopAllLink a {
    font-size: 1.3em;
    text-transform: uppercase;
    font-weight: var(--font-weight-bold) !important;
}

nav#topMenu > ul .NewMenuWrap li.sdmColHeader,
nav#topMenu > ul .NewMenuWrap li.sdmColSubHeader,
nav#topMenu > ul .NewMenuWrap li.sdmColHeaderHidden {
    height: 32px;
    border-bottom: 1px solid #bbb;
}

li.sdmColTopHeader a {
    font-weight: var(--font-weight-bold) !important;
    font-size: 1.3em !important;
    text-decoration: underline;
}

#divBag #divBagTotalLink {
    width: 100%;
    padding: 0;
    font-size: 1em;
}

#divBag #bagName {
    display: none;
}

.megacards span {
    margin: 10px 0;
}

.back-to-top {
    position: fixed;
    bottom: 1em;
    right: 1em;
    text-decoration: none;
    font-size: 12px;
    padding: 1em;
    z-index: 10060;
    width: 50px;
    height: 50px;
    background-position: -414px -261px;
    background-size: 1600px 1600px;
    background-repeat: no-repeat;
    visibility: hidden;
    opacity: 0;
    transition:
        opacity 0.5s linear,
        visibility 0s linear 0.5s;
}

.back-to-top:hover {
    background-position: -414px -261px;
}

.show-back-to-top .back-to-top {
    visibility: visible;
    opacity: 1;
    transition: opacity 0.5s linear;
}

nav#topMenu ul li.root .SubMenuWrapper {
    width: 100%;
    position: absolute;
    top: auto;
    text-align: center;
    font-size: 1em;
    border: 0;
    left: 50% !important;
    border-left: 1px solid #ddd;
    border-right: 1px solid #ddd;
    border-bottom: 1px solid #ddd;
    background-color: #fff;
    max-width: 1600px;
    transform: translateX(-50%);
}

nav#topMenu ul li.root.newDrop .SubMenuWrapper {
    padding: 0;
}

nav#topMenu ul li.root .bottomLinkStrip {
    text-align: center;
    padding: 6px 0;
}

nav#topMenu ul li.root.newDrop .popularBot {
    text-align: center;
    padding: 10px 0;
    background-size: 100% 1px;
    background-position: 100% 0;
    background-repeat: no-repeat;
    background-image: linear-gradient(
        to right,
        hsla(0, 0%, 0%, 0) 0%,
        hsla(0, 0%, 0%, 0.3) 50%,
        hsla(0, 0%, 0%, 0) 100%
    );
}

nav#topMenu li.root .SubMenuWrapper .popularBot li {
    width: 9.625%;
    float: left;
}

nav#topMenu li.root .SubMenuWrapper .popularBot li.itemPopular,
nav#topMenu li.root .SubMenuWrapper .popularBot li:first-of-type {
    width: 23%;
}

nav#topMenu li.root .SubMenuWrapper .popularBot li.itemPopular a,
nav#topMenu li.root .SubMenuWrapper .popularBot li:first-of-type a {
    padding: 15px 5px;
    height: auto;
    max-width: none;
    opacity: 1;
    text-indent: 0;
    font-size: 1em;
    text-transform: uppercase;
    font-weight: var(--font-weight-bold);
    letter-spacing: 0.1em;
    color: #000;
}

nav#topMenu li.root.MenuGroupD .SubMenuWrapper .popularBot li a {
    padding: 10px 5px;
    max-width: none;
}

nav#topMenu li.root .SubMenuWrapper .popularBot li.itemPremier {
    width: 14.5%;
}

nav#topMenu li.root .SubMenuWrapper .popularBot li.itemEFL,
nav#topMenu li.root .SubMenuWrapper .popularBot li.itemSPL {
    width: 6.5%;
}

nav#topMenu li.root .SubMenuWrapper .popularBot li.itemBundesliga {
    width: 11.5%;
}

nav#topMenu li.root .SubMenuWrapper .popularBot li.itemLa,
nav#topMenu li.root .SubMenuWrapper .popularBot li.itemLigue,
nav#topMenu li.root .SubMenuWrapper .popularBot li.itemSerie {
    width: 8%;
}

nav#topMenu li.root .SubMenuWrapper .popularBot li.itemInternational {
    width: 14%;
}

nav#topMenu li.root .SubMenuWrapper .popBrands li a,
nav#topMenu ul li.root.brandsMenu .SubMenuWrapper ul li.level1 a::before,
nav#topMenu ul li.root.promoMenu .SubMenuWrapper ul li.level1 a::before,
.hpTrending .tabSwitch .tab::before {
    background: url("/images/core/menu-logos-final-2019-v3c.svg") no-repeat;
}

nav#topMenu li.root .SubMenuWrapper .popBrands li a {
    background-size: 800px 800px;
    height: 50px;
    opacity: 0.5;
    text-indent: -9999px;
    font-size: 11px;
    padding: 0;
    max-width: 90px;
    margin: 0 auto;
}

nav#topMenu li.root .SubMenuWrapper .popBrands li a:hover {
    opacity: 1;
}

nav#topMenu li.root .SubMenuWrapper .popBrands li.itemNike a {
    background-position: 0 -179px;
}

nav#topMenu ul li.root.brandsMenu .SubMenuWrapper ul li.level2.b_nike a::before,
nav#topMenu ul li.root.promoMenu .SubMenuWrapper ul li.level1.b_nike a::before {
    background-position: -0.4em -11.9em;
}

nav#topMenu li.root .SubMenuWrapper .popBrands li.itemadidas a {
    background-position: -79px -176px;
    max-width: 85px;
}

nav#topMenu ul li.root.brandsMenu .SubMenuWrapper ul li.level2.b_adidas a::before,
nav#topMenu ul li.root.promoMenu .SubMenuWrapper ul li.level1.b_adidas a::before {
    background-position: -5.6em -12em;
}

nav#topMenu li.root .SubMenuWrapper .popBrands li.itemPUMA a {
    background-position: -157px -178px;
}

nav#topMenu ul li.root.brandsMenu .SubMenuWrapper ul li.level2.b_puma a::before,
nav#topMenu ul li.root.promoMenu .SubMenuWrapper ul li.level1.b_puma a::before {
    background-position: -11em -12.3em;
}

nav#topMenu li.root .SubMenuWrapper .popBrands li.itemKarrimor a {
    background-position: -241px -177px;
}

nav#topMenu ul li.root.brandsMenu .SubMenuWrapper ul li.level2.b_karrimor a::before,
nav#topMenu ul li.root.promoMenu .SubMenuWrapper ul li.level1.b_karrimor a::before {
    background-position: -17.2em -11.9em;
}

nav#topMenu ul li.root.brandsMenu .SubMenuWrapper ul li.level2.b_kappa a::before,
nav#topMenu ul li.root.promoMenu .SubMenuWrapper ul li.level1.b_kappa a::before {
    background-size: 75em 75em;
    background-position: -66.2em -52.7em;
}

nav#topMenu li.root .SubMenuWrapper .popBrands li.itemConverse a {
    background-position: -324px -177px;
}

nav#topMenu ul li.root.brandsMenu .SubMenuWrapper ul li.level2.b_converse a::before,
nav#topMenu ul li.root.promoMenu .SubMenuWrapper ul li.level1.b_converse a::before {
    background-position: -22.9em -12em;
}

nav#topMenu li.root .SubMenuWrapper .popBrands li.itemSkechers a {
    background-position: -428px -177px;
}

nav#topMenu ul li.root.brandsMenu .SubMenuWrapper ul li.level2.b_sketchers a::before,
nav#topMenu ul li.root.promoMenu .SubMenuWrapper ul li.level1.b_sketchers a::before {
    background-size: 48em 48em;
    background-position: -25.5em -10.3em;
}

nav#topMenu ul li.root.brandsMenu .SubMenuWrapper ul li.level2.b_calvinklein a::before,
nav#topMenu ul li.root.promoMenu .SubMenuWrapper ul li.level1.b_calvinklein a::before {
    background-size: 40em 40em;
    background-position: -5.8em -25em;
}

nav#topMenu ul li.root.brandsMenu .SubMenuWrapper ul li.level2.b_crocs a::before,
nav#topMenu ul li.root.promoMenu .SubMenuWrapper ul li.level1.b_crocs a::before {
    background-position: -36.7em -34.7em;
}

nav#topMenu ul li.root.brandsMenu .SubMenuWrapper ul li.level2.b_kickers a::before,
nav#topMenu ul li.root.promoMenu .SubMenuWrapper ul li.level1.b_kickers a::before {
    background-position: -38.9em -27.9em;
    background-size: 45em 45em;
}

nav#topMenu li.root .SubMenuWrapper .popBrands li.itemNewBalance a {
    background-position: -529px -176px;
}

nav#topMenu ul li.root.brandsMenu .SubMenuWrapper ul li.level2.b_newbalance a::before,
nav#topMenu ul li.root.promoMenu .SubMenuWrapper ul li.level1.b_newbalance a::before {
    background-position: -36.6em -11.9em;
}

nav#topMenu ul li.root.brandsMenu .SubMenuWrapper ul li.level2.b_speedo a::before,
nav#topMenu ul li.root.promoMenu .SubMenuWrapper ul li.level1.b_speedo a::before {
    background-size: 47em 47em;
    background-position: -40.1em -25.9em;
}

nav#topMenu li.root .SubMenuWrapper .popBrands li.itemASICS a {
    background-position: -3px -514px;
}

nav#topMenu li.root .SubMenuWrapper .popBrands li.itemSalomon a {
    background-position: -363px -511px;
}

nav#topMenu li.root .SubMenuWrapper .popBrands li.itemGarmin a {
    background-position: -254px -511px;
}

nav#topMenu li.root .SubMenuWrapper .popBrands li.itemReebok a {
    background-position: -619px -176px;
}

nav#topMenu ul li.root.brandsMenu .SubMenuWrapper ul li.level2.b_reebok a::before,
nav#topMenu ul li.root.promoMenu .SubMenuWrapper ul li.level1.b_reebok a::before {
    background-position: -42.6em -11.9em;
}

nav#topMenu li.root .SubMenuWrapper .popBrands li.itemLeeCooper a {
    background-position: -705px -179px;
}

nav#topMenu ul li.root.brandsMenu .SubMenuWrapper ul li.level2.b_leeCooper a::before,
nav#topMenu ul li.root.promoMenu .SubMenuWrapper ul li.level1.b_leeCooper a::before {
    background-position: -48.9em -12.1em;
}

nav#topMenu li.root .SubMenuWrapper .popBrands li.itemFiretrap a {
    background-position: -231px -453px;
}

nav#topMenu ul li.root.brandsMenu .SubMenuWrapper ul li.level2.b_firetrap a::before,
nav#topMenu ul li.root.promoMenu .SubMenuWrapper ul li.level1.b_firetrap a::before {
    background-size: 45em 45em;
    background-position: -12.8em -25.2em;
}

nav#topMenu li.root .SubMenuWrapper .popBrands li.itemMizuno a {
    background-position: -3px -563px;
}

nav#topMenu ul li.root.brandsMenu .SubMenuWrapper ul li.level2.b_slazenger a::before,
nav#topMenu ul li.root.promoMenu .SubMenuWrapper ul li.level1.b_slazenger a::before {
    background-position: -0.6em -17.1em;
}

nav#topMenu ul li.root.brandsMenu .SubMenuWrapper ul li.level2.b_gelert a::before,
nav#topMenu ul li.root.promoMenu .SubMenuWrapper ul li.level1.b_gelert a::before {
    background-position: -21.9em -13.8em;
    background-size: 47em 47em;
}

nav#topMenu ul li.root.brandsMenu .SubMenuWrapper ul li.level2.b_asics a::before,
nav#topMenu ul li.root.promoMenu .SubMenuWrapper ul li.level1.b_asics a::before {
    background-position: -1em -35.3em;
}

nav#topMenu ul li.root.brandsMenu .SubMenuWrapper ul li.level2.b_campri a::before,
nav#topMenu ul li.root.promoMenu .SubMenuWrapper ul li.level1.b_campri a::before {
    background-position: -10.4em -34.2em;
}

nav#topMenu ul li.root.brandsMenu .SubMenuWrapper ul li.level2.b_canterbury a::before,
nav#topMenu ul li.root.promoMenu .SubMenuWrapper ul li.level1.b_canterbury a::before {
    background-position: -33.8em -21em;
}

nav#topMenu ul li.root.brandsMenu .SubMenuWrapper ul li.level2.b_character a::before,
nav#topMenu ul li.root.promoMenu .SubMenuWrapper ul li.level1.b_character a::before {
    background-position: -6.7em -23.6em;
    background-size: 50em 50em;
}

nav#topMenu ul li.root.brandsMenu .SubMenuWrapper ul li.level2.b_donnay a::before,
nav#topMenu ul li.root.promoMenu .SubMenuWrapper ul li.level1.b_donnay a::before {
    background-position: -18.7em -23.5em;
    background-size: 50em 50em;
}

nav#topMenu ul li.root.brandsMenu .SubMenuWrapper ul li.level2.b_dunlop a::before,
nav#topMenu ul li.root.promoMenu .SubMenuWrapper ul li.level1.b_dunlop a::before {
    background-size: 40em 40em;
    background-position: -33.3em -18.3em;
}

nav#topMenu ul li.root.brandsMenu .SubMenuWrapper ul li.level2.b_everlast a::before,
nav#topMenu ul li.root.promoMenu .SubMenuWrapper ul li.level1.b_everlast a::before {
    background-position: -0.9em -21.8em;
}

nav#topMenu ul li.root.brandsMenu .SubMenuWrapper ul li.level2.b_five a::before,
nav#topMenu ul li.root.promoMenu .SubMenuWrapper ul li.level1.b_five a::before {
    background-position: -39.2em -21.2em;
}

nav#topMenu ul li.root.brandsMenu .SubMenuWrapper ul li.level2.b_golddigga a::before,
nav#topMenu ul li.root.promoMenu .SubMenuWrapper ul li.level1.b_golddigga a::before {
    background-position: -27.3em -26.1em;
}

nav#topMenu ul li.root.brandsMenu .SubMenuWrapper ul li.level2.b_kangol a::before,
nav#topMenu ul li.root.promoMenu .SubMenuWrapper ul li.level1.b_kangol a::before {
    background-position: -0.8em -30.8em;
}

nav#topMenu ul li.root.brandsMenu .SubMenuWrapper ul li.level2.b_jackJones a::before,
nav#topMenu ul li.root.promoMenu .SubMenuWrapper ul li.level1.b_jackJones a::before {
    background-position: -10.2em -16em;
    background-size: 42em 42em;
}

nav#topMenu ul li.root.brandsMenu .SubMenuWrapper ul li.level2.b_lonsdale a::before,
nav#topMenu ul li.root.promoMenu .SubMenuWrapper ul li.level1.b_lonsdale a::before {
    background-position: -14em -25.9em;
}

nav#topMenu ul li.root.brandsMenu .SubMenuWrapper ul li.level2.b_nevica a::before,
nav#topMenu ul li.root.promoMenu .SubMenuWrapper ul li.level1.b_nevica a::before {
    background-position: -33.1em -25.8em;
}

nav#topMenu ul li.root.brandsMenu .SubMenuWrapper ul li.level2.b_muddyfox a::before,
nav#topMenu ul li.root.promoMenu .SubMenuWrapper ul li.level1.b_muddyfox a::before {
    background-position: -6.2em -26.2em;
    background-size: 47em 47em;
}

nav#topMenu ul li.root.brandsMenu .SubMenuWrapper ul li.level2.b_noFear a::before,
nav#topMenu ul li.root.promoMenu .SubMenuWrapper ul li.level1.b_noFear a::before {
    background-position: -21.8em -21.4em;
}

nav#topMenu ul li.root.brandsMenu .SubMenuWrapper ul li.level2.b_oneills a::before,
nav#topMenu ul li.root.promoMenu .SubMenuWrapper ul li.level1.b_oneills a::before {
    background-position: -10.3em -26.8em;
}

nav#topMenu ul li.root.brandsMenu .SubMenuWrapper ul li.level2.b_pierreCardin a::before,
nav#topMenu ul li.root.promoMenu .SubMenuWrapper ul li.level1.b_pierreCardin a::before {
    background-position: -52.9em -25.5em;
    background-size: 65em 65em;
}

nav#topMenu ul li.root.brandsMenu .SubMenuWrapper ul li.level2.b_sondico a::before,
nav#topMenu ul li.root.promoMenu .SubMenuWrapper ul li.level1.b_sondico a::before {
    background-position: -28.3em -21.2em;
}

nav#topMenu ul li.root.brandsMenu .SubMenuWrapper ul li.level2.b_soulCal a::before,
nav#topMenu ul li.root.promoMenu .SubMenuWrapper ul li.level1.b_soulCal a::before {
    background-position: -1em -26.2em;
}

nav#topMenu li.root .SubMenuWrapper .popBrands li.itemUnderArmour a {
    background-position: -711px -237px;
}

nav#topMenu ul li.root.brandsMenu .SubMenuWrapper ul li.level2.b_underArmour a::before,
nav#topMenu ul li.root.promoMenu .SubMenuWrapper ul li.level1.b_underArmour a::before {
    background-position: -49.3em -16.2em;
}

nav#topMenu ul li.root.brandsMenu .SubMenuWrapper ul li.level2.b_USAPro a::before,
nav#topMenu ul li.root.promoMenu .SubMenuWrapper ul li.level1.b_USAPro a::before {
    background-position: -39.2em -25.7em;
}

nav#topMenu ul li.root.brandsMenu .SubMenuWrapper ul li.level2.b_arena a::before,
nav#topMenu ul li.root.promoMenu .SubMenuWrapper ul li.level1.b_arena a::before {
    background-position: -34.9em -48.8em !important;
}

nav#topMenu ul li.root.brandsMenu .SubMenuWrapper ul li.level2.b_carlton a::before,
nav#topMenu ul li.root.promoMenu .SubMenuWrapper ul li.level1.b_carlton a::before {
    background-size: 70em 70em;
    background-position: -37.1em -39.5em !important;
}

nav#topMenu ul li.root.brandsMenu .SubMenuWrapper ul li.level2.b_yonex a::before,
nav#topMenu ul li.root.promoMenu .SubMenuWrapper ul li.level1.b_yonex a::before {
    background-size: 100em 100em;
    background-position: -67.9em -57.2em !important;
}

.s-breadcrumbs-container .s-breadcrumbs-bar {
    font-size: 1.2em;
}

li#liBack {
    font-weight: 800;
    color: #000;
    cursor: pointer;
    padding-right: 5px;
}
li#liBack:hover {
    text-decoration: underline;
}
.breadcrumb-seperator.back {
    font-weight: 800;
    color: #000;
}

li#liBack .global-icon-chevron {
    display: inline-block;
}

li#liBack .global-icon-chevron svg {
    width: 10px;
    height: 10px;
}

@media (max-width: 1021px) {
    nav#topMenu ul li.root.newDrop .popularBot {
        display: none;
    }
    li#liBack {
        padding-right: 2px;
    }
}

nav#topMenu li.root .SubMenuWrapper .popularBot li.itemPopular a::after,
nav#topMenu li.root .SubMenuWrapper .popularBot li:first-of-type a::after {
    content: " > View All";
    font-weight: var(--font-weight-regular);
}

nav#topMenu ul li.root .SubMenuWrapper > ul {
    width: 100%;
    text-align: left;
}

nav#topMenu ul li.root .SubMenuWrapper > ul::before,
nav#topMenu ul li.root .SubMenuWrapper > ul::after {
    content: " ";
    display: table;
}

nav#topMenu ul li.root .SubMenuWrapper > ul::after {
    clear: both;
}

nav#topMenu ul li.root .SubMenuWrapper ul li.Center {
    width: 100%;
    float: left;
    padding: 20px;
}

nav#topMenu ul li.root .SubMenuWrapper ul li.Right {
    width: 100%;
    float: right;
    padding: 10px;
}

nav#topMenu ul li.root .SubMenuWrapper ul li.Right .bottomLinkStrip {
    padding: 10px;
}

nav#topMenu ul li.root .SubMenuWrapper ul li.Right .bottomLinkStrip .itemText a {
    display: inline-block;
    position: relative;
    width: auto;
    padding: 5px;
    font-size: 1.2em;
    text-transform: uppercase;
    font-weight: var(--font-weight-bold);
}

nav#topMenu ul li.root.newDrop .SubMenuWrapper ul li.Center {
    padding: 0;
    width: 100%;
}

nav#topMenu ul li.root.brandsMenu .SubMenuWrapper ul li.Center,
nav#topMenu ul li.root.sportsMenu .SubMenuWrapper ul li.Center {
    padding: 15px 0 30px;
    width: 100%;
    float: none;
}

nav#topMenu ul li.root.newDrop .SubMenuWrapper ul li.Left {
    width: 28%;
    float: right !important;
    padding: 20px 5px 10px;
    display: none !important;
}

@media (min-width: 1150px) and (max-width: 1269px) {
    nav#topMenu ul li.root.newDrop .SubMenuWrapper ul li.Left {
        width: 26%;
    }
}

@media (min-width: 1270px) and (max-width: 1369px) {
    nav#topMenu ul li.root.newDrop .SubMenuWrapper ul li.Left {
        width: 24%;
    }
}

@media (min-width: 1370px) and (max-width: 1499px) {
    nav#topMenu ul li.root.newDrop .SubMenuWrapper ul li.Left {
        width: 22%;
    }
}

@media (min-width: 1500px) {
    nav#topMenu ul li.root.newDrop .SubMenuWrapper ul li.Left {
        width: 21%;
    }
}

nav#topMenu ul li.root.newDrop .SubMenuWrapper ul li.Right {
    width: 100%;
}

nav#topMenu ul li.root.newDrop.activePromos .SubMenuWrapper ul li.Right {
    width: 20%;
}

nav#topMenu ul li.root.newDrop.activePromos .SubMenuWrapper ul li.Right .rightAdsMenu {
    position: relative;
    overflow: hidden;
}

nav#topMenu ul li.root.newDrop.activePromos .SubMenuWrapper ul li.Right .rightAdsMenu h3 {
    padding: 0;
}

nav#topMenu ul li.root.newDrop.activePromos .SubMenuWrapper ul li.Right .rightAdsMenu a + a {
    padding-top: 15px;
}

nav#topMenu ul li.root.newDrop.activePromos .SubMenuWrapper ul li.Right .rightAdsMenu a div {
    display: inline-block;
    position: relative;
    text-align: left;
    font-weight: var(--font-weight-bold);
}

nav#topMenu ul li.root.newDrop.activePromos .SubMenuWrapper ul li.Right .rightAdsMenu a div::after,
nav#topMenu ul li.root.promoMenu .SubMenuWrapper ul li .singlecolumnadvert .view-button::after,
nav#topMenu li.root.USCFashion .SubMenuWrapper ul li.Center a.postWrap h3 span::after {
    content: "";
    display: block;
    position: absolute;
    width: 100%;
    height: 1px;
    background-color: #000;
    bottom: 3px;
    left: 0;
}

nav#topMenu ul li.root .SubMenuWrapper ul li.level1 {
    width: 20%;
    float: left;
}

nav#topMenu ul li.root.newDrop .SubMenuWrapper ul li.level1 {
    width: 20%;
    padding: 20px 0;
    text-align: center;
    position: relative;
}

nav#topMenu ul li.root.newDrop.MenuGroupB .SubMenuWrapper ul li.level1,
nav#topMenu ul li.root.newDrop.MenuGroupG .SubMenuWrapper ul li.level1 {
    width: 25%;
}

nav#topMenu ul li.root.newDrop .SubMenuWrapper ul li.sdmViewAll,
nav#topMenu ul li.root.newDrop .SubMenuWrapper ul li.menuAccHeader {
    width: 50%;
    text-align: center;
    padding: 20px 0 10px;
}

nav#topMenu ul li.root.MenuGroupE .SubMenuWrapper ul li.level1.largeTitle {
    width: 40%;
}

nav#topMenu ul li.root.newDrop.threeCols .SubMenuWrapper ul li.level1 {
    width: 33.333333%;
}

nav#topMenu ul li.root.newDrop .SubMenuWrapper ul li.ClearIt {
    clear: left;
}

nav#topMenu ul li.root.newDrop .SubMenuWrapper ul li.level1 > ul {
    text-align: left;
    display: inline-block;
    max-width: 95%;
}

nav#topMenu ul li.root.newDrop .SubMenuWrapper ul li.largeLi {
    width: 50%;
    text-align: left;
    padding-left: 20px;
}

nav#topMenu ul li.root.newDrop .SubMenuWrapper ul li.splitFifty {
    width: 25%;
}

nav#topMenu ul li.root.newDrop .SubMenuWrapper ul li.splitThree {
    width: 33.333333%;
}

nav#topMenu ul li.root.MenuGroupSAO .SubMenuWrapper ul li.splitFifty,
nav#topMenu ul li.root.MenuGroupH .SubMenuWrapper ul li.splitFifty {
    width: 20%;
}

nav#topMenu li.root .SubMenuWrapper > ul .rightAdsMenu > h3 {
    font-size: 1em;
    text-align: center;
    letter-spacing: 0.1em;
    padding: 0 10px 10px;
    line-height: 1.4em;
}

nav#topMenu li.root .SubMenuWrapper > ul .rightAdsMenu > a {
    padding: 5px 10px;
    color: #000;
}

nav#topMenu li.root .SubMenuWrapper > ul .rightAdsMenu > a div {
    text-align: center;
    padding: 5px 0;
    font-size: 1em;
    line-height: 1.4em;
    font-weight: var(--font-weight-bold);
    letter-spacing: 0.1em;
}

nav#topMenu li.root .SubMenuWrapper > ul .rightAdsMenu > a img {
    width: 100%;
}

nav#topMenu ul li.root .SubMenuWrapper ul li.level1.CatFinish {
    padding-right: 3%;
}

nav#topMenu ul li.root .SubMenuWrapper ul li.level1.CatStart {
    padding-left: 3%;
}

nav#topMenu ul li.root.sportsMenu .SubMenuWrapper,
nav#topMenu ul li.root.brandsMenu .SubMenuWrapper {
    padding: 0;
}

nav#topMenu ul li.root.sportsMenu .SubMenuWrapper ul li.Left,
nav#topMenu ul li.root.brandsMenu .SubMenuWrapper ul li.Left {
    display: none !important;
}

.rightAdsMenu h3,
.rightAdsMenu a div {
    text-transform: uppercase;
}

nav#topMenu ul li.root.promoMenu .SubMenuWrapper {
    padding: 8px 3%;
}

nav#topMenu ul li.root.promoMenu .SubMenuWrapper ul li.PromoCenter {
    width: 20%;
}

nav#topMenu ul li.root.promoMenu .SubMenuWrapper ul li.PromoCenter ul li.level1 {
    width: 100%;
}

nav#topMenu ul li.root.promoMenu .SubMenuWrapper ul li.PromoCenter ul li.level1 ul li.level2 {
    border-top: 1px solid #eee;
}

nav#topMenu ul li.root.promoMenu .SubMenuWrapper ul li.PromoCenter ul li.level1 ul li.level2:first-child {
    border: none;
}

nav#topMenu ul li.root.promoMenu .SubMenuWrapper ul li .singlecolumnadvert .view-button {
    display: inline-block;
    position: relative;
    background-color: transparent;
    text-align: left;
    font-size: 1em;
    font-weight: var(--font-weight-bold);
    letter-spacing: 0.05em;
    color: #000;
    padding: 15px 0;
}

nav#topMenu ul li.root.promoMenu .SubMenuWrapper ul li .singlecolumnadvert .view-button::after {
    bottom: 12px;
}

nav#topMenu ul li.root.promoMenu .SubMenuWrapper ul li .singlecolumnadvert a:hover .view-button {
    color: #0255a3;
}

nav#topMenu ul li.root.promoMenu .SubMenuWrapper ul li.PromoRight {
    width: 80%;
}

nav#topMenu ul li.root.newDrop.activePromos .SubMenuWrapper ul li.Center {
    width: 80%;
}

nav#topMenu ul li.root.newDrop .SubMenuWrapper > ul li.level1.sdmColHeader > a {
    color: #005a54;
    text-align: left;
    padding: 5px calc(1% + 10px);
    display: inline-block;
    width: auto;
    float: left;
    position: relative;
    min-height: 30px;
}

nav#topMenu ul li.root.newDrop .SubMenuWrapper > ul li.level1.sdmColHeader::before,
.uscSite nav#topMenu ul li.root.newDrop.activePromos .SubMenuWrapper ul li.Right .rightAdsMenu h3::before {
    content: "";
    display: block;
    position: absolute;
    height: 2px;
    left: 25px;
    right: 25px;
    bottom: 0;
}

nav#topMenu ul li.root.newDrop .SubMenuWrapper > ul li.level1.sdmColHeader > a:hover::after {
    content: "";
    display: block;
    height: 2px;
    background-color: #005a54;
}

nav#topMenu ul li.root.newDrop .SubMenuWrapper > ul li.level1.sdmColHeader::before {
    background: transparent;
}

.overlay.productlist-overlay {
    display: none;
}

nav#topMenu ul li.root.newDrop .SubMenuWrapper .MensCenter > ul li.level1,
nav#topMenu ul li.root.newDrop .SubMenuWrapper .LadiesCenter > ul li.level1,
nav#topMenu ul li.root.newDrop .SubMenuWrapper .KidsCenter > ul li.level1,
nav#topMenu ul li.root.newDrop .SubMenuWrapper .FootballCenter > ul li.level1 {
    width: 16.6666%;
}

nav#topMenu ul li.root.newDrop .SubMenuWrapper .MensCenter > ul li.level1.sdmColHeader,
nav#topMenu ul li.root.newDrop .SubMenuWrapper .LadiesCenter > ul li.level1.sdmColHeader,
nav#topMenu ul li.root.newDrop .SubMenuWrapper .KidsCenter > ul li.level1.sdmColHeader,
nav#topMenu ul li.root.newDrop .SubMenuWrapper .FootballCenter > ul li.level1.sdmColHeader {
    width: 50%;
    padding: 10px 0 5px;
}

nav#topMenu ul li.root.newDrop .SubMenuWrapper .MensCenter > ul li.level1.sdmColHeader a,
nav#topMenu ul li.root.newDrop .SubMenuWrapper .LadiesCenter > ul li.level1.sdmColHeader a,
nav#topMenu ul li.root.newDrop .SubMenuWrapper .KidsCenter > ul li.level1.sdmColHeader a,
nav#topMenu ul li.root.newDrop .SubMenuWrapper .FootballCenter > ul li.level1.sdmColHeader a {
    padding: 5px;
}

nav#topMenu ul li.root.newDrop .SubMenuWrapper .MensCenter > ul li.level2.sdmColHeader,
nav#topMenu ul li.root.newDrop .SubMenuWrapper .LadiesCenter > ul li.level2.sdmColHeader,
nav#topMenu ul li.root.newDrop .SubMenuWrapper .KidsCenter > ul li.level2.sdmColHeader {
    text-decoration: underline;
}

nav#topMenu ul li.root.newDrop .SubMenuWrapper .RunningCenter > ul li.level1.sdmColHeader {
    width: 33.3333%;
}

nav#topMenu ul li.root.newDrop .SubMenuWrapper .TrainingCenter > ul li.level1.sdmColHeader {
    width: 33.3333%;
}

nav#topMenu ul li.root.newDrop .SubMenuWrapper .TrainingCenter > ul li.level1.sdmColHeader ~ .sdmColHeader {
    width: 50%;
}

nav#topMenu
    ul
    li.root.newDrop
    .SubMenuWrapper
    .TrainingCenter
    > ul
    li.level1.sdmColHeader
    ~ .sdmColHeader.smallMenuCol {
    width: 16.6666%;
}

nav#topMenu ul li.root.newDrop .SubMenuWrapper .AccessoriesCenter > ul li.level1.sdmColHeader {
    width: 50%;
}

nav#topMenu ul li.root.newDrop .SubMenuWrapper .AccessoriesCenter > ul li.level1.sdmColHeader ~ .sdmColHeader {
    width: 33.3333%;
}

nav#topMenu
    ul
    li.root.newDrop
    .SubMenuWrapper
    .AccessoriesCenter
    > ul
    li.level1.sdmColHeader
    ~ .sdmColHeader.smallMenuCol {
    width: 16.6666%;
}

nav#topMenu ul li.root.newDrop .SubMenuWrapper .OutletCenter > ul li.level1 {
    width: 16.6666%;
}

nav#topMenu ul li.root.newDrop .SubMenuWrapper .OutletCenter > ul li.level1.largeMenuCol {
    width: 33.3333%;
}

@media (max-width: 1370px) {
    nav#topMenu ul li.root.promoMenu .SubMenuWrapper ul li.PromoCenter {
        width: 30%;
    }

    nav#topMenu ul li.root.promoMenu .SubMenuWrapper ul li.PromoRight {
        width: 70%;
    }
}

@media (max-width: 1021px) {
    nav#topMenu ul li.root.promoMenu .SubMenuWrapper ul li.PromoCenter {
        width: 40% !important;
    }

    nav#topMenu ul li.root.promoMenu .SubMenuWrapper ul li.PromoCenter li.sdmColHeaderHidden {
        padding-top: 0;
    }

    nav#topMenu ul li.root.promoMenu .SubMenuWrapper ul li.PromoRight {
        display: block !important;
        width: 60%;
    }
}

nav#topMenu ul li.root.USCFashion .SubMenuWrapper ul li.Center {
    width: 100%;
    padding-top: 0;
}

nav#topMenu ul li.root.USCFashion .SubMenuWrapper ul li.Center .Center::before,
nav#topMenu ul li.root.USCFashion .SubMenuWrapper ul li.Center .Center::after {
    content: "";
    display: table;
}

nav#topMenu ul li.root.USCFashion .SubMenuWrapper ul li.Center .Center::after {
    clear: both;
}

nav#topMenu ul li.root.USCFashion .SubMenuWrapper ul li.Center a.postWrap .postTitle,
nav#topMenu li.root.USCFashion .SubMenuWrapper ul li.Center a.postWrap .postImage {
    padding: 10px;
}

nav#topMenu ul li.root.USCFashion .SubMenuWrapper ul li.Center a.postWrap .postTitle {
    text-align: center;
    line-height: 1.4em;
    font-weight: var(--font-weight-bold);
    letter-spacing: 0.1em;
    text-transform: uppercase;
    color: #000;
}

nav#topMenu ul li.root.USCFashion .SubMenuWrapper ul li.Center a.postWrap .postTitle span {
    position: relative;
    padding: 5px 0;
}

nav#topMenu ul li.root.USCFashion .SubMenuWrapper ul li.Center a.postWrap:hover .postTitle {
    color: #0255a3;
}

nav#topMenu li.root.newDrop .SubMenuWrapper > ul .indentSub {
    padding-left: 20px;
}

nav#topMenu li.root .SubMenuWrapper > ul .sdmColHeader > a,
nav#topMenu li.root .SubMenuWrapper > ul .sdmColOther > a {
    position: relative;
    color: #005a54;
    letter-spacing: 0.1em;
    font-size: 1.1em !important;
    padding: 10px 5px;
    text-transform: uppercase;
    font-weight: var(--font-weight-bold);
    border-top: 0;
    margin-top: 0;
}

nav#topMenu li.root .SubMenuWrapper > ul li.level2 > a {
    position: relative;
    display: inline-block;
    width: auto;
    font-size: 1.25em;
}

nav#topMenu li.root .SubMenuWrapper > ul li.level2 > a::after {
    content: "";
    display: block;
    position: absolute;
    left: 5px;
    right: 5px;
    bottom: 2px;
    height: 2px;
    background-color: transparent;
}

nav#topMenu li.root .SubMenuWrapper > ul li.level2 > a:hover::after {
    background-color: #000;
}

nav#topMenu li.root .SubMenuWrapper > ul li.level2.sdmColHeader > a {
    margin-bottom: 10px;
}

nav#topMenu li.root .SubMenuWrapper > ul li.level2.sdmColHeader > a:hover::after {
    background-color: #005a54;
}

nav#topMenu ul li.root .SubMenuWrapper ul li.Right .bottomLinkStrip .itemText a::before {
    content: "";
    display: block;
    position: absolute;
    bottom: 2px;
    left: 5px;
    right: 5px;
    height: 1px;
    background-color: #000;
}

nav#topMenu li.root.newDrop .SubMenuWrapper > ul .sdmColHeader > a,
nav#topMenu li.root.newDrop .SubMenuWrapper > ul .sdmColOther > a {
    min-height: 0;
    padding: 5px;
    min-width: 0;
    max-width: 100%;
}

nav#topMenu li.root.newDrop .SubMenuWrapper > ul .disablelink a,
.am-menu .newDrop ul li.level2.disablelink a {
    pointer-events: none;
}

nav#topMenu li.root.newDrop .SubMenuWrapper > ul .disablelink a:hover {
    text-decoration: none;
    color: #000;
}

nav#topMenu li.root.newDrop .SubMenuWrapper > ul .adiBoots > a,
nav#topMenu li.root.newDrop .SubMenuWrapper > ul .botList > a {
    text-transform: none;
}

nav#topMenu li.root.newDrop .SubMenuWrapper > ul .adiBoots > a::after {
    content: " BOOTS";
}

nav#topMenu ul li.root.newDrop .SubMenuWrapper ul li.sdmViewAll a {
    padding: 0 5px;
}

nav#topMenu li.root .SubMenuWrapper > ul .level2.sdmColGap > a {
    margin-top: 20px;
}

nav#topMenu .newDrop li.sdmColHeaderHidden {
    padding-top: 10px;
}

nav#topMenu li.root .SubMenuWrapper > ul li a {
    line-height: 1.4em;
    padding: 5px;
    color: #000;
    letter-spacing: 0.05em;
    font-size: 1em;
}

nav#topMenu li.root .SubMenuWrapper > ul li a:hover {
    text-shadow: -1px 0 0 #000;
}

nav#topMenu li.root .SubMenuWrapper > ul li.sdmColHeader a:hover {
    text-shadow: none;
}

nav#topMenu > ul li.newTag a::after,
.am-menu .newDrop ul li.newTag .menuitemtext::after {
    content: "New";
    background-color: #0255a3;
    padding: 4px;
    font-size: 0.7em;
    margin-left: 3px;
    color: #fff;
    vertical-align: middle;
}

nav#topMenu > ul li.saleTag a::after,
.am-menu .newDrop ul li.saleTag .menuitemtext::after {
    content: "Sale";
    background-color: #ee3425;
    padding: 1px 4px;
    font-size: 0.7em;
    margin-left: 3px;
    color: #fff;
    vertical-align: middle;
    display: inline-block;
}

nav#topMenu > ul li.myidTag a::after,
.am-menu .newDrop ul li.myidTag .menuitemtext::after {
    content: "";
    background-size: 500px 500px;
    background-position: -149px -131px;
    padding: 0;
    font-size: 8px;
    margin-left: 3px;
    background-color: #000;
    color: #fff;
    vertical-align: top;
    width: 32px;
    display: inline-block;
    height: 18px;
}

nav#topMenu > ul li.level1 a,
nav#topMenu > ul li.level2 a {
    max-width: none;
    min-width: 0;
}

nav#topMenu li.root .SubMenuWrapper .level2.sdmColFeature a {
    color: #333;
}

nav#topMenu ul li.root.sportsMenu .SubMenuWrapper ul li.Center ul li > ul {
    border: none;
    padding: 0;
}

nav#topMenu ul li.root.sportsMenu .SubMenuWrapper ul li.level1 {
    width: 16.6666%;
    border-left: 1px solid #ddd;
}

nav#topMenu ul li.root.sportsMenu .SubMenuWrapper ul li.level1:first-child {
    border: none;
}

nav#topMenu ul li.root.sportsMenu .SubMenuWrapper ul li.level1 ul li.level2 a,
nav#topMenu ul li.root.brandsMenu .SubMenuWrapper ul li.level1 ul li.level2 a {
    position: relative;
    padding: 5px 20px;
    max-width: 100%;
    min-width: 0;
}

nav#topMenu ul li.root.sportsMenu .SubMenuWrapper ul li.level1 ul li.level2.sdmColHeader a::before {
    display: none;
}

nav#topMenu ul li.root.sportsMenu .SubMenuWrapper ul li.level1 ul li.level2.sportsViewAll a::before {
    background-image: url("/images/core/desktop_menu_brands_sprite_version_7-01.svg");
    background-size: 1540px 1540px;
    background-position: -39.4em -125.5em;
}

nav#topMenu ul li.root.brandsMenu .SubMenuWrapper ul li.level1 ul li.level2.brandsViewAll a {
    font-weight: var(--font-weight-bold);
}

nav#topMenu .sportsMenu li.sdmColHeaderHidden {
    padding-top: 0;
}

@media (min-width: 768px) and (max-width: 889px) {
    nav#topMenu ul li.root.sportsMenu .SubMenuWrapper ul li.level1 ul li.level2.s_MMA.longDesc a {
        padding: 10px 0 9px 40px;
    }

    nav#topMenu ul li.root.sportsMenu .SubMenuWrapper ul li.level1 ul li.level2.s_fitnessexercise.longDesc a {
        padding: 10px 0 9px 40px;
    }
}

nav#topMenu ul li.root.brandsMenu .SubMenuWrapper ul li.level1 {
    border-left: 1px solid #eee;
    position: relative;
    width: 16.6666%;
    float: left;
}

nav#topMenu ul li.root.brandsMenu .SubMenuWrapper ul li.level1:first-child {
    border: none;
}

nav#topMenu ul li.root.brandsMenu .SubMenuWrapper ul li.level1.sdmColHeaderHidden {
    padding: 0;
}

nav#topMenu ul li.root.brandsMenu .SubMenuWrapper ul li.level1 a {
    text-align: left;
    position: relative;
    padding: 10px 5px;
}

#divsiblinglistwrapper ul li {
    height: auto !important;
}

.MenuGroupH.saleRed li.columnGroup.clearfix {
    width: 25%;
}

nav#topMenu ul li.root.MenuGroupH.saleRed.newDrop .SubMenuWrapper ul li.level1 {
    width: 100%;
    padding: 20px 2px 0;
}

nav#topMenu li.level1.sdmColHeader.colGroupStart.singleItem,
nav#topMenu ul li.root.MenuGroupJ.clearance.newDrop .SubMenuWrapper ul li.level1.singleItem {
    padding: 20px 10% 0;
    max-width: 90%;
    display: flex;
    justify-content: flex-start;

    a {
        padding: 5px 0 0 calc(8.5% - 6px);
    }
}

nav#topMenu li.columnGroup li.level1 {
    border-right: none;
}

nav#topMenu ul li.root .SubMenuWrapper > ul {
    max-height: calc(100vh - 194px);
    overflow-y: auto;
}

nav#topMenu ul li.root.newDrop .SubMenuWrapper > ul li.level1.sdmColHeader > a {
    min-height: 31px;
}

nav#topMenu ul li.root.newDrop .SubMenuWrapper ul {
    padding-left: 10px;
}

.MenuGroupH.clearance li.columnGroup.clearfix,
nav#topMenu ul li.root.MenuGroupJ.newDrop .SubMenuWrapper > ul > li > ul > li {
    width: 25%;
}

nav#topMenu ul li.root.MenuGroupH.clearance.newDrop .SubMenuWrapper ul li.level1,
nav#topMenu ul li.root.MenuGroupJ.clearance.newDrop .SubMenuWrapper ul li.level1 {
    width: 100%;
    padding: 20px 2px 0;
}

nav#topMenu ul li.root.MenuGroupJ.newDrop .SubMenuWrapper ul li.level1 > ul {
    min-width: 225px;
}

nav#topMenu ul li.root.MenuGroupD .SubMenuWrapper ul li.level1,
nav#topMenu ul li.root.MenuGroupE .SubMenuWrapper ul li.level1,
nav#topMenu ul li.root.MenuGroupJ.newDrop .SubMenuWrapper ul li ul li ul > li.level1,
nav#topMenu ul li.root.newDrop .SubMenuWrapper .AccessoriesCenter > ul li.level1.sdmColHeader,
nav#topMenu ul li.root.newDrop .SubMenuWrapper .ComponentsCenter > ul li.level1.sdmColHeader {
    width: 100%;
}

nav#topMenu ul li.root.MenuGroupD .SubMenuWrapper ul li.Center > ul > li {
    width: 20%;
}

nav#topMenu ul li.root.MenuGroupE .SubMenuWrapper ul li.Center > ul > li {
    width: 25%;
}

nav#topMenu ul li.root.MenuGroupE .SubMenuWrapper ul li.Center > ul > li > ul {
    padding: 0;
}

nav#topMenu ul li.root.MenuGroupE.newDrop .SubMenuWrapper .greySection ul {
    padding-left: 30px;
}

nav#topMenu .MenuGroupD li.columnGroup li.level1,
nav#topMenu .MenuGroupE li.columnGroup li.level1 {
    border-right: 0;
}

nav#topMenu li.root.MenuGroupD ul li ul li ul li ul,
nav#topMenu li.root.MenuGroupE ul li ul li ul li ul {
    border-top: 0;
}

nav#topMenu ul li.root.MenuGroupD.newDrop .SubMenuWrapper > ul li.level1.sdmColHeader > a,
nav#topMenu ul li.root.MenuGroupE.newDrop .SubMenuWrapper > ul li.level1.sdmColHeader > a {
    text-align: left;
    display: inline-block;
    max-width: 95%;
}

nav#topMenu ul li.root.MenuGroupD.newDrop .SubMenuWrapper > ul li.level1.sdmColHeader::before,
nav#topMenu ul li.root.MenuGroupE.newDrop .SubMenuWrapper > ul li.level1.sdmColHeader::before {
    display: none;
}

nav#topMenu ul li.root.newDrop .SubMenuWrapper .AccessoriesCenter > ul li.level1.sdmColHeader ~ .sdmColHeader,
nav#topMenu ul li.root.newDrop .SubMenuWrapper .AccessoriesCenter > ul li.level1.sdmColHeader ~ .sdmColHeader > ul,
nav#topMenu ul li.root.newDrop .SubMenuWrapper .AccessoriesCenter > ul li.level1.sdmColHeader > ul,
nav#topMenu ul li.root.newDrop .SubMenuWrapper .ComponentsCenter > ul li.level1.sdmColHeader ~ .sdmColHeader,
nav#topMenu ul li.root.newDrop .SubMenuWrapper .ComponentsCenter > ul li.level1.sdmColHeader ~ .sdmColHeader > ul,
nav#topMenu ul li.root.newDrop .SubMenuWrapper .ComponentsCenter > ul li.level1.sdmColHeader > ul {
    width: 100%;
}

nav#topMenu ul li.root.MenuGroupD.newDrop .SubMenuWrapper ul li.level1 > ul,
nav#topMenu ul li.root.MenuGroupE.newDrop .SubMenuWrapper ul li.level1 > ul {
    display: block;
}

nav#topMenu .MenuGroupE li.columnGroup.clearfix > ul > li > ul > li:last-child,
nav#topMenu .MenuGroupD li.columnGroup.clearfix > ul > li > ul > li:last-child {
    display: none !important;
}

nav#topMenu ul li.root.MenuGroupD.newDrop .SubMenuWrapper ul,
nav#topMenu ul li.root.MenuGroupE.newDrop .SubMenuWrapper ul {
    padding-left: 10px;
}

nav#topMenu ul li.root.MenuGroupE.newDrop .SubMenuWrapper ul li.level1 {
    text-align: left;
}

nav#topMenu ul li.root.MenuGroupE.newDrop .SubMenuWrapper > ul li.level1.sdmColHeader a {
    color: #005a54;
    margin-left: 10px;
    display: inline;
}

nav#topMenu ul li.root.MenuGroupE.newDrop .SubMenuWrapper > ul li.level1.sdmColHeader a::after {
    content: "";
    display: block;
    position: absolute;
    left: 5px;
    right: 5px;
    bottom: 2px;
    height: 2px;
    background-color: transparent;
}

nav#topMenu ul li.root.MenuGroupE.newDrop .SubMenuWrapper > ul li.level1.sdmColHeader a:hover::after {
    background-color: #005a54;
}

nav#topMenu ul li.root.newDrop.activePromos .SubMenuWrapper .Bikes li.BikesCenter.Center > ul li:nth-of-type(5) > ul,
nav#topMenu ul li.root.newDrop.activePromos .SubMenuWrapper .Bikes li.BikesCenter.Center > ul li:nth-of-type(6) > ul {
    padding: 0;
}

nav#topMenu ul li.root.newDrop .SubMenuWrapper ul.Sale li.level1 > ul {
    width: 100%;
    padding: 0 10%;
}

nav#topMenu li.root ul li ul li ul li ul {
    border-top: none;
}

@media (max-width: 1021px) {
    nav#topMenu ul li.root.brandsMenu .SubMenuWrapper ul li.level1 a {
        padding-left: 20px;
    }

    nav#topMenu ul li.root.brandsMenu .SubMenuWrapper ul li.level1 a::before {
        display: none;
    }
}

.FooterGroupLink {
    width: 100%;
    float: none;
    padding: 0;
}

.FontBold {
    font-weight: var(--font-weight-bold);
}

.emailSignupPane {
    padding: 0;
}

.footerContainer,
.footerFooter {
    background-color: #fff;
}

h2.FooterHeader {
    font-size: 1.4em;
}

.FooterMenuWrap {
    float: none;
}

.FooterGroup {
    padding: 0;
    max-width: 100%;
}

.FooterWrap {
    border-top: none;
    z-index: 1 !important;
    overflow: hidden;
    background-color: #f6f6f6;
    padding: 0;
    margin-top: 15px;
}

.FooterWrap::before {
    content: "";
    display: block;
    height: 3px;
    background-color: #ffd51e;
}

.FooterWrap .FooterTop {
    background-color: #f6f6f6;
    max-width: 1440px;
    margin: 0 auto;
}

.FooterWrap .FooterTop .footerStoreFinder,
.FooterWrap .FooterTop .footerCustServ {
    text-align: center;
    padding-top: 10px;
}

.FooterWrap .FooterTop .footerStoreFinder a::before,
.FooterWrap .FooterTop .footerCustServ a::before {
    content: "";
    display: inline-block;
    width: 40px;
    height: 40px;
}

.FooterWrap .FooterTop .footerStoreFinder a::before {
    background-size: 1600px 1600px;
    background-position: -155px -78px;
}

.FooterWrap .FooterTop .footerCustServ a::before {
    background-size: 1350px 1350px;
    background-position: -988px -56px;
}

.FooterWrap .FooterTop .footerCustServ::after {
    content: "";
    display: block;
    position: absolute;
    width: 2px;
    background-color: #ccc;
    top: 15px;
    bottom: 0;
    left: 0;
}

.FooterWrap .FooterTop .join-us-text p {
    font-size: 1em;
    text-transform: uppercase;
    color: #005a54;
}

.FooterWrap .FooterTop .SubTitle {
    display: block;
    color: #000;
    font-weight: var(--font-weight-bold);
    font-size: 16px;
}

.FooterWrap .FooterTop span.hidden-xs {
    display: block;
    color: #000;
    margin-top: 5px;
    font-size: 1.2em;
}

.FooterWrap .FooterTop span.hidden-xs span {
    text-decoration: underline;
}

.FooterWrap .footerContainer {
    border-bottom: 2px solid #ffd51e;
}

.socialMediaContainer {
    border-top: 1px solid #ddd;
    margin-top: 20px;
}

.socialSitesWrapper {
    margin: 40px auto;
    width: 75%;
    max-width: 500px;
    border-bottom: 0;
}

.socialSitesWrapper ul {
    overflow: hidden;
    margin: 0;
}

.socialSitesWrapper li {
    display: block;
    float: left;
    height: 50px;
    width: 20%;
    text-align: center;
    position: relative;
}

.socialSitesWrapper li a:hover {
    opacity: 1;
}

.socialSitesWrapper li.socInstagram a {
    background-position: -50px -330px;
}

.socialSitesWrapper li.socFacebook a {
    background-position: -83px -330px;
}

.socialSitesWrapper li.socPinterest a {
    background-position: -116px -330px;
}

.socialSitesWrapper li.socTwitter a {
    background-position: -13px -329px;
}

.socialSitesWrapper li.socYouTube a {
    background-position: -151px -330px;
    max-width: 48px;
}

.txtLink {
    height: 100%;
    vertical-align: middle;
    display: table-cell;
    padding-left: 5px;
}

.txtLink:hover {
    text-decoration: underline;
}

#footApp,
#footAppGooglePlay {
    width: 50%;
    float: left;
    text-align: center;
}

#footApp a:hover > .ico {
    background-position: -662px -275px;
    width: 120px;
}

#footApp .ico {
    background-position: -662px -237px;
    width: 120px;
}

#footAppGooglePlay a:hover > .ico {
    background-position: -605px -1214px;
    width: 120px;
}

#footAppGooglePlay .ico {
    background-position: -605px -1174px;
    width: 120px;
}

.footerMenu .FooterSubGroup {
    background-color: transparent;
    min-height: 0;
    margin: 0;
    height: auto;
}

.max-width,
.FooterPane2 {
    margin: 0 auto;
    max-width: 1400px;
}

.footerPane .FooterHeader {
    padding: 10px 0;
    display: block;
    margin-bottom: 6px;
    width: 100%;
}

.signup-wrapper .signupInner {
    border: none;
    margin: 0 auto;
    padding: 0;
}

.signup-wrapper .signupInner .signupInputBox {
    border-radius: 5px;
    margin: 2px 0 0;
    overflow: hidden;
    box-shadow: 0 2px 4px 2px #bbb;
}

.signup-wrapper .signupInner .signupEmailSuccess,
.signup-wrapper .signupInner .signupEmailProcessing {
    padding-top: 10px;
    font-size: 1.2em;
}

.signup-wrapper input#signupEmail {
    padding: 5px 10px;
    font-size: 1.2em;
    height: 42px;
    border: 1px solid #fff;
    border-right: none;
    border-radius: 5px 0 0 5px;
    transition: border-color 0.3s linear;
}

.signup-wrapper input#signupEmail:focus {
    border-color: #005a54;
}

.signup-wrapper input.signbut {
    font-size: 1.2em;
    height: 42px;
    letter-spacing: 0.1em;
    padding: 5px;
    border-radius: 0 5px 5px 0;
}

.signup-wrapper .signupInner .join-us-text {
    text-align: left;
}

.signup-wrapper .signupInner .join-us-text p {
    font-size: 1.3em;
    letter-spacing: 0.05em;
    margin-bottom: 0;
    text-transform: uppercase;
}

.signup-wrapper .signupInner .join-us-text p.SubTitle {
    font-size: 18px;
}

.signup-wrapper .signupInner .NewsletterPadding,
.signup-wrapper .signupInner .sub-buttons {
    padding: 0;
}

.signup-wrapper .signupInner .disclaimerText p {
    margin-top: 10px;
    font-size: 1.25em;
}

@media (max-width: 400px) {
    .signup-wrapper .signupInner .join-us-text p.SubTitle {
        font-size: 1.5em;
    }
}

.CountrySelection > div,
.CountryNoSelection > div,
.CountryNoEnglish > div {
    width: 100%;
}

#SearchStores a,
#SearchStores li {
    width: 100%;
    height: 198px;
    display: block;
    margin: 0 auto;
    max-width: 165px;
}

#SearchStores li {
    height: 180px;
    background: url("/images/core/footer-stores-background-v2.png") no-repeat;
}

.currencyLanguageSelector .spanCurrencyLanguageSelector .languageRoot {
    display: none;
}

#divCurrencyLanguageSelector .spanCurrencyLanguageSelector .flag {
    background-size: 720px 720px;
    background-position: -318px -28px;
    flex: auto;
}

.currency-eur #divCurrencyLanguageSelector .spanCurrencyLanguageSelector .flag {
    background-position: -500px -111px;
}

.currency-usd #divCurrencyLanguageSelector .spanCurrencyLanguageSelector .flag {
    background-position: -462px -112px;
}

#divCurrencyLanguageSelector .flag {
    background-image: url("/images/flagssprite_small_final_v6.png");
}

#CountryRedirectOtherSitesList .flag {
    background-image: url("/images/flags/flags-v6.jpg");
}

.language-cs .CountrySelection .flag.global,
.language-cz .CountrySelection .flag.global {
    background-position: -53px 1px;
}

.language-de .CountrySelection .flag.global {
    background-position: -82px 1px;
}

.language-en .CountrySelection .flag.global {
    background-position: 0 1px;
}

.language-es .CountrySelection .flag.global {
    background-position: 1px -18px;
}

.language-fr .CountrySelection .flag.global {
    background-position: -26px -18px;
}

.language-hu .CountrySelection .flag.global {
    background-position: -27px -37px;
}

.language-is .CountrySelection .flag.global {
    background-position: 0 -37px;
}

.language-nl .CountrySelection .flag.global {
    background-position: 1px -75px;
}

.language-pl .CountrySelection .flag.global {
    background-position: -27px -56px;
}

.language-pt .CountrySelection .flag.global {
    background-position: 1px -56px;
}

.language-sl .CountrySelection .flag.global {
    background-position: -27px -75px;
}

.language-sk .CountrySelection .flag.global {
    background-position: -53px -56px;
}

.language-my .CountrySelection .flag.global {
    background-position: -137px -18px;
}

.hotspotbuy.hotspotwishlist,
.hotspotbuy.hotspotquickbuy {
    width: 50%;
    height: 35px;
    background-color: rgba(220, 220, 220, 0.9);
    overflow: hidden;
    font-size: 1.1em;
}

.language-en .CountryNoSelection,
.language-de .CountryNoSelection,
.language-nl .CountryNoSelection,
.language-cs .CountryNoSelection,
.language-fr .CountryNoSelection,
.language-hu .CountryNoSelection,
.language-is .CountryNoSelection,
.language-pl .CountryNoSelection,
.language-pt .CountryNoSelection,
.language-sk .CountryNoSelection,
.language-sl .CountryNoSelection,
.language-es .CountryNoSelection,
.language-it .CountrySelection,
.language-sv .CountrySelection {
    display: none;
}

.CountrySelection p,
.CountryNoSelection p,
.CountryNoEnglish p {
    color: #686868;
    font-size: 1.4em;
    border-bottom: 1px solid #ddd;
    font-weight: var(--font-weight-bold);
    margin-bottom: 6px;
    line-height: 1.2em;
    padding-bottom: 6px;
}

.language-en ul#SearchStores li.CountryGraphic {
    background-position: 2% 1px;
}

.language-en ul#SearchStores li.CountryGraphic:hover {
    background-position: 2% -189px;
}

.language-cs ul#SearchStores li.CountryGraphic {
    background-position: 19.1% 0;
}

.language-cs ul#SearchStores li.CountryGraphic:hover {
    background-position: 19.1% -199px;
}

.language-nl ul#SearchStores li.CountryGraphic {
    background-position: 21.7% -833px;
}

.language-nl ul#SearchStores li.CountryGraphic:hover {
    background-position: 21.7% -994px;
}

.language-fr ul#SearchStores li.CountryGraphic {
    background-position: 40% -14px;
}

.language-fr ul#SearchStores li.CountryGraphic:hover {
    background-position: 40% -210px;
}

.language-hu ul#SearchStores li.CountryGraphic {
    background-position: 58% -9px;
}

.language-hu ul#SearchStores li.CountryGraphic:hover {
    background-position: 58% -208px;
}

.language-is ul#SearchStores li.CountryGraphic {
    background-position: 79% -13px;
}

.language-is ul#SearchStores li.CountryGraphic:hover {
    background-position: 79% -197px;
}

.language-pl ul#SearchStores li.CountryGraphic {
    background-position: 3% -435px;
}

.language-pl ul#SearchStores li.CountryGraphic:hover {
    background-position: 3% -609px;
}

.language-pt ul#SearchStores li.CountryGraphic {
    background-position: 21% -434px;
}

.language-pt ul#SearchStores li.CountryGraphic:hover {
    background-position: 21% -614px;
}

.language-sk ul#SearchStores li.CountryGraphic {
    background-position: 40% -436px;
}

.language-sk ul#SearchStores li.CountryGraphic:hover {
    background-position: 40% -626px;
}

.language-sl ul#SearchStores li.CountryGraphic {
    background-position: 60% -433px;
}

.language-sl ul#SearchStores li.CountryGraphic:hover {
    background-position: 60% -621px;
}

.language-es ul#SearchStores li.CountryGraphic {
    background-position: 80% -432px;
}

.language-es ul#SearchStores li.CountryGraphic:hover {
    background-position: 80% -613px;
}

.language-de ul#SearchStores li.CountryGraphic {
    background-position: 60.25% -836px;
}

.language-de ul#SearchStores li.CountryGraphic:hover {
    background-position: 60.25% -1006px;
}

.CountryNoEnglish ul#SearchStores li.DeliveryGraphic,
.language-it ul#SearchStores li.DeliveryGraphic,
.language-sv ul#SearchStores li.DeliveryGraphic {
    background-position: 42% -843px;
}

.CountryNoEnglish ul#SearchStores li.DeliveryGraphic:hover,
.language-it ul#SearchStores li.DeliveryGraphic:hover,
.language-sv ul#SearchStores li.DeliveryGraphic:hover {
    background-position: 42% -999px;
}

.footerLogo {
    text-align: center;
    padding-top: 28px;
}

#SearchStores li .FooterHeader,
.FooterSearchCountry .FooterHeader h2 {
    color: #686868;
    border-bottom: 1px solid #ddd;
    font-weight: var(--font-weight-bold);
}

#SearchStores li .FooterHeader {
    font-size: 1.3em;
    width: 45%;
}

.FooterSearchCountry .FooterHeader h2 {
    font-size: 1.4em;
}

.footerLogo .flag.global {
    background-position: -25px -137px;
}

.footerLogo .flag.ie {
    background-position: -51px -156px;
}

.footerLogo .flag.at {
    background-position: -77px -176px;
}

.footerLogo .flag.pl {
    background-position: -23px -197px;
}

.footerLogo .flag.fr {
    background-position: -25px -157px;
}

.footerLogo .flag.se {
    background-position: -76px -197px;
}

.lillBasket a.removeClass,
.s-basket-remove-button a .deleteItemIcon {
    width: 18px;
    display: inline-block;
    vertical-align: middle;
    height: 18px;
    font-size: 11px;
    background-position: -344px -307px;
    background-size: 770px 770px;
    text-indent: -9999px;
}

.s-basket-remove-button a:hover .deleteItemIcon,
.lillBasket a.removeClass:hover {
    background-position: -394px -307px;
}

.updateIcon,
.BasketNew .BasketQuantBut::before,
.BasketNew .BasketQuantBut.s-basket-plus-button::before,
.Responsive .BasketNew #BasketDiv .NewUpdateQuant:hover .updateIcon {
    background-size: 770px 770px;
    background-position: -204px -318px;
}

.BasketNew .BasketQuantBut::before {
    background-position: -155px -351px;
}

.BasketNew .BasketQuantBut.s-basket-plus-button::before {
    background-position: -199px -351px;
}

nav#topMenu li.columnGroup > ul {
    padding: 0 6px;
}

nav#topMenu .NewMenu > ul {
    min-height: 477px;
}

nav#topMenu ul li.root > a {
    color: #005a54;
    font-size: 1.1em;
    text-transform: uppercase;
    line-height: 40px;
    letter-spacing: 0.5px;
    padding: 0 5px;
    position: relative;
    overflow: hidden;
}

nav#topMenu ul li.root.MenuGroupSAO > a {
    color: #af0128;
}

nav#topMenu ul.RootGroup li.root > a {
    display: inline-block;
    position: relative;
    overflow: visible;
    font-weight: var(--font-weight-bold);
}

nav#topMenu ul.RootGroup li.root > a::after {
    content: "";
    position: absolute;
    display: block;
    height: 2px;
    bottom: 9px;
    left: 8px;
    right: 8px;
    background-color: transparent;
}

nav#topMenu ul.RootGroup li.root > a:hover::after,
nav#topMenu ul.RootGroup li.root.sdHover > a::after {
    background-color: #005a54;
}

nav#topMenu ul.RootGroup li.root.MenuGroupSAO > a:hover::after,
nav#topMenu ul.RootGroup li.root.MenuGroupSAO.sdHover > a::after {
    background-color: #af0128;
}

nav#topMenu > ul .NewMenuWrap li.level1 > a {
    color: #636363;
    float: left;
    width: 17%;
    padding: 5px 15px;
    font-weight: var(--font-weight-bold);
    margin: 0;
    max-width: 17%;
    border-right: 1px solid #ddd;
    text-transform: uppercase;
}

nav#topMenu ul li.root.MenuGroupUSC,
nav#topMenu ul li.root.brandsMenu {
    background-color: #d6e0ec;
}

nav#topMenu ul li.root.promoMenu {
    background-color: #e0e9f0;
}

nav#topMenu ul li.root.MenuGroupDeal > a:hover,
nav#topMenu ul li.root.MenuGroupDeal.sdHover > a {
    height: 38px;
}

nav#topMenu > ul .NewMenuWrap li.level2 > a:hover,
nav#topMenu > ul .NewMenuWrap li.level1.sdHover > a {
    color: #fff;
}

nav#topMenu ul li.root.ViewAllDeals .SubMenuWrapper ul li.Center {
    width: 100%;
}

nav#topMenu ul li.root.ViewAllDeals .SubMenuWrapper ul li.Right {
    display: none !important;
}

nav#topMenu ul li.root.ViewAllDeals .SubMenuWrapper li ul li.level1 {
    width: 25%;
    float: left;
    padding: 20px;
}

nav#topMenu ul li.root.ViewAllDeals .SubMenuWrapper li ul li.level1 a {
    text-indent: -9999px;
    display: block;
    width: 100%;
    padding: 0;
    background-size: 100% auto;
    height: 352px;
    max-width: 100%;
    background-color: transparent !important;
    background-repeat: no-repeat;
}

nav#topMenu ul li.root.ViewAllDeals .SubMenuWrapper li ul li.ClearanceImage a {
    background-image: url("/images/marketing/sd-square-clear.jpg");
}

nav#topMenu ul li.root.ViewAllDeals .SubMenuWrapper li ul li.FlashImage a {
    background-image: url("/images/marketing/sd-square-flash.jpg");
}

nav#topMenu ul li.root.ViewAllDeals .SubMenuWrapper li ul li.PreorderImage a {
    background-image: url("/images/marketing/sd-square-pre.jpg");
}

nav#topMenu ul li.root.ViewAllDeals .SubMenuWrapper li ul li.WeeklyImage a {
    background-image: url("/images/marketing/sd-menu-week-offer.jpg");
}

nav#topMenu ul li.root.MenuGroupT.newDrop .SubMenuWrapper ul li.Left {
    width: 50%;
    padding: 36px 5px 0;
}

nav#topMenu ul li.root.MenuGroupT.newDrop li.Center > ul {
    margin-top: 36px !important;
}

nav#topMenu ul li.root.MenuGroupT.newDrop .SubMenuWrapper ul li.level1 {
    width: 50%;
    text-align: center;
    position: relative;
}

nav#topMenu ul li.root.MenuGroupT.newDrop .rightAdsMenu {
    margin-bottom: 10px;
}

nav#topMenu ul li.root.MenuGroupT.newDrop .twoStackContent {
    display: flex;
    flex-wrap: wrap;
}

nav#topMenu ul li.root.MenuGroupT.newDrop .twoStackContent > div {
    padding: 10px 20px;
    text-transform: none !important;
    letter-spacing: 0.04em;
    float: none;
}

nav#topMenu ul li.root.MenuGroupT.newDrop .twoStackContent a img {
    margin-bottom: 5px;
}

nav#topMenu ul li.root.MenuGroupT.newDrop .twoStackHeader h3 {
    color: #000;
    letter-spacing: 0.15em;
    font-size: 0.85em !important;
    padding: 15px 5px 5px 20px;
    text-transform: uppercase;
}

nav#topMenu ul li.root.MenuGroupT.newDrop .twoStackContent div a {
    line-height: 1.2em;
    padding: 0 0 5px;
}

nav#topMenu ul li.root.MenuGroupT.newDrop .twoStackContent .twoStackCta a {
    text-transform: uppercase !important;
    color: #000;
    font-weight: var(--font-weight-bold);
}

nav#topMenu > ul .NewMenuWrap li.sdmColHeader a {
    text-transform: uppercase;
    font-size: 1em !important;
}

div.NewMenuWrap {
    top: 80px;
    border-bottom: 1px solid #ddd;
    height: auto;
}

nav#topMenu ul.RootGroup {
    text-align: center;
    display: flex;
}

nav#topMenu ul.RootGroup li.root {
    position: static;
    flex: 1 1 auto;
}

div.NewMenu {
    padding: 0;
    width: 100%;
    max-width: 1410px;
    height: 100%;
}

div.NewMenuWrap ul > li.level1:hover {
    background-color: transparent !important;
}

div.NewMenuWrap ul > li.sdHover > a,
.NewMenuWrap li.level2 a:hover {
    background-color: rgba(0, 84, 164, 0.9) !important;
}

div.NewMenu > ul > li > a {
    padding: 0 45px;
}

nav#topMenu {
    max-width: 1440px;
    padding: 0 15px;
    position: static;
}

nav#topMenu .NewMenu ul li.level1 {
    padding: 0;
    width: 100%;
    height: 100%;
    float: left;
    position: static;
}

div.NewMenu > ul > li.level1 > a {
    padding: 0 20px 0 40px;
}

nav#topMenu > ul .NewMenuWrap li.TopGap {
    padding: 9px 0 3px;
    border-top: 1px solid #bbb;
    margin-top: 31px;
}

li.menuImg {
    background: url("/images/marketing/menuimage.jpg") no-repeat;
    min-height: 340px;
    display: block;
    height: 100%;
    text-indent: -9999px;
}

.DropdownWrap {
    left: 17% !important;
    width: 83% !important;
    padding: 10px;
    top: 0;
    position: absolute;
    height: 100%;
    overflow: hidden !important;
}

.Dropdown {
    box-shadow: none;
    border: none;
    margin-bottom: 0;
    padding: 0;
    max-width: 100%;
    background: transparent;
}

.Dropdown .Center {
    width: 100%;
    background: transparent;
    padding: 0;
}

.Dropdown .Center .columnGroup {
    width: 20%;
}

.Dropdown .Center > ul > li:first-child + li + li + li {
    width: 20%;
    clear: none;
}

nav#topMenu > ul .NewMenuWrap li.level2 a {
    line-height: 1em;
    padding: 5px;
    max-width: 100%;
    font-size: 1.3em;
}

.popularMenu h3 {
    font-size: 0.8em;
    line-height: 1.1em;
    text-transform: uppercase;
    padding: 15px 0 15px 16px;
    margin: 0;
}

.popularMenu h3,
nav#topMenu li.root .SubMenuWrapper > ul li .popularMenu a,
nav#topMenu li.root .NewMenuWrap .popularMenu a {
    color: #fff;
}

nav#topMenu li.root .SubMenuWrapper > ul li .popularMenu a,
nav#topMenu li.root .NewMenuWrap .popularMenu a {
    text-transform: initial;
    line-height: 1em;
    font-weight: var(--font-weight-regular);
}

nav#topMenu .popularMenu ul li {
    margin-bottom: 10px;
}

nav#topMenu .popularMenu.popular_brands ul li.itemView.All.Brands a {
    font-weight: var(--font-weight-bold);
}

@media (min-width: 1022px) and (max-width: 1199px) {
    nav#topMenu li.root .SubMenuWrapper > ul li .popularMenu a,
    nav#topMenu li.root .NewMenuWrap .popularMenu a {
        padding: 1px 8px;
        font-size: 0.9em;
    }

    .popularMenu h3 {
        padding: 13px 8px;
    }
}

@media (min-width: 1200px) {
    nav#topMenu li.root .SubMenuWrapper > ul li .popularMenu a,
    nav#topMenu li.root .NewMenuWrap .popularMenu a {
        padding: 4px 12px;
    }

    .popularMenu h3 {
        padding: 13px 12px;
    }
}

.ui-autocomplete {
    z-index: 5925 !important;
    max-width: 660px;
    box-shadow: none;
    padding: 10px;
    position: fixed !important;
}

#divBagItemsChild .bagHeader p {
    text-transform: uppercase;
    font-size: 1.3em;
    font-weight: var(--font-weight-bold);
    border-bottom: 1px solid #ddd;
}

#divBagItemsChild .bagHeader #clsBasketMob {
    font-size: 1em;
    width: 30px;
    cursor: pointer;
    position: absolute;
    right: 0;
    top: 0;
    height: 30px;
    line-height: 30px;
}

#divBagItemsChild .bagHeader #clsBasketMob::before {
    width: 25px;
    height: 25px;
    font-size: 11px;
    background-position: -399px -402px;
    display: inline-block;
    background-size: 900px 900px;
    vertical-align: top;
    content: " ";
}

.sdlProdList #lblCategoryCopy {
    font-size: 1.1em;
}

.sdlProdList .QuickLookIcon,
.sdlProdList .WishIcon {
    width: 35px;
    height: 35px;
    background-size: 800px 800px;
}

.sdlProdList .hotspotbuy.hotspotquickbuy,
.sdlProdList .hotspotbuy.hotspotwishlist {
    bottom: 0;
    right: 10px;
    width: 35px;
    background-color: transparent;
}

.sdlProdList #spnSearchIcon .glyphicon {
    font-size: 11px;
    background-size: 600px 600px;
    background-position: -12px -401px;
    height: 24px;
    width: 30px;
}

.sdlProdList #spnSearchIcon .glyphicon::before {
    content: none;
}

.sdlProdList .layout li a,
.sdlProdList .productFilterList .SelectableFilter::before,
.sdlProdList .productFilterList .SelectedFilter::before {
    background-image: url("/images/core/sd-prod-list-prod-detail-sprite-updated-v6.svg");
    background-size: 300px 300px;
}

.sdlProdList .productFilterList .SelectableFilter,
.sdlProdList .productFilterList .SelectedFilter {
    background: none;
    padding: 0;
    line-height: 1.1em;
}

.sdlProdList .productFilterList .SelectableFilter::before,
.sdlProdList .productFilterList .SelectedFilter::before {
    background-position: -137px -137px;
    width: 18px;
    display: inline-block;
    height: 18px;
    vertical-align: top;
    font-size: 11px;
}

.sdlProdList .productFilterList a:hover .SelectableFilter::before,
.sdlProdList .productFilterList .SelectedFilter::before {
    background-position: -154px -121px;
}

.sdlProdList .productFilterList .SelectedFilter:hover::before {
    background-position: -171px -102px;
}

.sdlProdList .productFilterList .FilterName {
    padding-left: 5px;
}

.sdlProdList .productFilterList .FilterValue,
.sdlProdList .productFilterList .FilterName {
    float: none;
    vertical-align: top;
    display: inline;
    line-height: 18px;
}

.sdlProdList .productFilterList .SelectableFilter::after,
.sdlProdList .productFilterList .SelectedFilter::after,
.sdlProdList .productFilterList .FilterAnchor .MobSortSelectionCheckbox::before {
    content: none;
}

.evanProdList .RefandPrice {
    line-height: 1.1em;
    vertical-align: baseline;
}

.evanProdList .RefandPrice .s-smalltext {
    font-size: 1.1em;
}

.evanProdList .layout li.colopt2 a {
    background-position: -465px -101px;
}

.evanProdList .layout li.colopt2 a:hover,
.evanProdList .layout li.colopt2.selected a {
    background-position: -508px -101px;
}

.evanProdList .layout li.colopt3 a {
    background-position: -606px -101px;
}

.evanProdList .layout li.colopt3 a:hover,
.evanProdList .layout li.colopt3.selected a {
    background-position: -557px -101px;
}

.evanProdList .layout li.colopt4 a {
    background-position: -699px -101px;
}

.evanProdList .layout li.colopt4 a:hover,
.evanProdList .layout li.colopt4.selected a {
    background-position: -663px -101px;
}

.PSPlacementWrapper .PSPlacementTitle,
#pnlRecentlyViewedProducts .AltStratTitle {
    background-color: #fff;
    text-align: center;
    font-weight: var(--font-weight-bold);
    line-height: 1.2em;
    border: 0;
    letter-spacing: 0.05em;
    color: #333;
}

.PSPlacementWrapper .borderWrap,
#pnlRecentlyViewedProducts .AltVertOne {
    border: 0;
    margin-top: 10px;
}

#pnlRecentlyViewedProducts .AltVertOne .AltStratProdImg {
    display: block;
    padding: 5px 10px;
}

#pnlRecentlyViewedProducts .AltVertOne .AltStratProdImg img {
    max-width: 180px;
    width: 100%;
}

.PSPlacementWrapper .ProductSuggestionsListing li a {
    display: block;
}

.PSPlacementWrapper .SuggestedProduct > h4,
#pnlRecentlyViewedProducts h4 {
    text-align: left;
    font-size: 1em;
    font-weight: var(--font-weight-regular);
    padding: 0;
}

.PSPlacementWrapper .SuggestedProduct > h4 a .PSProdBrand,
#pnlRecentlyViewedProducts .AltStratBrand {
    font-weight: var(--font-weight-bold);
    font-size: 1.2em;
    text-transform: uppercase;
    letter-spacing: 0.05em;
}

.PSPlacementWrapper .SuggestedProduct > h4 a .PSProdTitle,
.PSPlacementWrapper .SuggestedProduct > h4 a .PSProdName,
#pnlRecentlyViewedProducts .AltStratDescription {
    font-size: 1.2em;
}

.PSPlacementWrapper .SuggestedProduct .PSProdPrice,
#pnlRecentlyViewedProducts .AltStratRefSell {
    padding: 5px 5px 0;
    text-align: left;
    height: auto;
}

.PSPlacementWrapper .SuggestedProduct .productimage.s-productthumbimage {
    padding: 5px 10px;
}

.PSPlacementWrapper .productimage .PSImage .PSPimg-res {
    max-width: 180px;
    width: 100%;
}

.PSPlacementWrapper .SuggestedProduct .PSSellPrice,
#pnlRecentlyViewedProducts .AltStratSellPrice {
    letter-spacing: 0.05em;
    font-size: 1.2em;
    display: inline-block;
    line-height: 1.1em;
}

.PSPlacementWrapper .SuggestedProduct .PSFrom,
#pnlRecentlyViewedProducts .AltFrom {
    font-size: 1.1em;
    display: inline-block;
    line-height: 1.2em;
    padding-right: 2px;
}

.PSPlacementWrapper .SuggestedProduct .PSFromSell,
#pnlRecentlyViewedProducts .AltFromSell {
    display: inline-block;
    line-height: normal;
    padding-right: 5px;
}

#pnlRecentlyViewedProducts .AltStratProduct {
    margin-bottom: 0;
}

#pnlRecentlyViewedProducts .AltStratBrandDesc {
    display: block;
}

.PSPlacementWrapper .SuggestedProduct .PSTicketSell,
#pnlRecentlyViewedProducts .AltStratProduct .AltStratRefPriName {
    padding: 0;
    line-height: normal;
}

.PSPlacementWrapper .PSProdPrice .AdditionalPriceLabel,
#pnlRecentlyViewedProducts .AltStratRefSell .AdditionalPriceLabel {
    padding: 0 2px 0 0;
    line-height: 1.2em;
    font-size: 1.1em;
    color: #888;
    display: inline-block;
}

.PSPlacementWrapper .SuggestedProduct .ticketPricePrefix,
.PSPlacementWrapper .SuggestedProduct .PSRefPrice,
#pnlRecentlyViewedProducts .AltStratProduct .AltStratRefPrice {
    display: inline-block;
    font-size: 1.1em;
    line-height: 1.2em;
}

.SuggestedProduct .hotspotbuy.hotspotquickbuy,
.SuggestedProduct .hotspotbuy.hotspotwishlist {
    top: 0;
    bottom: auto;
}

.sdlProdList .hotspotbuy.hotspotquickbuy {
    right: 45px;
}

.sdlProdList .hotspotbuy .WishIcon {
    background-position: -19px -591px;
}

.sdlProdList .hotspotbuy .WishIcon:hover {
    background-position: -62px -591px;
}

.sdlProdList .QuickLookIcon {
    background-position: -20px -533px;
}

.sdlProdList .QuickLookIcon:hover {
    background-position: -64px -533px;
}

.sdlProdList .pagination .layout {
    text-align: left;
}

.pagination .sortbyfilter {
    text-align: right;
}

.sdlProdList .pagination .layout,
.pagination .sortbyfilter {
    border: none;
}

.sdlProdList .s-product-sache {
    position: absolute;
    top: 0;
    right: 5px;
}

.sdlProdList .s-productthumbbox .s-productthumbimage .ProductImageList {
    margin-bottom: 10px;
}

.sdlProdList .s-productthumbbox .s-productthumbimage .ProductImageList img {
    transition: transform 0.3s ease-out;
}

.sdlProdList .s-productthumbbox .s-productthumbimage:hover .ProductImageList img {
    transform: scale(1.05);
}

.sdlProdList .productdescriptionbrand {
    text-transform: uppercase;
    font-size: 1em;
    letter-spacing: 0.05em;
}

.sdlProdList .productdescriptionname {
    font-weight: var(--font-weight-regular);
}

.sdlProdList .s-producttext-price .s-largered {
    display: inline-block;
    padding: 0 3px 0 0;
    vertical-align: middle;
    line-height: 1em;
}

.pagination .PageNumber {
    line-height: normal;
}

.PageNumber a,
.PageNumber span.PageSelector {
    height: 25px;
    min-width: 0;
    line-height: 25px;
    margin: 0 5px;
    padding: 0 8px;
}

.PageNumber a.NextLink,
.PageNumber a.PrevLink {
    height: 25px;
    line-height: 25px;
}

@media (max-width: 374px) {
    .PageNumber a,
    .PageNumber span.PageSelector {
        padding: 0 4px;
    }
}

.sdlProdList .s-productthumb-extra {
    font-size: 1em;
}

.sdlProdList .s-productthumbbox .s-productthumb-extra .s-productsize {
    padding: 0;
}

.sdlProdList .s-productthumb-extra,
.sdlProdList .s-productthumbbox .s-producttext-price {
    padding: 0 5px 5px;
}

.sdlProdList .s-productthumb-extra .sizeText,
.layout li.colopt5 {
    display: none;
}

.sdlProdList .RefandPrice {
    line-height: 1em;
    vertical-align: middle;
}

.s-producttext-withticket .AdditonalPriceLabel,
.sdlProdList .Responsive .RefandPrice span {
    font-size: 1.1em;
    vertical-align: middle;
    line-height: 1.2em;
}

.sdlProdList .pagination-bottom .layout {
    margin-top: 30px;
}

.s-largered span,
.columns3 .s-largered span,
.columns4 .s-largered span {
    font-size: 1.2em;
    line-height: normal;
    display: inline-block;
}

.Responsive .s-largered span.curprice {
    letter-spacing: 0.05em;
}

.Responsive .s-largered span.fromProductPrice {
    font-size: 1em;
}

.s-producttext-top-wrapper,
.columns3 .s-producttext-top-wrapper,
.columns4 .s-producttext-top-wrapper {
    padding: 5px;
}

.flexFilters {
    display: flex;
}

.paginationWrapper .mobSortFilter {
    position: static;
}

.mobSortFilter .mobFilterContainer,
.mobSortFilter .mobTitlArro {
    display: flex;
    align-items: center;
    min-height: 31px;
}

.productFilterTitleBox .glyphicon,
.mobFilterAppIcon {
    height: 20px;
    top: 0;
    width: 20px;
}

.MobSortSelector .Responsive .productFilterTitleBox,
.MobSortSelector .productFilterTitleBoxCLOSED {
    padding: 5px;
}

#mobControlBar {
    padding: 5px 10px;
    margin: 0;
    cursor: pointer;
    border: 1px solid #005a54;
}

.productFilterTitleBox .glyphicon::before,
.productFilterTitleBox.FilterClosed .glyphicon::before,
.sdlProdList .glyphicon-ok-sign::before,
.mobFilterAppIcon::before {
    content: "";
    display: block;
    height: 20px;
    width: 20px;
    background-size: 750px 750px;
    background-position: -172px -391px;
}

.productFilterTitleBox.FilterClosed .glyphicon::before,
.sdlProdList .glyphicon-ok-sign::before {
    background-position: -243px -420px;
}

.mobFilterAppIcon::before {
    background-position: -206px -391px;
}

#filterByMob {
    position: relative;
    text-align: left;
    top: 0;
    transform: none;
}

#filterByMob > span,
.flexFilters .mobSortDesc .productFilterTitle {
    font-size: 1.3em;
}

#filterByMob .MobFiltersText {
    width: calc(100% - 20px);
    float: left;
}

.mobFilterAppIcon {
    display: inline-block !important;
    margin: 0;
    text-align: right;
}

.mobAppliedFilters {
    margin: 0;
    font-size: 1.2em;
}

.mobSortFilter .mobAppliedFilters {
    font-size: 1em;
    height: auto;
}

.mobSortDesc {
    width: calc(100% - 21px);
}

.mobSortDesc .productFilterTitle {
    text-transform: uppercase;
    letter-spacing: 0.05em;
    font-size: 1.5em;
    line-height: 1.4em;
}

.Responsive .mobDdClose .clsFilterIcon::before {
    content: "";
    display: block;
    width: 40px;
    height: 40px;
    background-size: 1100px 1100px;
    background-position: -483px -488px;
}

.sdlProdList .catTemplateNew .viewMore {
    padding-top: 15px;
    text-align: right;
}

.sdlProdList .catTemplateNew .viewMore a {
    cursor: pointer;
}

.sdlProdList .catTemplateNew .viewMore .collapsed span.ClosedView,
.sdlProdList .catTemplateNew .viewMore a,
.sdlProdList .catTemplateNew .viewMore span.OpenView {
    display: inline-block;
}

.sdlProdList .catTemplateNew .viewMore a span {
    font-size: 1.2em;
    display: inline-block;
    vertical-align: top;
}

.sdlProdList .catTemplateNew .viewMore .collapsed span.OpenView,
.sdlProdList .catTemplateNew .viewMore span.ClosedView {
    display: none;
}

.sdlProdList .catTemplateNew .viewMore span.OpenView::after,
.sdlProdList .catTemplateNew .viewMore span.ClosedView::after {
    content: "";
    width: 17px;
    height: 17px;
    margin-left: 5px;
    vertical-align: top;
    display: inline-block;
    background-size: 720px 720px;
    background-position: -165px -374px;
}

.sdlProdList .catTemplateNew .viewMore span.OpenView::after {
    background-position: -165px -402px;
}

.sdlProdList .catTemplateNew .textDesc {
    width: 100%;
    max-width: 800px;
    margin: 0 auto;
    padding: 10px;
    font-size: 1.1em;
}

.sdlProdList .catTemplateNew .siblingLinks span {
    display: inline-block;
}

.sdlProdList .catTemplateNew .siblingLinks span a {
    letter-spacing: 0.1em;
    font-size: 1em;
    padding: 10px 15px;
    display: inline-block;
    transition: all 0.3s ease-out;
}

.sdlProdList .catTemplateNew .siblingLinks span a:hover {
    text-decoration: underline;
}

.ChildCategoriesListWrapper #ChildCategoriesList li a::after {
    background-size: 620px 620px;
    background-position: -203px -346px;
}

.paginationWrapper {
    padding-top: 10px;
    padding-bottom: 10px;
}

.s-maincontent-container .pagination {
    padding: 0;
    margin: 0;
}

.s-maincontent-container .paginationWrapper .pag-items-left {
    float: left;
    margin-top: 3px;
}

.s-maincontent-container .paginationWrapper .pag-items-right {
    float: right;
}

.s-maincontent-container .paginationWrapper .pag-items-right .layout,
.s-maincontent-container .paginationWrapper .pag-items-right .sortbyfilter {
    float: left;
    width: auto;
    border: none;
    padding: 0;
}

.s-maincontent-container .paginationWrapper .columnselector li {
    border: none;
    margin-right: 0;
}

.s-maincontent-container .columnselector li a {
    background-size: 950px 950px;
    float: left;
    height: 30px;
    width: 30px;
}

.s-maincontent-container .paginationWrapper .ViewbyCols,
.s-maincontent-container .paginationWrapper .columnselector .colopt5 {
    display: none;
}

.s-maincontent-container .paginationWrapper .sortbyfilter .sortOptionsContainer {
    padding: 10px 5px;
}

.s-maincontent-container .paginationWrapper .sortbyfilter .sortOptionsHeader span {
    text-transform: uppercase;
    color: #000;
    font-size: 1em;
}

.s-maincontent-container .paginationWrapper .sortbyfilter .sortOptionsHeader span.selectedOption {
    font-weight: var(--font-weight-bold);
}

.s-maincontent-container .paginationWrapper .sortbyfilter .lblSortBy::after {
    content: " | ";
    margin: 0 5px;
    font-weight: var(--font-weight-bold);
}

#FiltersAndProductsWrapper #FilterContainer ul.productFilters > li {
    width: 100%;
}

ul.productFilters .productFilter .FilterName {
    float: left;
    width: auto;
    text-transform: none;
}

.productFilterList .FilterValue {
    float: left;
    display: inline-block;
    width: auto;
    margin-left: 5px;
    padding-top: 0;
}

.globalSelectedFiltersWrapper {
    background-color: #f5f5f5;
    min-height: 0;
}

.globalSelectedFilters .SelectedFiltersLabel {
    width: 18%;
    float: left;
    padding: 4px 10px;
    font-size: 1.2em;
    font-weight: var(--font-weight-bold);
    text-transform: uppercase;
}

.selectedFilters li.selectedFilter > a {
    display: block;
    width: 100%;
    position: relative;
}

.selectedFilters li.selectedFilter > a::before {
    content: "";
    background-position: -235px -517px !important;
    background-size: 900px 900px !important;
    height: 15px;
    width: 15px;
    display: inline-block;
    position: absolute;
    left: 0;
    top: 0;
}

.evanProdList .SelectedFiltersWrapper.globalSelectedFilters {
    padding: 6px 0 0;
    overflow: hidden;
}

.evanProdList .SelectedFiltersWrapper.globalSelectedFilters ul {
    position: relative;
    float: left;
}

.evanProdList .SelectedFiltersWrapper.globalSelectedFilters ul::before {
    content: "";
    position: absolute;
    display: block;
    width: 1px;
    top: 0;
    left: 0;
    bottom: 6px;
    background-color: #000;
}

.evanProdList .SelectedFiltersWrapper.globalSelectedFilters ul li {
    display: inline-block;
    padding-bottom: 6px;
}

.evanProdList .SelectedFiltersWrapper.globalSelectedFilters ul li a {
    padding: 5px 15px;
    border-right: 1px solid #000;
    cursor: pointer;
}

.evanProdList .SelectedFiltersWrapper.globalSelectedFilters ul li a::before {
    top: 5px;
    left: 10px;
    background-size: 850px 850px !important;
    background-position: -637px -141px !important;
}

.evanProdList .SelectedFiltersWrapper.globalSelectedFilters ul li a .selectedFilterLabel {
    text-transform: none;
    padding-left: 13px;
    letter-spacing: 1px;
    font-weight: var(--font-weight-bold);
}

.evanProdList .SelectedFiltersWrapper.globalSelectedFilters ul li a .selectedFilterLabel:hover {
    text-decoration: none;
}

.evanProdList .SelectedFiltersWrapper.globalSelectedFilters ul li a .selectedFilterLabel::before {
    display: none;
}

.evanProdList .SelectedFiltersWrapper.globalSelectedFilters ul li.inlineClearAllFilters {
    display: none;
}

.evanProdList .SliderContain .ui-slider {
    border: none;
    background-color: #ccc;
    height: 4px;
}

.evanProdList .SliderContain .ui-slider-horizontal .ui-slider-handle {
    height: 10px;
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;
}

.evanProdList .SliderContain .ui-slider-handle {
    border: none;
}

.evanProdList .SliderContain .ui-slider-handle::after {
    content: "";
    display: block;
    height: 10px;
    width: 10px;
    border-radius: 100%;
}

.evanProdList #spnSearchIcon .glyphicon {
    height: 20px;
    width: 20px;
    background-size: 750px 750px;
    background-position: -194px -35px;
}

.evanProdList #spnSearchIcon .glyphicon::before {
    display: none;
}

.search-dropdown__wrapper .overlayHome {
    position: absolute;
}

.evanProdList .QuickLookIcon,
.evanProdList .WishIcon {
    width: 35px;
    height: 35px;
    background-size: 1000px 1000px;
}

.evanProdList .product-sash.product-sash--top-left {
    left: 6px;
}

.evanProdList .hotspotbuy.hotspotquickbuy,
.evanProdList .hotspotbuy.hotspotwishlist {
    bottom: 0;
    right: 10px;
    width: 35px;
    background-color: transparent;
}

.evanProdList .hotspotbuy.hotspotquickbuy {
    right: 45px;
}

.evanProdList .hotspotbuy .WishIcon {
    background-position: -395px -160px;
}

.evanProdList .hotspotbuy .WishIcon:hover {
    background-position: -439px -160px;
}

.evanProdList .QuickLookIcon {
    background-position: -300px -161px;
}

.evanProdList .QuickLookIcon:hover {
    background-position: -346px -161px;
}

.evanProdList .paginationWrapper .PrevNextButtons.PageNumber .PrevLink,
.evanProdList .paginationWrapper .PrevNextButtons.PageNumber .NextLink {
    height: 30px;
    width: 30px;
    display: inline-block;
    background-size: 1000px 1000px;
    background-color: #005a54;
    border: none;
    text-indent: 100%;
    white-space: nowrap;
    overflow: hidden;
    padding: 0;
}

.evanProdList .paginationWrapper .PrevNextButtons.PageNumber .PrevLink.DisabledLink,
.evanProdList .paginationWrapper .PrevNextButtons.PageNumber .NextLink.DisabledLink {
    background-color: #f5f5f5;
}

.evanProdList .paginationWrapper .PrevNextButtons.PageNumber .PrevLink {
    background-position: -160px -306px;
}

.evanProdList .paginationWrapper .PrevNextButtons.PageNumber .PrevLink.DisabledLink {
    background-position: -161px -164px;
}

.evanProdList .paginationWrapper .PrevNextButtons.PageNumber .NextLink {
    background-position: -119px -306px;
}

.evanProdList .paginationWrapper .PrevNextButtons.PageNumber .NextLink.DisabledLink {
    background-position: -119px -164px;
}

@media (min-width: 1022px) {
    .globalSelectedFilters .SelectedFiltersLabel {
        display: block;
    }

    .globalSelectedFilters .SelectedFiltersContainer {
        width: 82%;
        float: left;
    }

    .evanProdList .SelectedFiltersContainer li.selectedFilter .selectedFilterLabel {
        text-transform: uppercase;
        font-size: 1.1em;
        line-height: 1.3em;
        display: inline-block;
    }

    .Browse .FilterProductsCount .currentProducts,
    .Browse .FilterProductsCount .countDelimeter {
        display: none;
    }

    .evanProdList .productFilter #PriceFilter {
        height: 65px;
        padding: 0 10px;
    }
}

.catTemplate-FWC .textLeft {
    padding-bottom: 10px;
}

.catTemplate-FWC .textLeft p {
    line-height: 1.4em;
    margin: 0;
    display: block;
    color: #000;
    font-size: 1.25em;
}

.catTemplate-FWC .textLeft p,
.Browse h1,
#lblCategoryCopy {
    text-align: left;
}

.catTemplate-FWC .siblingLinks {
    margin-top: 20px;
    font-size: 1em;
}

.catTemplate-FWC .siblingLinks a {
    background-color: #e1e1e1;
    letter-spacing: 0.1em;
    line-height: 1.5em;
    padding: 10px;
    display: inline-block;
    margin: 0 5px 10px;
    min-width: 120px;
}

.catTemplate-FWC .siblingLinks a:hover {
    background-color: #eaeaea;
}

.catTemplate-FWC.inverted {
    background-color: #e1e1e1;
}

.catTemplate-FWC.inverted .siblingLinks a {
    background-color: #fff;
}

.catTemplate-FWC.inverted .siblingLinks a:hover {
    background-color: #f1f1f1;
}

.catTemplate-FWC .imgRight a {
    display: block;
}

.catTemplate-FWC .imgRight img {
    margin: 0;
}

@media (min-width: 768px) and (max-width: 950px) {
    .ControlWrap .storeGroup {
        display: none;
    }
}

@media (min-width: 768px) {
    .PageNumber a,
    .PageNumber span.PageSelector {
        padding: 0 12px;
    }

    .dvSearch .TextBoxClear {
        right: 52px;
        left: auto;
    }

    .FooterWrap .FooterTop > div {
        padding: 0 3%;
    }

    .FooterWrap .FooterTop > div.signup-wrapper {
        padding: 0 3% 0 2%;
    }

    .footerContainer .footerMenu {
        padding: 40px 15px;
        overflow: hidden;
    }

    .footerContainer .footerMenu::before {
        display: none;
    }

    .socialSitesWrapper {
        width: 60%;
    }

    .socialSitesWrapper li a {
        max-width: 45px;
        background-size: 1400px 1400px;
    }

    .socialSitesWrapper li.socInstagram a {
        background-position: -63px -428px;
    }

    .socialSitesWrapper li.socFacebook a {
        background-position: -104px -428px;
    }

    .socialSitesWrapper li.socPinterest a {
        background-position: -147px -428px;
    }

    .socialSitesWrapper li.socTwitter a {
        background-position: -16px -425px;
    }

    .socialSitesWrapper li.socYouTube a {
        background-position: -195px -428px;
        max-width: 55px;
    }

    .sdlProdList .TextSizeWrap,
    .AltProdList .s-productthumbbox h4 {
        text-align: left;
    }

    .sdlProdList .catTemplateNew .collapse {
        display: block !important;
        height: auto !important;
    }

    .catTemplate .textLeft {
        margin: 35px 0 15px;
        color: #999;
        line-height: 1.8em;
        padding: 0 5%;
        letter-spacing: 0.1em;
    }

    .catTemplate-FWC .siblingLinks a {
        padding: 10px 20px;
    }
}

@media (min-width: 768px) and (max-width: 1050px) {
    .FooterWrap .FooterTop > div {
        padding: 0 10px;
    }
}

@media (max-width: 767px) {
    .catTemplate.catTemplateNew .siblingLinks {
        margin-top: 15px;
    }

    .catTemplate-FWC .siblingLinks a {
        margin: 0 10px 10px 0;
    }

    .catTemplate-FWC .textLeft {
        padding-bottom: 0;
    }
}

.ui-autocomplete > li {
    border-bottom: 1px dashed #eee;
}

.ui-autocomplete > li:last-of-type {
    border: 0;
}

.ui-autocomplete > li > .ui-corner-all {
    font-size: 1.1em;
    padding: 10px;
}

.LogoWrap img {
    max-height: 48px;
}

.Login .newlogin .myAccLogin .logIcon {
    background-position: -11px -214px;
}

.sdlProdList .ChildCategoriesListWrapper {
    padding: 0;
}

.sdlProdList .ChildCategoriesListWrapper h2,
.sdlProdList .refineByLeftHeader h2 {
    letter-spacing: 0.05em;
    font-size: 1.4em;
}

.sdlProdList .ChildCategoriesListWrapper #ChildCategoriesList li a,
.sdlProdList .productFilterList .FilterName {
    font-size: 1.2em;
}

.sdlProdList .layout span.ViewbyCols,
.sdlProdList .pagination .sortbyfilter label {
    font-size: 1.2em;
    text-transform: uppercase;
    letter-spacing: 0.05em;
}

.sdlProdList .dropprods_Order {
    max-width: 70%;
    width: auto;
    font-size: 1.2em;
    padding: 0 8px;
    min-width: 200px;
}

.RightBarLinks div.TopLinkMenu > a,
.Responsive .IconBagandWish {
    transition: background-color 0.3s ease-in;
}

@media (max-width: 1260px) and (min-width: 1022px) {
    nav#topMenu
        > ul
        .NewMenuWrap
        .sportsDropdown
        .Dropdown
        .Center
        > ul
        > li:first-child
        + li
        + li
        + li
        > ul
        > li.sportsEquestrian
        > a,
    nav#topMenu
        > ul
        .NewMenuWrap
        .sportsDropdown
        .Dropdown
        .Center
        > ul
        > li:first-child
        + li
        + li
        + li
        > ul
        > li.sportsFootball
        > a,
    nav#topMenu
        > ul
        .NewMenuWrap
        .sportsDropdown
        .Dropdown
        .Center
        > ul
        > li:first-child
        + li
        + li
        + li
        > ul
        > li.sportsRunning
        > a {
        font-size: 1em;
        padding: 72px 0 0;
    }

    nav#topMenu
        > ul
        .NewMenuWrap
        .sportsDropdown
        .Dropdown
        .Center
        > ul
        > li:first-child
        + li
        + li
        + li
        > ul
        > li.sportsEquestrian
        > a::before,
    nav#topMenu
        > ul
        .NewMenuWrap
        .sportsDropdown
        .Dropdown
        .Center
        > ul
        > li:first-child
        + li
        + li
        + li
        > ul
        > li.sportsFootball
        > a::before,
    nav#topMenu
        > ul
        .NewMenuWrap
        .sportsDropdown
        .Dropdown
        .Center
        > ul
        > li:first-child
        + li
        + li
        + li
        > ul
        > li.sportsRunning
        > a::before {
        font-size: 12.87px;
    }
}

@media (min-width: 1022px) {
    .HeaderWrap {
        height: 144px;
        transition: transform 0.3s ease-in-out;
    }

    .menu-search-hidden .HeaderWrap {
        transform: translate3d(0, -100%, 0);
    }

    .searchFocus #BodyWrap .HeaderWrap {
        transform: none;
    }

    .HeaderTopSpacer {
        height: 144px;
    }

    nav#topMenu ul li.mobOnly {
        display: none;
    }

    #MenuOpenContentCover.activeOverlay {
        display: block;
        opacity: 0.5;
    }

    .FooterInner > div {
        margin-bottom: 10px;
    }

    .currencyLanguageSelector .spanCurrencyLanguageSelector::before,
    .currencyLanguageSelector .spanCurrencyLanguageSelector::after {
        content: "";
        display: table;
    }

    .currencyLanguageSelector .spanCurrencyLanguageSelector::after {
        clear: both;
    }

    .TopNavBar .ToplinksGroup .row > div {
        margin-top: 28px;
        margin-bottom: 28px;
        padding: 0;
        height: auto;
    }

    #topLinkMenu ul li.TopLinkDrop ul.TopSubLinkMenu {
        opacity: 1;
    }

    nav#topMenu ul li.root.MenuGroupT.newDrop .SubMenuWrapper ul li.Left {
        width: 60%;
    }

    nav#topMenu ul li.root.MenuGroupT.newDrop .SubMenuWrapper ul li.Center {
        width: 40%;
    }

    .ResponsiveSwitch {
        display: none;
    }

    .signup-wrapper .signupInner {
        float: left;
        width: 50%;
        padding: 40px 0 20px;
    }

    .footerContainer .footerMenu::after {
        content: "";
        display: block;
        position: absolute;
        width: 1px;
        top: 40px;
        bottom: 40px;
        right: 0;
        background-color: #eaeaea;
    }

    #innerfiltercontainer {
        padding: 0;
    }

    #innerfiltercontainer > ul {
        padding: 7px;
        border: 1px solid #f7f7f7;
    }

    .sdlProdList .s-productscontainer2 > li {
        margin-bottom: 20px;
    }

    #divBag .HeaderBagEmptyMessage,
    #divBag .HeaderCheckoutLink {
        font-size: 1.1em;
    }

    #searchSuggestionsDropdown[data-visible="yes"] {
        margin-top: -14px;
    }
}

@media (min-width: 1221px) {
    .ToplinksGroup .search {
        width: 38%;
    }

    .TopNavBar .LogoWrap,
    .TopNavBar .ControlWrap {
        width: 31%;
    }
}

@media (min-width: 1022px) and (max-width: 1220px) {
    .TopNavBar .LogoWrap,
    .TopNavBar .ControlWrap,
    .ToplinksGroup .search {
        width: 33.333333%;
    }

    .ControlWrap .languageBlock,
    .ControlWrap .storeGroup,
    .ControlWrap .WishList {
        margin-right: 5px;
    }

    .ControlWrap .accountBlock {
        margin-right: 5px;
        padding-right: 5px;
        max-width: 60px;
    }

    .ControlWrap .bagWishBlock {
        width: 115px;
    }
}

@media (max-width: 1021px) and (min-width: 768px) {
    .Copyright span {
        margin: 10px 0;
    }
}

@media (min-width: 768px) {
    .signup-wrapper .signupInner .join-us-text {
        padding-top: 0;
        overflow: hidden;
    }

    nav#topMenu li.root.newDrop .SubMenuWrapper > ul .botList > a::before {
        content: "> ";
    }

    nav#topMenu li.root .SubMenuWrapper > ul .viewAllBold > a {
        font-weight: var(--font-weight-bold);
    }

    .FooterSubGroup {
        border-bottom: 0;
    }

    .FooterArea {
        margin-left: 20px;
    }

    .footerMenu > .row {
        width: 25%;
        float: left;
    }

    .bsIconsBagAndWish {
        height: 40px;
    }

    .bsASB {
        padding-top: 11px;
    }

    .bsASB > div {
        padding-left: 0;
        padding-right: 0;
    }

    .Responsive .IconBagandWish {
        border: 1px solid #ddd;
        background-color: #f5f5f5;
    }

    .Responsive .IconBagandWish:hover {
        border: 1px solid #eee;
        background-color: #fafafa;
    }

    .footerPane {
        width: 50%;
    }

    .mainImageButtons > .row {
        display: flex;
        justify-content: center;
    }
}

@media (min-width: 1022px) and (max-width: 1199px) {
    nav#topMenu ul li.root.brandsMenu .SubMenuWrapper ul li.level1::before {
        padding-bottom: 45%;
    }
}

@media (max-width: 1021px) {
    .ToplinksGroup .search {
        padding: 10px 15px;
        border-bottom: 1px solid #444;
        width: 100%;
        height: 56px !important;
    }

    .ToplinksGroup .container-fluid > .row > div,
    .ToplinksGroup .container-fluid > .row {
        height: auto !important;
    }

    #mp-menu {
        width: calc(100% - 46px);
        z-index: 10071 !important;
        max-width: none;
    }

    .MenuCloseActive {
        position: fixed;
        width: 46px;
        height: 50px;
        padding: 0;
        right: -46px;
        background-color: #68bc91;
        background-size: 600px 600px;
        background-position: -131px -118px;
        border-bottom: 1px solid #a6b9cc;
        font-size: 11px;
        cursor: pointer;
        z-index: 10071;
        transition: 0.5s ease-in-out;
    }

    .PullMenuActive .MenuCloseActive,
    .body-menu-open .MenuCloseActive {
        right: 0;
    }

    .MobileMenuContentWrap .MobMenIcon {
        display: none;
    }

    .MobileMenuContentWrap > ul li,
    .MobileMenuContentWrap .has-dropdown li.level1 > a,
    .MobileMenuContentWrap #liMobileCurrencySelector,
    .MobileMenuContentWrap > ul li:hover,
    .MobileMenuContentWrap #liMobileCurrencySelector:hover {
        background-color: transparent;
    }

    .MobileMenuContentWrap .shop > ul > li.root a,
    .MobileMenuContentWrap > .mobMenuGroup li.root a,
    .MobileMenuContentWrap > .mobMenuGroup ~ div li a,
    .MobileMenuContentWrap > .mobMenuGroup ~ ul li a {
        padding: 15px 25px;
    }

    .MobileMenuContentWrap li#mob-myaccount > a + .am-level ul li a {
        background-color: #fff;
    }

    .MobileMenuContentWrap .shop > ul li.level2.sdmColHeader a {
        padding: 15px 20px;
        font-weight: var(--font-weight-bold);
        border-top: 1px solid #ddd;
    }

    .MobileMenuContentWrap .shop > ul li.sdmMobViewAll a {
        font-weight: var(--font-weight-bold);
    }

    .MobileMenuContentWrap > .mobMenuGroup > li.root > a::before,
    .MobileMenuContentWrap > .mobMenuGroup ~ div li.has-dropdown > a::before,
    .MobileMenuContentWrap > .mobMenuGroup ~ ul li a.mob-login::before {
        content: "";
        display: inline-block;
        width: 20px;
        height: 20px;
        vertical-align: middle;
        margin-right: 5px;
        background-size: 700px 700px;
    }

    .MobileMenuContentWrap > .mobMenuGroup ~ ul li a.mob-login::before {
        margin-right: 8px;
        background-position: -398px -30px;
    }

    .currencyHeaderMob,
    .languageHeaderMob {
        display: none;
    }

    .MobileMenuContentWrap .shop > ul > li.root.mobSection > a::before {
        content: "";
        border: 0;
        border-top: 1px solid #ffd51e;
        border-image-slice: 1;
        top: 0;
        left: 0;
        position: absolute;
        height: 1px;
        width: 100%;
    }

    .MobileMenuContentWrap .shop > ul > li.root.mobSection.open > a::before {
        content: none;
    }

    .MobileMenuContentWrap li#mob-myaccount > a::before {
        background-position: -27px -33px;
    }

    .MobileMenuContentWrap li#mob-help > a::before {
        background-size: 650px 650px;
        background-position: -248px -27px;
    }

    .MobileMenuContentWrap li#mob-jobs > a::before {
        background-size: 500px 500px;
        background-position: -251px -19px;
    }

    .MobileMenuContentWrap li#mob-storelocator > a::before {
        background-position: -67px -33px;
    }

    .MobileMenuContentWrap li#mob-wishlist > a::before {
        background-position: -146px -32px;
    }

    .MobileMenuContentWrap li#liMobileLanguageSelector {
        display: none;
    }

    .MobileMenuContentWrap li#liMobileCurrencySelector > a::before {
        background-size: 650px 650px;
        background-position: -289px -27px;
    }

    .currency-eur .MobileMenuContentWrap li#liMobileCurrencySelector > a::before {
        background-position: -453px -102px;
    }

    .currency-aud .MobileMenuContentWrap li#liMobileCurrencySelector > a::before,
    .currency-usd .MobileMenuContentWrap li#liMobileCurrencySelector > a::before,
    .currency-nzd .MobileMenuContentWrap li#liMobileCurrencySelector > a::before,
    .currency-sgd .MobileMenuContentWrap li#liMobileCurrencySelector > a::before {
        background-position: -418px -102px;
    }

    #liMobileCurrencySelector a.mp-back {
        display: block;
        position: relative;
        height: 50px;
        padding: 14px 20px 14px 50px;
        background-color: #0255a3;
    }

    #liMobileCurrencySelector a.mp-back::before {
        position: absolute;
        left: 20px;
        top: 50%;
        transform: translateY(-50%);
        background-image: url("/images/core/sd-sprite-sheet-v12.svg");
        background-size: 600px 600px;
        background-position: -242px -130px;
    }

    #liMobileCurrencySelector a.mp-back span {
        font-size: 1.5em;
        color: #fff;
    }

    #liMobileCurrencySelector .currencyOption {
        padding: 15px 25px;
    }

    #liMobileCurrencySelector .currencyOption label,
    #liMobileCurrencySelector .currencyOption input {
        display: inline-block;
        margin: 0;
        vertical-align: middle;
        padding-right: 5px;
    }

    .MobileMenuContentWrap > .mobMenuGroup,
    .MobileMenuContentWrap > .mobMenuGroup ~ div,
    .MobileMenuContentWrap > .mobMenuGroup ~ ul {
        background-color: #f0f0f0;
    }

    li.has-dropdown > a.MobMenChevron::after {
        right: 5px;
    }

    .shop > ul > li#liAllDepts a {
        background-color: #005a54 !important;
    }

    .am-menu .newDrop ul li.level2 a {
        padding-left: 30px;
    }

    .am-menu .newDrop ul li.level2.indentSub a {
        padding-left: 35px;
    }

    .am-menu .newDrop ul li.level2.sdmColHeader a {
        padding-left: 25px;
        background-color: #f0f0f0;
    }

    .MobSearchDropDown {
        right: 15px !important;
        left: 15px !important;
        width: calc(100% - 30px) !important;
        padding: 5px 10px 10px;
        max-width: none;
    }

    .MobSearchDropDown.ui-autocomplete > li > .ui-corner-all {
        padding-left: 20px;
    }

    .ui-autocomplete {
        z-index: 10071 !important;
    }

    #mp-menu .mp-level .level1.sdmColHeader a,
    .MobileMenuContentWrap .shop > ul li.sdmColHeader a,
    #mp-menu .mp-level .viewAllBold > a,
    #mp-menu .am-level .viewAllBold > a {
        font-weight: var(--font-weight-bold);
    }

    #mp-menu .mp-level .mobMenSplit,
    #mp-menu .am-level .mobMenSplit {
        position: relative;
    }

    #mp-menu .mp-level .mobMenSplit::before,
    #mp-menu .mp-level .mobMenSplit::after,
    #mp-menu .am-level .mobMenSplit::before,
    #mp-menu .am-level .mobMenSplit::after {
        content: "";
        display: block;
        position: absolute;
        top: -1px;
        left: 0;
        right: 0;
        height: 1px;
        background-color: #ffd51e;
    }

    #mp-menu .mp-level .mobMenSplit::before,
    #mp-menu .am-level .mobMenSplit::before {
        background: #fff !important;
        z-index: 0;
    }

    #mp-menu ul li.mobMenToplink {
        position: absolute;
        margin: 0;
        top: 0;
        right: 10px;
        background-color: transparent;
    }

    #mp-menu ul li.mobMenToplink a.menuitemtext {
        color: #fff;
        text-decoration: underline;
        font-size: 1.1em;
        padding: 17px 0 15px;
        box-shadow: none;
        background-color: transparent;
    }

    .MobileMenuContentWrap .brandsMenu ul li.mobIcon a::before {
        content: "";
        display: inline-block;
        width: 40px;
        height: 30px;
        vertical-align: middle;
        margin-right: 5px;
        background: url("/images/core/menu-logos-final-2019-v3c.svg") no-repeat;
        background-size: 400px 400px;
        background-position: -3px -88px;
    }

    .MobileMenuContentWrap .brandsMenu ul li.mobIcon.b_nike a::before {
        background-position: -3px -88px;
    }

    .MobileMenuContentWrap .brandsMenu ul li.mobIcon.b_adidas a::before {
        background-position: -40px -87px;
    }

    .MobileMenuContentWrap .brandsMenu ul li.mobIcon.b_puma a::before {
        background-position: -82px -88px;
    }

    .MobileMenuContentWrap .brandsMenu ul li.mobIcon.b_newbalance a::before {
        background-position: -266px -87px;
    }

    .MobileMenuContentWrap .brandsMenu ul li.mobIcon.b_dunlop a::before {
        background-size: 350px 350px;
        background-position: -296px -162px;
    }

    .MobileMenuContentWrap .brandsMenu ul li.mobIcon a {
        padding: 10px 20px;
    }

    .Responsive #FilterContainer #innerfiltercontainer {
        display: flex;
        flex-direction: column;
        padding-top: 20px;
        padding-bottom: 136px;
    }

    .Responsive #FilterContainer #innerfiltercontainer > div,
    .Responsive #FilterContainer #innerfiltercontainer > ul {
        width: 100%;
    }

    .ChildCategoriesListWrapper {
        order: 5;
        border-top: 1px solid #bbb;
    }

    #pnlRecentlyViewedProducts .AltVertOne .AltStratProduct {
        width: 200px;
    }

    .Responsive .s-left-header h2,
    .ChildCategoriesListWrapper h2 {
        padding: 0 40px;
        line-height: 40px;
        margin-bottom: 10px;
    }

    .ChildCategoriesListWrapper + .refineByLeftHeader .mobDdClose {
        top: 20px;
    }

    .Responsive .mobDdClose {
        width: 40px;
        line-height: 40px;
        height: 40px;
        padding: 0;
        border: 0;
    }

    #TopPaginationWrapper {
        min-height: 73px;
        top: auto !important;
        width: 100% !important;
    }

    #TopPaginationWrapper.sticky .paginationWrapper {
        opacity: 0;
    }

    #TopPaginationWrapper .paginationWrapper {
        transition: opacity 0.4s ease-in-out;
        opacity: 1;
    }

    #TopPaginationWrapper.activeFilterBar .paginationWrapper {
        position: fixed;
        z-index: 960;
        background-color: #fafafa;
        width: 100% !important;
        border-bottom: 1px solid #eee;
        left: 0;
        top: 0 !important;
        opacity: 1;
        padding: 5px 15px;
    }

    .activeFilterBar .mobSortFilter .MobSortSelector > .productFilterList {
        left: 15px;
        width: calc(100% - 30px);
    }

    nav#topMenu ul li.root.brandsMenu .SubMenuWrapper ul li.level1::before {
        padding-bottom: 55%;
    }

    nav#topMenu ul li.root.sportsMenu .SubMenuWrapper ul li.Left,
    nav#topMenu ul li.root.brandsMenu .SubMenuWrapper ul li.Left,
    nav#topMenu ul li.root.newDrop .SubMenuWrapper ul li.Left,
    nav#topMenu ul li.root .SubMenuWrapper ul li.Right {
        display: none !important;
    }

    nav#topMenu ul li.root.sportsMenu .SubMenuWrapper ul li.Center,
    nav#topMenu ul li.root.brandsMenu .SubMenuWrapper ul li.Center,
    nav#topMenu ul li.root .SubMenuWrapper ul li.Center {
        width: 100% !important;
        float: none;
    }

    nav#topMenu ul li.root.brandsMenu .SubMenuWrapper ul li.level1.brandsViewAll a {
        padding-top: 19%;
    }

    .desktopNav {
        display: none;
    }

    nav#topMenu ul li.root.newDrop .SubMenuWrapper ul li.Right,
    .slideoutNav {
        display: block !important;
    }

    nav#topMenu ul li.root > a {
        font-size: 1em;
        padding: 0 2px;
        letter-spacing: 0;
    }

    nav#topMenu ul li.root.MenuGroupT.newDrop .SubMenuWrapper ul li.Left {
        display: inline-block !important;
    }

    nav#topMenu ul li.root.MenuGroupT.newDrop .SubMenuWrapper ul li.Center {
        width: 50% !important;
    }

    .ChildCategoriesListWrapper #ChildCategoriesList li a::after {
        background-position: -265px -206px;
    }

    .FooterWrap .FooterTop > div.signup-wrapper {
        padding: 20px;
        margin-top: 15px;
    }

    .signup-wrapper::before {
        content: "";
        display: block;
        position: absolute;
        left: 15px;
        right: 15px;
        top: 0;
        height: 2px;
        background-color: #ccc;
    }

    #innerfiltercontainer .MobSortSelector {
        display: none;
    }

    .mobSortFilter .MobSortSelector {
        margin: 0;
    }

    .mobSortFilter .MobSortSelector > .productFilterList {
        position: absolute;
        left: 0;
        width: calc(50% - 5px);
        z-index: 960;
        background-color: #f5f5f5;
    }

    .mobSortFilter .MobSortSelector > .productFilterList .evanProdList .MobSortSelector input[type="radio"] {
        display: none;
    }

    .evanProdList .FilterAnchor .MobSortSelectionCheckbox {
        width: 100%;
    }

    .evanProdList .FilterAnchor .MobSortSelectionCheckbox.SelectedFilter span {
        color: #005a54;
    }

    .evanProdList #FilterContainer {
        top: 0;
        box-shadow: none;
        left: 100%;
        transition: left 0.3s ease-in-out;
    }

    .evanProdList #FilterContainer.activeFilter {
        left: 0;
    }

    .evanProdList #FilterContainer #mobFilterControls {
        position: absolute;
        height: auto;
        padding: 20px 10px;
        width: calc(100% - 20px);
        left: 10px;
        bottom: 30px;
        background: rgba(255, 255, 255, 0.5);
    }

    .evanProdList #mobFilterControls li {
        height: auto;
        border: 1px solid #005a54;
    }

    .evanProdList #mobFilterControls li.mobApplyFilter.clearFiltersOpen {
        width: calc(58% - 5px);
    }

    .evanProdList #mobFilterControls li#mobappfltrs {
        background-color: #005a54 !important;
    }

    .evanProdList #mobFilterControls li#mobclrfltrs {
        background-color: #fff !important;
        width: calc(42% - 5px);
    }

    .evanProdList #mobFilterControls li#mobclrfltrs:hover {
        background-color: #fff !important;
    }

    .evanProdList #mobFilterControls li#mobclrfltrs span {
        color: #000;
    }

    .evanProdList #mobFilterControls li .textIconWrap {
        position: static;
        top: auto;
        left: auto;
        transform: none;
        padding: 15px 10px;
    }

    .evanProdList #mobFilterControls li .textIconWrap .glyphicon {
        display: none;
    }

    .evanProdList #FiltersHeader h2 {
        border-bottom: 2px solid #ffd51e;
    }

    .searchFocus .ToplinksGroup #mobSearchContainer {
        z-index: 91;
    }

    #mobSearchContainer {
        display: none;
        background-color: #fff;
        width: 55px;
        float: right;
        position: absolute;
        right: 5px;
        top: 0;
        padding: 0;
    }

    #mobileSearchTriggerBtn {
        display: none;
        background: none;
        width: 100%;
        height: 100%;
    }

    .searchFocus #mobileSearchTriggerBtn {
        display: flex;
        align-items: center;
    }

    #mobileSearchTriggerBtn span.searchIco {
        background-size: 650px 650px;
        background-position: -119px -137px;
        background-repeat: no-repeat;
        height: 30px;
        margin: 0 auto;
        display: block;
        width: 30px;
        font-size: 11px;
    }
}

@media (max-width: 1021px) and (min-width: 768px) {
    #mp-menu {
        height: calc(100% - 126px);
        top: 126px !important;
    }

    .MenuCloseActive {
        top: 126px;
    }

    .HeaderWrap,
    .TopNavBar {
        height: 125px;
    }

    .HeaderTopSpacer {
        height: 126px;
    }

    .LogoWrap a {
        line-height: 70px;
    }

    #divBagTotalLink a#aBagLink,
    #divWishList {
        padding-top: 14px;
    }

    #mp-menu,
    .MenuCloseActive {
        top: 126px !important;
    }
}

@media (min-width: 768px) and (max-width: 1021px) {
    .FooterWrap .txtLink,
    .FooterColLink2 {
        display: none;
    }

    .ToplinksGroup .container-fluid {
        padding: 0;
    }

    #topMenuWrapper #topMenu {
        padding: 0;
    }

    .currencyLanguageSelector .spanCurrencyLanguageSelector {
        width: 100%;
        text-align: center;
    }

    .Responsive .currencyLanguageSlider {
        right: 5px;
    }

    #divBag .HeaderBagEmptyMessage {
        font-size: 1em;
    }

    #divBag .HeaderCheckoutLink {
        font-size: 1.1em;
        letter-spacing: 0.1em;
    }

    .footerMenu > .row {
        width: 32.5%;
        float: left;
    }

    .footPopLink ul {
        width: 100%;
    }

    .socialMediaContainer li {
        padding: 4px 0 !important;
    }

    .login::before {
        width: 80%;
        background-position: -6px -202px;
    }

    .login:hover::before {
        background-position: -70px -201px;
    }

    .bsLogo {
        padding: 0;
    }

    .basketLink .langlogin .login,
    #findAstore a {
        text-indent: -9999px;
    }

    #navigation-container {
        margin: 2% 0 4%;
    }

    .LanguageCurrency {
        float: right;
    }

    .storefind:hover::before {
        background-position: -97px -99px;
    }

    .storefind::before {
        left: 50%;
        transform: translateX(-50%);
        background-position: -32px -99px;
    }

    #searchSuggestionsDropdown[data-visible="yes"] {
        max-height: calc(100vh - var(--top) - 100px);
        left: 20px;
        width: calc(100vw - 40px);
    }
}

@media (max-width: 767px) {
    .ToplinksGroup .ControlWrap,
    .ToplinksGroup #mobMenuContainer {
        width: 120px;
    }

    .ToplinksGroup .LogoWrap {
        width: calc(100% - 240px);
    }

    #mp-menu {
        height: calc(100% - 101px);
        top: 101px !important;
    }

    .MenuCloseActive {
        top: 101px;
    }

    .LogoWrap img {
        max-height: 30px;
    }

    .gwpPromotionContainer {
        margin-right: 10px;
        margin-left: 10px;
    }

    .mobSortFilter .MobSortSelector > .productFilterList {
        border: 1px solid #ddd;
        position: absolute;
        left: 0;
        width: 100%;
    }

    .lillBasket #aBagLink span#bagQuantity {
        vertical-align: -3px;
        position: absolute;
        font-size: 12px;
        left: 50%;
        top: 16px;
        background-color: transparent;
        color: #ffd51e;
        transform: translateX(-50%);
    }

    .ControlWrap .bagWishBlock {
        height: 45px;
    }

    #TopPaginationWrapper.activeFilterBar .paginationWrapper {
        top: 101px !important;
    }

    .am-menu .newDrop ul li.myidTag .menuitemtext::after,
    .am-menu .newDrop ul li.saleTag .menuitemtext::after,
    .am-menu .newDrop ul li.newTag .menuitemtext::after {
        margin-left: 8px;
    }

    .am-menu .MenuGroupSALE > a {
        font-weight: var(--font-weight-bold);
        color: #ee3123;
    }

    .footerQuickLinks {
        padding: 0 15px 15px;
    }

    .FooterGroupLink li {
        text-align: left;
    }

    .FooterGroupLink li a {
        padding: 10px 0;
    }

    .FooterGroupLink {
        width: 75%;
        margin: 0 auto;
        padding-bottom: 15px;
    }

    .sub-buttons {
        margin: 0;
    }

    .ToplinksGroup {
        padding: 0;
    }

    .shop > ul > li.root.mobOnly.appExcl > a {
        background-color: #f9f210;
        font-weight: var(--font-weight-bold);
        text-transform: uppercase;
        font-size: 1.2em;
    }

    .shop > ul > li.root.mobOnly.appExcl > a > .menuitemtext::after {
        color: #ee3425;
        content: " 20% off everything";
    }

    .am-menu .newDrop ul li.footShirts > a > .menuitemtext::after {
        content: " & Kit";
    }

    .am-menu .newDrop ul li.surfGround > a > .menuitemtext::after {
        content: " Ground";
    }

    .am-menu .newDrop ul li.adiBoots > a > .menuitemtext::after {
        content: " Boots";
    }

    .am-menu .newDrop ul li.level2.botList a {
        background-color: #fff;
    }

    .HeaderTopSpacer {
        height: 101px;
    }

    .ControlWrap {
        width: 25%;
    }

    .LogoWrap a {
        line-height: 45px;
    }

    #divBagTotalLink a#aBagLink #bagQuantityContainer {
        height: 100%;
        line-height: 45px;
        width: 30px;
        background-position: -167px -54px;
    }

    .active #divBagTotalLink a#aBagLink #bagQuantityContainer {
        background-position: -167px -54px;
    }

    .signup-wrapper .signupInner .join-us-text {
        margin-bottom: 0;
        padding-top: 0;
    }

    .signup-wrapper + .signup-wrapper {
        margin: 0;
    }

    .signup-wrapper + .signup-wrapper .signupInner::before {
        display: none;
    }

    .FooterSubGroup {
        border-top: solid 1px #ddd;
        text-align: center;
    }

    .footerMenu > div:first-child .FooterSubGroup {
        border: none;
    }

    .bsASB {
        padding-top: 6px;
    }

    .Responsive .IconBagandWish:hover {
        background-color: #fafafa;
    }

    #divBagTotalLink span#bagQuantity {
        width: 100%;
        margin: 0;
        padding: 0;
        line-height: 1.5em;
    }

    #divBagTotalLink a::before {
        background-position: -20px -339px;
    }

    .coprightmega {
        text-align: center;
    }

    .lillBasket #divBagItems,
    #divAjaxLoaderImage,
    #divEmptyErrorMessage {
        top: 45px;
    }

    .Basket .ads {
        display: none;
    }

    .Responsive .categoryListSide h2 {
        background: #000;
    }

    .csHomeTable {
        min-height: 275px;
    }

    .ToplinksGroup .top-right {
        padding: 8.5px 0 0 !important;
    }

    .footerelements {
        padding: 0;
    }

    .FooterHeader span {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        height: 18px;
        font-size: 11px;
        float: none;
        width: 30px;
        background-size: 850px 850px;
        background-position: -268px -475px;
    }

    .FooterHeader span.glyphicon-chevron-down {
        background-position: -188px -443px;
    }

    .FooterHeader span::before {
        display: none;
    }

    .FooterSubGroup .FooterHeader {
        padding: 20px 0;
        text-align: left;
        width: 95%;
        float: none;
        position: relative;
        display: inline-block;
    }

    .FooterSubGroup .FooterHeader span {
        padding: 0;
        right: 0;
        width: 20px;
        height: 18px;
        font-size: 11px;
        background-image: url("/images/core/chevron-sprite.svg");
        background-size: 250px 250px;
        background-position: -209px -168px;
    }

    .FooterSubGroup .FooterHeader span.glyphicon-chevron-down {
        background-position: -209px -142px;
    }

    .FootdvSearch {
        padding: 0 2%;
    }

    .socialtext {
        display: none;
    }

    .TopNavBar {
        height: 100px;
    }

    #searchSuggestionsDropdown[data-visible="yes"] {
        max-height: calc(100vh - var(--top) - 150px);
        left: 15px;
        width: calc(100vw - 30px);
    }
}

.singlecolumnadvert .view-button {
    display: block;
    font-size: 0.9em;
    background-color: #ccc;
    padding: 15px;
    text-align: center;
}

.modal .close:hover {
    background-color: transparent !important;
    background-position: -308px -491px !important;
}

.MagazineModal .close {
    width: 40px;
    height: 40px;
}

#hotspotModal .modal-header {
    height: 45px;
}

#hotspotModal .close {
    height: 29px;
    width: 29px;
    right: 8px;
    top: 8px;
}

.MagazineModal .close,
#advertPopup .close,
#hotspotModal .close {
    background-color: #fff !important;
}

.MagazineModal .close:hover,
#advertPopup .close:hover,
#hotspotModal .close:hover {
    background-color: #fff !important;
}

@media (min-width: 1280px) {
    nav#topMenu > ul li.myidTag a::after,
    nav#topMenu > ul li.saleTag a::after,
    nav#topMenu > ul li.newTag a::after {
        margin-left: 8px;
    }

    nav#topMenu ul li.root > a {
        padding: 0 8px;
        letter-spacing: 1px;
    }
}

#BasketModal.appPromoMug .modal-dialog {
    max-height: 500px;
    max-width: 400px;
}

#BasketModal.appPromoMug .modal-body {
    height: 450px;
    max-height: 450px;
    overflow-x: visible;
    overflow-y: visible;
    padding: 15px 25px 20px;
}

#BasketModal.appPromoMug #workarea {
    border: none;
}

#BasketModal.appPromoMug .modal-header .header-text {
    display: none;
}

.forcedProductInnerContent {
    padding-top: 100px;
}

.forcedProductInnerContent .forcedProductImage {
    text-indent: 100%;
    white-space: nowrap;
    overflow: hidden;
    background-image: url("/images/marketing/mugshot.png");
    height: 200px;
    width: 200px;
    background-size: contain;
    position: absolute;
    top: -95px;
    left: 50%;
    margin-left: -119px;
}

.forcedProductInnerContent .forcedProductContentTitle {
    text-transform: uppercase;
    font-size: 2.7em;
    font-weight: var(--font-weight-regular);
    letter-spacing: 2px;
}

.forcedProductInnerContent .forcedProductContentTitle span {
    font-weight: var(--font-weight-bold);
}

.forcedProductInnerContent .forcedProductContentTitle .rStar,
.forcedProductInnerContent .forcedProductContentTitle .lStar {
    display: inline-block;
    position: relative;
    width: 30px;
    height: 30px;
    vertical-align: top;
    background-image: url("/images/core/sd-prod-list-prod-detail-sprite-updated-v6.svg");
    background-size: 22em 22em;
    background-position: -2.2em -6.4em;
}

.forcedProductInnerContent .forcedProductContentTitle .rStar {
    margin-left: 10px;
}

.forcedProductInnerContent .forcedProductContentTitle .lStar {
    margin-right: 10px;
}

.forcedProductInnerContent .forcedProductContentTitle .lStar::before,
.forcedProductInnerContent .forcedProductContentTitle .rStar::before {
    content: " ";
    display: inline-block;
    width: 18px;
    height: 18px;
    position: absolute;
    top: 0;
    background-image: url("/images/core/sd-prod-list-prod-detail-sprite-updated-v6.svg");
    background-size: 15em 15em;
    background-position: -1.55em -4.39em;
}

.forcedProductInnerContent .forcedProductContentTitle .lStar::before {
    left: -18px;
}

.forcedProductInnerContent .forcedProductContentTitle .rStar::before {
    right: -18px;
}

.forcedProductInnerContent p {
    font-size: 1.5em;
    font-style: italic;
    margin-bottom: 45px;
}

.forcedProductInnerContent p.forcedProductAddMessage {
    font-weight: var(--font-weight-bold);
    font-style: normal;
    letter-spacing: 1px;
    margin-bottom: 5px;
}

.forcedProductInnerContent p.forcedProductAddMessage span {
    text-transform: uppercase;
}

.forcedProductInnerContent p.discountMessage {
    font-size: 1.1em;
    margin-bottom: 20px;
}

#BasketModal.appPromoMug .buttoncontainer {
    padding: 0;
}

#BasketModal.appPromoMug .buttoncontainer > .ImgButWrap {
    display: block;
    margin-top: 15px;
    padding: 0;
}

#BasketModal.appPromoMug .buttoncontainer > .ImgButWrap a {
    padding: 18px 15px;
    font-size: 1.6em;
    font-weight: var(--font-weight-bold);
    line-height: 1.5em;
}

#BasketModal.appPromoMug .buttoncontainer > .ImgButWrap a::before {
    content: " ";
    display: inline-block;
    width: 27px;
    height: 20px;
    vertical-align: sub;
    margin-right: 10px;
    background-image: url("/images/core/sd-prod-list-prod-detail-sprite-updated-v6.svg");
    background-size: 30em 30em;
}

#BasketModal.appPromoMug .buttoncontainer > .ImgButWrap:first-child a::before {
    background-position: 9.95em 19.2em;
}

#BasketModal.appPromoMug .buttoncontainer > .ImgButWrap:first-child + .ImgButWrap a::before {
    background-position: 7.6em 17.2em;
}

@media (min-width: 768px) {
    #BasketModal.appPromoMug .modal-dialog {
        width: 400px;
    }
}

@media (max-width: 767px) {
    #BasketModal.appPromoMug .modal-body {
        height: 400px;
        max-height: 400px;
    }

    .forcedProductInnerContent {
        padding-top: 70px;
    }

    .forcedProductInnerContent .forcedProductImage {
        width: 150px;
        height: 150px;
        top: -78px;
        margin-left: -90px;
    }

    .forcedProductInnerContent p {
        margin-bottom: 25px;
    }
}

#advertPopup .modal-dialog {
    max-width: 634px;
    height: 100%;
    max-height: 634px;
}

#advertPopup .modal-body {
    max-width: 632px;
    height: 632px;
    max-height: 632px;
}

@media (max-width: 633px) {
    #advertPopup .modal-dialog {
        max-height: 633px;
    }

    #advertPopup .modal-body {
        height: 631px;
        max-height: 631px;
    }
}

@media (max-width: 594px) {
    #advertPopup .modal-dialog {
        max-height: 594px;
    }

    #advertPopup .modal-body {
        height: 592px;
        max-height: 592px;
    }
}

@media (max-width: 549px) {
    #advertPopup .modal-dialog {
        max-height: 549px;
    }

    #advertPopup .modal-body {
        height: 547px;
        max-height: 547px;
    }
}

@media (max-width: 504px) {
    #advertPopup .modal-dialog {
        max-height: 504px;
    }

    #advertPopup .modal-body {
        height: 502px;
        max-height: 502px;
    }
}

@media (max-width: 459px) {
    #advertPopup .modal-dialog {
        max-height: 459px;
    }

    #advertPopup .modal-body {
        height: 457px;
        max-height: 457px;
    }
}

@media (max-width: 414px) {
    #advertPopup .modal-dialog {
        max-height: 414px;
    }

    #advertPopup .modal-body {
        height: 412px;
        max-height: 412px;
    }
}

@media (max-width: 375px) {
    #advertPopup .modal-dialog {
        max-height: 375px;
    }

    #advertPopup .modal-body {
        height: 373px;
        max-height: 373px;
    }
}

@media (max-width: 348px) {
    #advertPopup .modal-dialog {
        max-height: 348px;
    }

    #advertPopup .modal-body {
        height: 346px;
        max-height: 346px;
    }
}

@media (max-width: 320px) {
    #advertPopup .modal-dialog {
        max-height: 320px;
    }

    #advertPopup .modal-body {
        height: 318px;
        max-height: 318px;
    }
}

.FastPayStatusBox p,
.FastPaySettingRowButtons div {
    color: #0154a4 !important;
}

a.list-group-item:hover,
a.list-group-item:focus,
.primaryColor {
    background-color: #0054a4 !important;
    border-color: #0054a4 !important;
}

.list-group-item.list-group-submenu:hover,
.list-group-item.list-group-submenu:focus {
    background-color: #0054a4 !important;
}

.headRow .divtableCell {
    background-color: #0054a4 !important;
}

#superModal .modal-dialog {
    max-width: 902px;
    width: 100%;
    max-height: 370px;
    height: 100%;
    top: 50%;
    transform: translateY(-50%);
}

#superModal .modal-body {
    height: 100%;
    width: 100%;
    padding: 0;
}

#superModal .modal-header {
    height: 0;
    border: 0;
    padding: 0;
}

#superModal #finalFrame {
    display: none;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
}

#superModal #finalFrame img {
    margin-bottom: 0;
}

.catTemplate #videoCover {
    cursor: pointer;
    z-index: 2;
    transition: all 0.7s ease-in;
}

.parallax .TopLinkBar {
    height: 5px;
}

.parallax .TopNavBar {
    top: 5px;
}

@media (min-width: 1022px) {
    .lillBasket #divBagItems,
    #divAjaxLoaderImage,
    #divEmptyErrorMessage {
        transform: translate3d(0, 48px, 0);
        max-height: calc(100vh - 76px);
        transition: transform 0.3s ease-in-out;
        top: 0;
    }

    .menu-search-hidden .lillBasket #divBagItems,
    .menu-search-hidden #divAjaxLoaderImage,
    .menu-search-hidden #divEmptyErrorMessage {
        transform: translate3d(0, 116px, 0);
        max-height: 100vh;
    }

    .AltProdList ul.productFilters > li {
        padding: 0 0 25px;
    }

    .productFilterTitleBox,
    .productFilterTitleBoxCLOSED {
        padding: 10px 0;
        margin-bottom: 10px;
        border-bottom: 1px solid #ddd;
    }

    #FiltersAndProductsWrapper #FilterContainer ul.productFilters .productFilter .FilterAnchor {
        position: relative;
        padding: 7px 5px 7px 0;
    }

    #FiltersAndProductsWrapper #FilterContainer ul.productFilters .productFilter .FilterAnchor > span {
        padding: 0 0 0 30px;
        background: none;
    }

    #FiltersAndProductsWrapper #FilterContainer ul.productFilters .productFilter .FilterAnchor > span::before {
        display: block;
        position: absolute;
        width: 20px;
        height: 20px;
        left: 0;
        top: 50%;
        transform: translateY(-50%);
        border: 1px solid #000;
    }

    #FiltersAndProductsWrapper #FilterContainer ul.productFilters .productFilter .FilterAnchor .SelectedFilter {
        color: #005a54;
    }

    #FiltersAndProductsWrapper #FilterContainer ul.productFilters .productFilter .FilterAnchor .SelectedFilter::before,
    #FiltersAndProductsWrapper #FilterContainer ul.productFilters .productFilter .FilterAnchor:hover > span::before {
        background-color: #005a54;
        border-color: #005a54;
    }

    .productFilterList {
        border: none;
        padding: 0;
    }

    .productFilter .productFilterList .brandSearchSort {
        padding: 0 10px 0 0;
    }

    .productFilter .productFilterList::-webkit-scrollbar {
        width: 5px;
    }

    .productFilter .productFilterList::-webkit-scrollbar-track {
        background: #ddd;
    }

    .productFilter .productFilterList::-webkit-scrollbar-thumb {
        background: #005a54;
        border-radius: 5px;
    }

    .ControlWrap .bagWishBlock {
        height: 48px;
    }

    .TopNavBar {
        height: 104px;
    }
}

#accountMenu .sidebar-nav li.active a,
.cprefs section h2 {
    color: #005a54;
}

#accountMenu .sidebar-nav li.active a::before,
#accountMenu ul.sidebar-nav li.SignOutAcc a,
.cprefs .yesnoSelect label.selected,
.cprefs .cprefsFrequency label.selected {
    background-color: #005a54;
}

#accountMenu ul.sidebar-nav li.SignOutAcc a:hover {
    background-color: #015fb8;
}

.threeBox h2::before {
    background-position: -269px -284px;
}

.threeBox .cprefsSMS h2::before {
    background-position: -270px -320px;
}

.threeBox .cprefsPost h2::before {
    background-position: -350px -318px;
}

nav#topMenu ul.RootGroup li.MenuGroupSALE {
    background-color: #ee3425;
    order: 10;
}

nav#topMenu ul.RootGroup li.MenuGroupDEALS {
    order: 11;
    background-color: #fedd00;
}

nav#topMenu ul li.MenuGroupSALE > a:hover,
nav#topMenu ul li.MenuGroupSALE.sdHover > a {
    color: #ee3425 !important;
}

nav#topMenu ul li.MenuGroupDEALS > a {
    color: #000 !important;
}

.li-pr-home,
.li-pr-basket {
    display: block !important;
    padding: 0 0 20px;
}

#li_placement .hotspotbuy {
    position: absolute;
    top: 15px;
}

.li-pr-basket .swiper-container {
    padding-bottom: 10px;
}

#li_placement .hotspotbuy.hotspotquickbuy,
#li_placement .hotspotbuy.hotspotwishlist {
    right: 15px;
    height: 28px;
    width: 28px;
    background: transparent;
}

#li_placement .hotspotbuy.hotspotwishlist {
    right: 50px;
}

.SuggestedProduct h3 a:hover span {
    text-decoration: underline;
    color: #636363;
}

#li_placement .SuggestedProduct .QuickLookIcon,
#li_placement .SuggestedProduct .WishIcon,
#li_placement .hotspotbuy .QuickLookIcon,
#li_placement .hotspotbuy .WishIcon {
    width: 28px;
    height: 28px;
    background-size: 66em 45em;
}

#li_placement .SuggestedProduct .QuickLookIcon,
#li_placement .hotspotbuy .QuickLookIcon {
    background-position: -23px -953px;
}

#li_placement .SuggestedProduct .QuickLookIcon:hover,
#li_placement .hotspotbuy .QuickLookIcon:hover {
    background-position: -67px -953px;
}

#li_placement .SuggestedProduct .hotspotbuy .WishIcon,
#li_placement .hotspotbuy .WishIcon {
    background-position: -23px -1012px;
}

#li_placement .hotspotbuy .SuggestedProduct .WishIcon:hover,
#li_placement .hotspotbuy .WishIcon:hover {
    background-position: -67px -1012px;
}

.PSPlacementHorizontal,
.PSPlacementVertical {
    margin-top: 20px;
}

#li_placement .PSPlacementVertical {
    margin-top: 0;
}

#li_placement .PSPlacementVertical .swiper-wrapper {
    width: auto;
}

.Home .PSPlacementHorizontal .PSPlacementTitle {
    padding: 10px;
    color: #000;
    font-size: 1.6em;
    font-weight: var(--font-weight-bold);
    text-transform: uppercase;
    margin-bottom: 20px;
    border: none;
}

.Home .PSPlacementHorizontal .ProductSuggestionsListing li {
    text-align: center;
    width: 25%;
}

#li_placement .PSPlacementHorizontal #li_swiper.ProductSuggestionsListing .hotspotbuy {
    display: none !important;
}

.Home .PSPlacementHorizontal .ProductSuggestionsListing li .SuggestedProduct h4 {
    padding: 0;
}

.Home .PSPlacementHorizontal .ProductSuggestionsListing li .SuggestedProduct a {
    padding-bottom: 5px;
}

.Home .PSPlacementHorizontal .ProductSuggestionsListing li .SuggestedProduct a .PSProdBrand {
    font-weight: var(--font-weight-bold);
}

#li_placement .PSPlacementVertical .ProductSuggestionsListing li {
    display: block;
    padding: 5px;
    text-align: center;
    width: 100%;
}

#li_placement .ProductSuggestionsListing > li > div > a:hover {
    text-decoration: underline;
}

.Home .PSPlacementHorizontal .ProductSuggestionsListing li .SuggestedProduct > a {
    display: inline-block;
    width: 100%;
}

#li_placement .PSPlacementVertical .SuggestedProduct > a {
    display: none;
}

.Home .PSPlacementHorizontal .PSSellPrice {
    font-size: 1.8em;
    font-weight: var(--font-weight-bold);
    line-height: 1.2em;
    color: #000;
}

.Home .PSPlacementHorizontal .PSSellPrice.productHasRef {
    color: #ee3123;
}

.Home .PSPlacementHorizontal .PSProdPrice {
    padding: 0 5px;
}

.Home .PSPlacementHorizontal .PSProdPrice .PSRefPrice {
    font-size: 1.2em;
    text-decoration: line-through;
}

#li_placement .BlockDiv {
    display: block;
    clear: both;
}

#li_placement .productimage.s-productthumbimage {
    position: relative;
}

#li_placement .PSPlacementVertical .productimage {
    padding: 0 25%;
    margin-bottom: 5px;
}

#li_placement .QuickBuyButtonContainer {
    position: absolute;
    bottom: 0;
    right: 0;
    width: 42px;
}

.SuggestedProduct > h4 a:hover div {
    text-decoration: underline;
}

.Copyright,
.ResponsiveSwitch {
    max-width: 1440px;
    text-align: center;
    padding: 0 15px;
}

.ResponsiveSwitch a {
    padding: 20px 10px;
    display: inline-block;
}

.Copyright {
    padding-top: 30px;
}

.Copyright .footerSocial::after {
    content: "";
    display: block;
    position: absolute;
    top: 25px;
    bottom: 5px;
    right: 0;
    width: 1px;
    background-color: #000;
}

.Copyright .footerSocial ul,
.Copyright .footerPaymentLine .footerPayment {
    display: inline-block;
    overflow: hidden;
    margin: 20px 0;
}

.Copyright .footerSocial ul li {
    float: left;
    margin-left: 10px;
}

.Copyright .footerSocial ul li:first-child {
    margin: 0;
}

.Copyright .footerSocial ul li a {
    display: block;
    width: 60px;
    height: 50px;
    text-indent: 100%;
    white-space: nowrap;
    overflow: hidden;
    background-size: 2500px 2500px;
}

.Copyright .footerSocial ul li.socFacebook a {
    background-position: -89px -638px;
}

.Copyright .footerSocial ul li.socInstagram a {
    background-position: -152px -638px;
}

.Copyright .footerSocial ul li.socYouTube a {
    background-position: -284px -638px;
}

.Copyright .footerSocial ul li.socBlog a {
    background-position: -89px -638px;
}

.Copyright .footerSocial ul li.socBlog {
    display: none;
}

.socTwitter .XLogo {
    height: 100%;
    margin: 0;

    .global-icon.global-icon-x {
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 0;

        svg {
            max-width: 32px;
        }
    }
}

.Copyright .footerPaymentLine .footerPayment {
    width: 100%;
    height: 50px;
    background-image: url("/images/payment/cardpayments-gbp-v2.png");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: 50% 50%;
}

.u-vh {
    position: absolute;
    overflow: hidden;
    clip: rect(0 0 0 0);
    inline-size: 1px;
    block-size: 1px;
    margin: -1px;
    padding: 0;
    border: 0;
}

.currency-eur .Copyright .footerPaymentLine .footerPayment,
.currency-usd .Copyright .footerPaymentLine .footerPayment,
.currency-aud .Copyright .footerPaymentLine .footerPayment,
.currency-nzd .Copyright .footerPaymentLine .footerPayment,
.currency-sgd .Copyright .footerPaymentLine .footerPayment {
    background-image: url("/images/payment/cardpayments-aunzie-v2.png");
}

.currency-myr .Copyright .footerPaymentLine .footerPayment {
    background-image: url("/images/payment/cardpayments-myr.png");
}

.Copyright span {
    display: block;
    text-transform: uppercase;
    color: #005a54;
    margin: 0;
}

.Copyright .footerCopyRightLine span {
    text-transform: none;
    margin: 40px 0 20px;
}

.appLinks,
.appLinks div {
    padding: 0;
}

.appLinks {
    margin-top: 20px;
}

.appLinks .appText {
    color: #fff;
    font-size: 14px;
    margin-bottom: 5px;
}

.appLinks a {
    display: block;
    height: 100%;
}

.appImageApple,
.appImageGoogle {
    height: 55px;
}

.appImageApple span,
.appImageGoogle span {
    background-image: url("/images/core/sd-new-footer-icons.svg");
    background-repeat: no-repeat;
    height: 100%;
    width: 100%;
    max-width: 125px;
    background-size: 900px 900px;
    display: inline-block;
}

.appImageApple span {
    background-position: -10px -203px;
}

.appImageGoogle span {
    background-position: -10px -152px;
}

@media (min-width: 768px) {
    .Home .PSPlacementHorizontal .ProductSuggestionsListing {
        flex-flow: row wrap;
        max-width: 1440px;
    }

    .Home .PSPlacementHorizontal .ProductSuggestionsListing li {
        padding: 20px 30px;
    }

    .Home .PSPlacementHorizontal .productimage .PSImage .PSPimg-res {
        width: 70%;
        padding-bottom: 15px;
    }

    #financeOptionsGenericWrapper.calc-link {
        padding-right: 60px;
    }
}

@media (min-width: 768px) and (max-width: 1021px) {
    .ToplinksGroup #mobMenuContainer,
    .ControlWrap .bagWishBlock,
    #mobSearchContainer {
        height: 70px !important;
    }

    .Home .PSPlacementHorizontal .ProductSuggestionsListing li {
        width: 33.333333% !important;
        max-width: none;
    }

    .Home .PSPlacementHorizontal .ProductSuggestionsListing li:nth-child(6),
    .Home .PSPlacementHorizontal .ProductSuggestionsListing li:nth-child(7) {
        display: none;
    }
}

@media (max-width: 767px) {
    .Home .PSPlacementHorizontal #li_swiper.ProductSuggestionsListing .hotspotbuy,
    .li-pr-basket .PSPlacementHorizontal .ProductSuggestionsListing .hotspotbuy {
        display: none !important;
    }

    .Home #li_placement .productimage.s-productthumbimage {
        padding: 0 50px;
    }
}

#lblCategoryCopy .textLeft p #catCopyMobileReadMoreLess {
    cursor: pointer;
    font-weight: var(--font-weight-bold);
}

.Browse .categorycopyd4 .HeadnCount {
    margin: 10px 0;
    top: 0;
}

.Browse .searchWrapResults {
    font-size: 1.2em;
}

.Browse .topheadbox {
    border-top: 0;
}

.Browse .categorycopyd4 {
    padding-bottom: 10px;
    text-align: left;
}

.Browse #divsiblinglistwrapper ul,
.Browse .siblingcategory {
    text-align: left;
}

#divViewMore {
    display: inline-block;
    padding-left: 5px;
}

#lblCategoryCopy .catTemplate .textLeft {
    padding: 0 20px;
    font-size: 1em;
}

#lblCategoryCopy .catTemplate .subLink {
    display: block;
    text-decoration: underline;
    margin: 0 0 10px;
    letter-spacing: 0.1em;
    color: #636363;
}

#lblCategoryCopy .catTemplate .siblingMore {
    margin-top: 10px;
    font-weight: var(--font-weight-bold);
    display: block;
    letter-spacing: 0.1em;
}

#lblCategoryCopy .catTemplate .textLeft p {
    display: block !important;
    font-size: 1em;
}

#lblCategoryCopy .catTemplate .subLink,
#lblCategoryCopy .catTemplate .siblingMore {
    font-size: 1em;
}

#lblCategoryCopy .catTemplate .siblingLinks a {
    line-height: 1.8em;
    padding: 5px 10px;
    color: #636363;
}

.mobile-read-more-less-enabled #lblCategoryCopy #read-more-less,
.mobile-read-more-less-enabled .topheadbox #read-more-less {
    position: inherit;
}

@media (max-width: 1021px) {
    #lblCategoryCopy .catTemplate .textLeft {
        margin: 0;
        width: 100%;
    }

    #lblCategoryCopy .catTemplate .imgRight {
        display: none;
    }

    #lblCategoryCopy .catTemplate .siblingMore {
        display: block !important;
    }

    .sdlProdList #lblCategoryCopy {
        border: 0;
    }

    .Browse .categorycopyd4 .HeadnCount {
        position: static;
        width: 100%;
        top: auto;
        left: auto;
        padding: 0;
    }
}

@media (max-width: 767px) {
    #lblCategoryCopy .textLeft .siblingLinks {
        white-space: nowrap;
        overflow-x: auto;
        -webkit-overflow-scrolling: touch;
    }

    #lblCategoryCopy .textLeft .siblingLinks::-webkit-scrollbar {
        height: 5px;
    }

    #lblCategoryCopy .textLeft .siblingLinks::-webkit-scrollbar-track {
        background: #ddd;
    }

    #lblCategoryCopy .textLeft .siblingLinks::-webkit-scrollbar-thumb {
        background: #0255a3;
        border-radius: 5px;
    }

    .sdlProdList #lblCategoryCopy {
        display: block;
    }

    #lblCategoryCopy .catTemplate .textLeft {
        padding: 0 10px;
    }

    #lblCategoryCopy .catTemplate .siblingLinks a {
        background-color: #fff;
        margin: 0 4px 10px;
        display: inline-block;
    }

    #lblCategoryCopy .catTemplate .siblingLinks a:hover {
        text-decoration: none;
        background-color: #eaeaea;
    }

    #lblCategoryCopy .catTemplate .siblingMore {
        margin-top: 10px;
    }

    .ControlWrap .WishList .wishQuantityContainer #lblWishList {
        display: none;
    }

    .ControlWrap .WishList,
    #divBag #divBagTotalLink {
        padding-top: 9px;
    }
}

.mp-container .mp-menu .mp-level #homeMenu li > a.mp-close,
.mp-container .mp-menu .mp-level ul > li a.mp-close {
    display: none;
}

.mp-container .mp-menu .mp-level {
    max-height: 100%;
    z-index: 2;
}

.menulevelheader {
    font-size: 1.3em;
    height: 50px;
    padding: 0 10px 0 50px;
}

.shop > ul > li a.mp-back,
.mobMenuGroup #mob-myaccount .mp-back {
    background: transparent;
    position: absolute;
    top: 0;
    left: 10px;
    height: 50px;
    width: 30px;
    text-indent: 100%;
    white-space: pre;
    overflow: hidden;
    font-size: 0.8em;
    padding: 15px 0 !important;
}

.shop > ul > li a.mp-back .mp-back-text,
.mobMenuGroup #mob-myaccount .mp-back .mp-back-text {
    display: none;
}

.mobMenuGroup #mob-myaccount .mp-back::after,
.shop > ul > li a.mp-back::after,
#liMobileCurrencySelector a.mp-back::after {
    content: "";
    position: absolute;
    background-image: url("/images/core/sd-sprite-sheet-v12.svg");
    background-size: 600px 600px;
    background-position: -242px -130px;
    display: block;
    width: 20px;
    height: 20px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

#liMobileCurrencySelector a.mp-back::after {
    left: 15px;
    transform: translateY(-50%);
}

.MobileMenuContentWrap .shop > ul > li .menulevelheader > a,
.mobMenuGroup #mob-myaccount .menulevelheader a {
    font-size: 1.2em;
    text-decoration: none;
    padding: 13px 5px;
    display: inline-block;
}

#homeMenu > li > a {
    font-size: 1.6em;
    padding: 13px 25px;
}

.mp-container .mp-menu .mmHasChild .mp-level.show-level .mobMenGroup {
    background-color: #fff;
}

.mobMenuGroup #mob-myaccount .menulevelheader a {
    color: #fff;
}

#mp-menu .mp-level {
    background-color: #fff !important;
}

#homeMenu,
.menulevelheader {
    background-color: #005a54;
}

#mp-menu .mp-level .menuitemtext {
    color: #000;
}

#productDetails .rating-container {
    margin-bottom: 10px !important;
    min-height: 19px;
}

.rating-container [data-bv-show="rating_summary"] .bv_main_container {
    margin: 0 !important;
}

.rating-container [data-bv-show="rating_summary"] .bv_main_container .bv_avgRating_component_container,
.reviews-container [data-bv-show="inline_rating"] .bv_main_container .bv_averageRating_component_container {
    display: none !important;
}

.rating-container [data-bv-show="rating_summary"] .bv_main_container .bv_numReviews_component_container {
    padding-top: 1px !important;
}

.rating-container [data-bv-show="rating_summary"] .bv_main_container .bv_main_container_row_flex {
    padding-right: 8px !important;
}

.rating-container [data-bv-show="rating_summary"] .bv_main_container .bv_stars_component_container {
    padding-right: 5px !important;
}

.ProdDetails div.bv_modal_component_container[data-bv-modal] {
    z-index: 6000 !important;
}

#productlistcontainer .reviews-container {
    padding: 0 10px !important;
    margin-top: 10px !important;
    margin-bottom: 5px !important;
    min-height: 19px;
    text-align: left;
}

.reviews-container [data-bv-show="inline_rating"] > div {
    display: block !important;
}

.reviews-container [data-bv-show="reviews"],
.reviews-container [data-bv-show="questions"] {
    padding: 20px 0 !important;
}

.bv_modal_component_container .bv_histogram_row_container {
    height: auto !important;
    background-color: #f7f7f7 !important;
    padding: 8px !important;
    display: block !important;
}

.bv_modal_component_container .bv_histogram_row_container:hover {
    background-color: #efefef !important;
}

.bv_modal_component_container .bv_button_component_container {
    padding-top: 15px !important;
}

.bv_modal_component_container .bv_histogram_row_star {
    padding-left: 3px !important;
}

.bv_modal_component_container .bv_button_component_container button.bv_button_buttonFull {
    width: 100% !important;
    padding: 15px !important;
    transition: background-color 0.3s ease-in;
}

#bv-mboxzone-lightbox.bv-cv2-cleanslate .bv-mbox-wide .bv-mbox {
    background-color: #f0f0f0 !important;
}

#bv-mboxzone-lightbox.bv-cv2-cleanslate
    .bv-mbox-fullprofile
    .bv-sidebar
    .bv-author-profile-userinfo
    .bv-author-userstats-list
    .bv-author-userstats-value {
    background-color: rgba(25, 25, 25, 0.1) !important;
}

.reviews-container
    .bv-cv2-cleanslate
    .bv-content-pagination
    .bv-content-pagination-container
    .bv-content-btn-pages-load-more {
    text-transform: uppercase !important;
    background-color: #000 !important;
    color: #fff !important;
    padding: 10px !important;
    border: 0 !important;
    width: auto !important;
    min-width: 200px !important;
}

.reviews-container
    .bv-cv2-cleanslate
    .bv-content-pagination
    .bv-content-pagination-container
    .bv-content-btn-pages-load-more:hover {
    background-color: #000 !important;
    color: #fff !important;
}

.reviews-container
    .bv-cv2-cleanslate
    .bv-content-pagination
    .bv-content-pagination-container
    .bv-content-btn-pages-load-more
    .bv-content-btn-pages-load-more-text {
    text-transform: uppercase !important;
    cursor: pointer !important;
    position: relative !important;
    font-family: var(--brand-font) !important;
    letter-spacing: 0.1em !important;
    font-weight: var(--font-weight-bold) !important;
}

.reviews-container .bv-cv2-cleanslate .bv-content-header .bv-content-title-container {
    padding-top: 10px !important;
}

.reviews-container .bv-cv2-cleanslate .bv-header .bv-section-summary .bv-content-title {
    padding-top: 25px !important;
}

.reviews-container .bv-cv2-cleanslate {
    margin: 20px 0;
}

@media (max-width: 767px) {
    .reviews-container [data-bv-show="reviews"],
    .reviews-container [data-bv-show="questions"] {
        padding: 20px 10px !important;
    }

    .reviews-container .bv-cv2-cleanslate {
        margin: 20px 10px;
    }

    .rating-container {
        padding: 0 15px;
    }
}

.reviews-container .bv-cv2-cleanslate .bv-compat .bv-content-placeholder .bv-rating-stars-container.bv-rating-none {
    margin: 10px 5px 10px 10px !important;
}

.reviews-container .bv-cv2-cleanslate .bv-compat .bv-content-placeholder {
    padding-bottom: 30px !important;
}

.reviews-container [data-bv-show="questions"] .bv-cv2-cleanslate .bv-compat .bv-content-placeholder {
    padding-left: 10px !important;
}

.reviews-container .bv-cv2-cleanslate .bv-compat .bv-content-noreviews-label,
.reviews-container .bv-cv2-cleanslate .bv-compat .bv-content-placeholder .bv-write-review-label {
    vertical-align: middle !important;
    line-height: 20px !important;
    margin: 10px 5px 10px 10px !important;
    display: inline-block !important;
}

.reviews-container [data-bv-rating] .bv_main_container .bv_text,
.reviews-container [data-bv-show="inline_rating"] .bv_main_container .bv_text {
    line-height: 1.2em !important;
    font-size: 1.2em !important;
    padding: 0 !important;
}

.reviews-container .bv-cv2-cleanslate .bv-compat .bv-content-slider-sublabel1,
.reviews-container .bv-cv2-cleanslate .bv-compat .bv-content-slider-sublabel2 {
    display: inline-block !important;
    float: none !important;
}

.reviews-container [data-bv-show="rating_summary"] .bv_main_container .bv_numReviews_text {
    line-height: 15px !important;
}

.reviews-container .bv-cv2-cleanslate .bv-compat .bv-content-placeholder .bv-write-review-label {
    display: none !important;
}

#lblCategoryCopy .textDesc {
    display: block;
    line-height: 1.4em;
    margin: 0;
    color: #000;
    font-size: 1.25em;
    text-align: left;
    font-family: poppins-regular, Arial, sans-serif;
}

#lblCategoryCopy .catTemplate .siblingLinks,
.topheadbox .siblingLinks,
#divsiblinglistwrapper ul.ulFirst {
    padding-top: 12px;
    padding-left: 0;
    padding-right: 0;
    font-size: 1em;
    scrollbar-color: #005a54 #fff;
    margin: 0 0 10px;
    flex-wrap: nowrap;
    white-space: nowrap;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
}

#lblCategoryCopy .catTemplate .siblingLinks {
    display: block;
    margin-top: 10px;
}

#divsiblinglistwrapper ul.ulFirst {
    display: flex;
}

#lblCategoryCopy .catTemplate .siblingLinks a,
.topheadbox .siblingLinks a,
#lblCategoryCopy .catTemplate-FWC .siblingLinks a,
.catTemplate-FWC .siblingLinks a,
#divsiblinglistwrapper ul li a {
    line-height: 14px;
    background-color: #fff;
    letter-spacing: 0;
    padding: 12px 16px;
    display: inline-block;
    font-family: poppins-regular, Arial, sans-serif;
    font-size: 14px;
    color: #000;
    border: 1px solid #c4c4c4;
    border-radius: 4px;
    text-transform: none;
    margin: 0 4px 10px 0;
}

#lblCategoryCopy .catTemplate .siblingLinks a:hover,
#lblCategoryCopy .catTemplate .siblingLinks a:focus,
#lblCategoryCopy .catTemplate .siblingLinks a:active,
.topheadbox .siblingLinks a:hover,
.topheadbox .siblingLinks a:focus,
.topheadbox .siblingLinks a:active,
#divsiblinglistwrapper ul li a:hover,
#divsiblinglistwrapper ul li a:focus,
#divsiblinglistwrapper ul li a:active {
    border-color: #005a54;
}

#divsiblinglistwrapper ul li a:hover,
#divsiblinglistwrapper ul li a:focus,
#divsiblinglistwrapper ul li a:active {
    text-decoration: none;
}

#lblCategoryCopy .catTemplate .siblingLinks a:active,
.topheadbox .siblingLinks a:active,
#divsiblinglistwrapper ul li a:active {
    border-color: #005a54;
    background-color: #005a54;
    color: #fff;
}

#lblCategoryCopy .textDesc,
.topheadbox .textDesc {
    text-align: left;
}

#lblCategoryCopy .siblingLinks,
.topheadbox .siblingLinks,
#divsiblinglistwrapper ul.ulFirst {
    white-space: nowrap;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    scrollbar-width: thin;
    scrollbar-color: #005a54 #c4c4c4;
}

#lblCategoryCopy .siblingLinks::-webkit-scrollbar,
.topheadbox .siblingLinks::-webkit-scrollbar,
#divsiblinglistwrapper ul.ulFirst::-webkit-scrollbar {
    height: 5px;
}

#lblCategoryCopy .siblingLinks::-webkit-scrollbar-thumb,
.topheadbox .siblingLinks::-webkit-scrollbar-thumb,
#divsiblinglistwrapper ul.ulFirst::-webkit-scrollbar-thumb {
    background: #005a54;
    border-radius: 4px;
}

#lblCategoryCopy .siblingLinks::-webkit-scrollbar-track,
.topheadbox .siblingLinks::-webkit-scrollbar-track,
#divsiblinglistwrapper ul.ulFirst::-webkit-scrollbar-track {
    background: #c4c4c4;
    border-radius: 4px;
    margin-left: 16px;
    margin-right: 16px;
}

#lblCategoryCopy .catTemplate .siblingLinks,
.sdlProdList .catDesc,
.sdlProdList .siblingcategory,
.topheadbox .siblingLinks {
    margin-left: 0;
}

@media (min-width: 1022px) {
    #lblCategoryCopy .catTemplate .siblingLinks,
    .topheadbox .siblingLinks {
        white-space: normal;
        flex-wrap: wrap;
    }
}

@media (min-width: 1640px) {
    #lblCategoryCopy .catTemplate .siblingLinks,
    .topheadbox .siblingLinks {
        padding-left: 0;
        padding-right: 0;
        margin-left: 0;
    }
}

.evanProdList .s-productthumbtext .s-producttext-price {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    height: 30px;
}

.evanProdList .s-producttext-withticket .s-largered,
.evanProdList #hsPriceWrapper #hsPrice {
    padding-right: 10px;
}

.evanProdList #hsPriceWrapper #hsPrice {
    padding-right: 0;
}

.discount-percentage-off,
#hsDiscountPercentText.discount-percentage-off {
    color: #ff0039;
    font-weight: var(--font-weight-bold);
    font-family: poppins-regular, Arial, sans-serif;
    text-transform: uppercase;
}

.discount-percentage-off .discount-percentage-parenthesis {
    font-weight: var(--font-weight-bold);
}

.evanDetails #productDetails .discount-percentage-off {
    padding: 0 0 0 4px;
}

.evanProdList {
    #navlist.s-productscontainer2:not(.swiper-wrapper) {
        display: grid;
        grid-template-columns: repeat(4, minmax(0, 1fr));
        gap: 30px;
        margin: 0;
    }

    .columns3 #navlist.s-productscontainer2:not(.swiper-wrapper) {
        grid-template-columns: repeat(3, minmax(0, 1fr));
    }

    .columns4 #navlist.s-productscontainer2:not(.swiper-wrapper) {
        grid-template-columns: repeat(4, minmax(0, 1fr));
    }

    #navlist.s-productscontainer2 > li {
        padding: 0;
        margin: 0;
        width: 100%;
    }

    .columns3 .plp-advert-placement {
        order: 5 !important;
    }

    &.has-member-pricing-product-on-plp {
        .columns3 .plp-advert-placement {
            order: 2 !important;
        }

        .columns4 .plp-advert-placement {
            order: 3 !important;
        }
    }

    @media (min-width: 1022px) {
        #ProductContainer {
            padding-left: 20px;
        }
    }

    @media (max-width: 1021px) {
        #navlist.s-productscontainer2:not(.swiper-wrapper) {
            gap: 10px;
        }
    }
}

@media (max-width: 767px) {
    .evanProdList {
        .columns3,
        .columns4 {
            #navlist.s-productscontainer2:not(.swiper-wrapper) {
                grid-template-columns: repeat(2, minmax(0, 1fr));
            }
        }

        &.has-member-pricing-product-on-plp {
            .columns3,
            .columns4 {
                .plp-advert-placement {
                    order: 1 !important;
                }
            }
        }
    }
}
